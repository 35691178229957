import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";
import { useNavigate } from "react-router-dom";
import { removeCookie, setCookie } from "../../../util/cookies";
import { signOut } from "../../../lib/modules/auth";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";

export default function RemoveUserModal({ open, onClose }: any) {
  const { post, del } = useAxios();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );
  async function onUserRemove() {
    const res = await post(`${API_DEFAULT}/user/info/withdrawal`);

    if (res?.success) {
      del(`${API_DEFAULT}/user/auth/sign-out`, {
        accessToken: accessToken,
        refreshToken: refreshToken,
        browser: browserData,
      });
      signOut({ dispatch: dispatch });
    }
  }

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          정말 탈퇴하시겠어요?
        </Text>

        <Text
          className="upload-text dark:text-darkText mt-4"
          color={UploadTextColor}
        >
          탈퇴하면 모든 정보와 수익이 사라집니다.
        </Text>

        <FillButton
          text="탈퇴하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={onUserRemove}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
