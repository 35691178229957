import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";

export default function CustomTabs({ tabsData, value, setValue, size }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        sx={{
          "& .MuiTabs-root": {
            transition: "none !important",
          },
          "& .Mui-selected": {
            color: "#1A1A1A",
            backgroundColor: "transparent",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#1A1A1A",
          },
        }}
      >
        {tabsData.map((tab: any, index: any) => (
          <Tab
            key={index}
            value={tab.value}
            label={tab.label}
            sx={{
              fontSize: size,
              fontWeight: 700,
              cursor: "pointer",
              "&.Mui-selected": {
                color:
                  themeData === "light" || themeData === ""
                    ? "#1A1A1A"
                    : "#fff",
              },

              "&:not(.Mui-selected)": {
                color: "#AAA",
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
