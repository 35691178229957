import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  searchFlag: false,
};

export const mobileSlice = createSlice({
  name: "mobile",
  initialState: initialToastState,
  reducers: {
    searchFlagFetched: (state, action) => {
      state.searchFlag = action.payload;
    },
  },
});

export const { searchFlagFetched } = mobileSlice.actions;

export default mobileSlice.reducer;
