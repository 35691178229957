import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import React, { useEffect, useState } from "react";
import { useContext } from "../../util/useContext";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import {
  setSearchStudioList,
  setSearchStudioListInit,
} from "../../store/reducer/mainStock";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import StockListCard from "../feed/stock-list/StockListCard";
import { FadeLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import Banner from "../../components/common/Banner";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

export default function SearchFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const location = useLocation();
  const { state } = useContext();
  const { post, get } = useAxios();
  const dispatch = useAppDispatch();
  const { searchStudioList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual,
  );

  const [currentState, setCurrentState] = useState("");
  const [prevState, setPrevState] = useState("");
  const themeData = useAppSelector((state) => state.theme.data);
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [itmes, setItems] = useState<any>([]);

  useEffect(() => {
    setCurrentState(state);

    if (currentState !== prevState) window.location.reload();
  }, [state]);

  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await get(
      `${API_DEFAULT}/contents/search?page=${nextGroupKey}&size=${count}&qStr=${state}`,
    );
    if (res?.success) {
      nextItems = res?.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  useEffect(() => {
    if (!location.pathname.includes(`/feed/${state}`)) {
      dispatch(setSearchStudioListInit());
    }
  }, []);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10`}
    >
      <div className="my-[50px] mb-[30px]">
        <Banner />
      </div>
      <div className="flex items-center gap-4 mb-[30px]">
        <Text className="title dark:text-darkText">
          <Text span color="#42BC7F" className="title mr-[10px]">
            {state}
          </Text>
          검색 결과
        </Text>
      </div>
      <div className="mt-[30px]">
        <JustifiedInfiniteGrid
          loading={
            <FadeLoader
              color={
                themeData === "light" || themeData === ""
                  ? "#121212"
                  : "#ffffff"
              }
            />
          }
          placeholder={
            <div className="placeholder">추가 데이터를 불러오는 중...</div>
          }
          stretch={true}
          passUnstretchRow={true}
          sizeRange={[228, 228]}
          stretchRange={[144, 320]}
          {...(isMobile && { columnRange: [0, 1] })}
          threshold={3000}
          gap={20}
          onRequestAppend={(e) => {
            const nextGroupKey = (+e.groupKey! || 0) + 1;
            // 같은 그룹키는 무시
            if (nextGroupKey === groupKey) {
              return;
            }
            setGroupKey(nextGroupKey);
            e.wait();

            setTimeout(async () => {
              const add = await getItems(nextGroupKey, 10);

              if (add?.length === 0) {
                e.ready();
                return true;
              }

              dispatch(setSearchStudioList({ data: add, reset: false }));
              setItems([...itmes, ...add]);
              e.ready();
            }, 1);
          }}
        >
          {itmes?.length < 1 ? (
            <div className="flex flex-col items-center justify-center">
              <Text className="subtitle-2 dark:text-darkText">
                조회된 피드가 없습니다.
              </Text>
            </div>
          ) : (
            itmes?.map((item: any, index: any) => (
              <StockListCard
                data-grid-groupkey={item.groupKey}
                key={index}
                index={index}
                item={item}
                onClick={undefined}
              />
            ))
          )}
        </JustifiedInfiniteGrid>
      </div>

      <div className="fixed bottom-10 right-10">
        <div className="flex flex-col justify-center items-center gap-4">
          <FeedNav />

          <IconButton
            //@ts-ignore
            className="rounded-full px-[28px] py-[28px]"
            placeholder={undefined}
            children={undefined}
            size="lg"
            onClick={moveToTop}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
