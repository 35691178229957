//@ts-nocheck
import React from "react";
import Text from "../../../components/design-system/Text";
import Slide from "../../../components/landing/Slide";
import TextSlide from "../../../components/landing/TextSlide";
import { useMediaQuery } from "react-responsive";

export default function SectionTwo() {
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <div className="h-screen w-full flex flex-col bg-[#000] relative">
      <TextSlide delay={1.2}>
        <div
          className={`flex flex-col justify-center items-center gap-3 ${isMobile ? "mt-[120px]" : "mt-[200px]"}`}
        >
          <Text fontSize={isMobile ? 24 : 80} fontWeight={600} color="#fff">
            부족할 만큼 많은 사진/영상
          </Text>
          <Text fontSize={isMobile ? 18 : 30} fontWeight={400} color="#fff">
            대한민국 평균 스마트폰 보유 콘텐츠 갯수 13,000개
          </Text>
        </div>
      </TextSlide>
      <div className="flex justify-center items-center">
        <div className="flex justify-center items-center">
          <Slide
            delay={0.4}
            className="absolute bottom-0 left-[calc(50vw - 300px)] z-[2]"
          >
            <img src="/img/standbuy/landing/phone.png" />
          </Slide>

          <div className="absolute bottom-[13vh] left-[50vw] z-[1]">
            <img src="/img/standbuy/landing/right-box.png" width="600" />
          </div>

          <div className="absolute bottom-[26vh] right-[47vw] z-[1]">
            <img src="/img/standbuy/landing/error.png" width="600" />
          </div>
        </div>
      </div>

      <div className="absolute bottom-[-20vh] right-[54vw] z-[3]">
        <img src="/img/standbuy/landing/rectangle.png" width="600" />
      </div>
    </div>
  );
}
