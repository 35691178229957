import React, { useRef, useState } from "react";
import { Input, Button } from "@material-tailwind/react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";

export function LabelInputBtn({
  value,
  setValue,
  width,
  placeholder,
  label,
  size = "md",
  error = false,
  success = false,
  disable = false,
  minLength,
  maxLength,
  onKeyUp,
  readOnly = false,
  onClick,
  btnText,
  flag,
  sendNumberFlag,
  type = "email",
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const focusRef = useRef<any>();

  const handleInput = (e: any) => {
    setValue(e);
    return focusRef.current.focus();
  };

  return (
    <div className="relative flex w-full max-w-[24rem]">
      <Input
        type={type}
        className="dark:text-darkText"
        variant="static"
        label={label}
        placeholder={placeholder}
        crossOrigin={undefined}
        color={themeData === "dark" ? "white" : undefined}
        size={size}
        error={error}
        success={success}
        disabled={disable}
        ref={focusRef}
        value={value}
        onChange={(e: any) => handleInput(e.target.value)}
        onKeyUp={onKeyUp}
        minLength={minLength}
        maxLength={maxLength}
        readOnly={readOnly}
      />
      <Button
        //@ts-ignore
        size="sm"
        color={"green"}
        disabled={
          flag === "email"
            ? error || value === "" || sendNumberFlag
            : sendNumberFlag
        }
        className={`${"bg-[#42BC7F]"} + ${"!absolute right-1 top-1 rounded"}`}
        children={undefined}
        placeholder={undefined}
        onClick={onClick}
      >
        {btnText}
      </Button>
    </div>
  );
}
