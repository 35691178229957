import React, { useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import { LabelInputReset } from "../../../components/design-system/input/LabelInputReset";
import { ErrorText } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function PasswordFindForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  // 이메일 검사: '@', '.' 이 둘다 포함될것.
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isValidEmail = regex.test(email);

  const { post } = useAxios();

  async function handleEmailCode() {
    const res = await post(
      `${API_DEFAULT}/user/auth/mail-check?email=${email}`,
    );

    if (res?.success) {
      dispatch(
        toastFetched({
          show: true,
          text: res?.data.message,
          type: "success",
        }),
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error?.message,
          type: "error",
        }),
      );
    }
  }

  return (
    <Card>
      <div className="flex flex-col">
        <Text className="subtitle-2 dark:text-darkText">비밀번호 찾기</Text>

        <div className="w-full mt-12">
          <LabelInputReset
            width="100%"
            value={email}
            setValue={setEmail}
            placeholder={"이메일"}
            label={"이메일"}
            error={!isValidEmail && email !== ""}
            success={isValidEmail && email !== ""}
          />
          {!isValidEmail && email !== "" && (
            <Text color={ErrorText} className="label-2 mt-1">
              이메일 형식이 올바르지 않습니다.
            </Text>
          )}
        </div>

        <div className={"flex flex-col items-center"}>
          <FillButton
            onClick={handleEmailCode}
            text={"이메일 인증"}
            size={"lg"}
            className="mt-10 w-full"
            disabled={!isValidEmail}
          />

          <Text
            className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
            onClick={() => navigate(-1)}
          >
            뒤로가기
          </Text>
        </div>
      </div>
    </Card>
  );
}
