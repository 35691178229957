//@ts-nocheck
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Text from "../../../components/design-system/Text";
import { useWindowSize } from "@uidotdev/usehooks";
import { motion, useAnimation, useInView } from "framer-motion";

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/img/standbuy/landing/seoul.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export default function SectionNinth() {
  const { height } = useWindowSize();
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInview) {
      controls.start("visible");
    }
  }, [isInview]);

  return (
    <Wrap className="h-screen flex flex-col">
      <div className="w-full h-full flex flex-col pt-[10vh] pl-[8vw]">
        <Text
          fontSize={60}
          fontWeight={600}
          color="#fff"
          className="leading-[1.5]"
        >
          한국 기업 홍보영상
        </Text>

        <Text
          fontSize={60}
          fontWeight={600}
          color="#fff"
          className="leading-[1]"
        >
          이제는 한국 콘텐츠로
        </Text>

        <Text
          fontSize={22}
          fontWeight={400}
          color="#fff"
          className="leading-[1] mt-8 ml-2"
        >
          콘텐츠 제작자 90%이상 국내 콘텐츠 수요 응답
        </Text>
      </div>

      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        transition={{
          type: "spring",
          duration: 0.2,
          damping: 8,
          delay: 0.3,
          stiffness: 100,
        }}
        initial="hidden"
        animate={controls}
      >
        <div
          className={`absolute right-[58vw] z-[3] ${
            height <= 1110 ? "bottom-[34vh]" : "bottom-[40vh]"
          }`}
        >
          <img src="/img/standbuy/landing/circle-1.png" />
        </div>
      </motion.div>

      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, translateX: 90 },
          visible: { opacity: 1, translateX: 0 },
        }}
        transition={{
          type: "spring",
          duration: 0.2,
          damping: 8,
          delay: 0.6,
          stiffness: 100,
        }}
        initial="hidden"
        animate={controls}
        className="absolute bottom-[10vh] right-[0] z-[3]"
      >
        <div className="">
          <img src="/img/standbuy/landing/circle-2.png" />
        </div>
      </motion.div>

      <div className="absolute bottom-[-20vh] right-[54vw] z-[3]">
        <img
          src="/img/standbuy/landing/circle-3.png"
          width={`${height <= 1110 ? 500 : undefined}`}
        />
      </div>
    </Wrap>
  );
}
