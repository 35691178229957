import React, { useEffect, useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import { LabelInputReset } from "../../../components/design-system/input/LabelInputReset";
import { LabelInputEyes } from "../../../components/design-system/input/LabelInputEyes";
import { ErrorText } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { signIn } from "../../../lib/modules/auth";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import CustomCheckBox from "../../../components/design-system/CustomCheckBox";
import {
  checkEmailFetched,
  loginEmailFetched,
} from "../../../store/reducer/loginEmailSlice";
import { shallowEqual } from "react-redux";

export default function LoginEmailForm() {
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassWord] = useState("");
  const [loading, setLoading] = useState(false);
  const { data, recordChecked } = useAppSelector((state) => state.email);
  const [record, setRecord] = useState(false);
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );

  // 이메일 검사: '@', '.' 이 둘다 포함될것.
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isValidEmail = regex.test(email);

  const regexPwd = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\[\]{};:'"<>,.?/]).+$/;
  let isValidPassword = regexPwd.test(password);

  const loginData = {
    email: email,
    password: password,
    browser: browserData,
  };

  async function onLogin(data: any) {
    const res = await post(`${API_DEFAULT}/user/auth/sign-in`, data);

    if (res?.success) {
      await signIn({ dispatch: dispatch, data: res?.data });

      if (record) {
        dispatch(loginEmailFetched(email));
        dispatch(checkEmailFetched(record));
      }

      navigate("/feed", { replace: true });
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error?.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onLogin,
    onSuccess: () => {
      // console.log(status);
    },
  });

  useEffect(() => {
    if (status === "pending") {
      setLoading(true);
    } else if (status === "success") {
      setLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (data && recordChecked) {
      setEmail(data);
    }
  }, [data]);

  useEffect(() => {
    if (recordChecked) {
      setRecord(true);
    }
  }, []);

  useEffect(() => {
    dispatch(checkEmailFetched(record));
  }, [record]);

  return (
    <Card>
      <div className="flex flex-col">
        <Text className="subtitle-2 dark:text-darkText">이메일로 로그인</Text>
        <div className="w-full mt-12">
          <LabelInputReset
            width="100%"
            value={email}
            setValue={setEmail}
            placeholder={"이메일"}
            label={"이메일"}
            error={!isValidEmail && email !== ""}
            success={isValidEmail && email !== ""}
          />
          {!isValidEmail && email !== "" && (
            <Text color={ErrorText} className="label-2 mt-1">
              이메일 형식이 올바르지 않습니다.
            </Text>
          )}
        </div>

        <div className="w-full mt-12">
          <LabelInputEyes
            type="password"
            width="100%"
            value={password}
            setValue={setPassWord}
            placeholder={"비밀번호"}
            label={"비밀번호"}
            minLength={8}
            error={
              (!isValidPassword && password !== "") ||
              (isValidPassword && password !== "" && password.length < 8)
            }
            success={isValidPassword && password !== "" && password.length >= 8}
            onKeyUp={(event: any) => {
              if (event.key === "Enter") {
                onLogin(loginData);
              }
            }}
          />
          {(!isValidPassword && password !== "") ||
          (isValidPassword && password !== "" && password.length < 8) ? (
            <Text color={ErrorText} className="label-2 mt-1">
              8자 이상 숫자/영어 소문자/특수문자 조합으로 입력해주세요.
            </Text>
          ) : null}
        </div>

        <div className="mb-12 mt-6">
          <CustomCheckBox
            checked={record}
            onChange={() => setRecord(!record)}
            label={"이메일 저장"}
            labelStyle="label-1"
          />
        </div>

        <FillButton
          text={"로그인"}
          disabled={!isValidEmail || !isValidPassword}
          onClick={() => {
            // onLogin(loginData);
            mutate(loginData);
          }}
          loading={loading}
          className="flex justify-center"
          size="lg"
        />
      </div>
    </Card>
  );
}
