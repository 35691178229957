import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";

export default function ExchangeCancelModal({ open, onClose, data }: any) {
  const { post } = useAxios();

  async function cancelExchange() {
    const res = await post(
      `${API_DEFAULT}/user/settle/cancel?exchangeId=${data?.exchangeId}`,
    );

    if (res?.success) {
      alert("정산 취소 완료");
      window.location.reload();
    }
  }

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          정산을 취소하시겠어요?
        </Text>

        <FillButton
          text="취소하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={cancelExchange}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
