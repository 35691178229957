//@ts-nocheck

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
} from "@material-tailwind/react";
import Text from "../../design-system/Text";
import KeywordCheckBox from "./KeywordCheckBox";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import CustomCheckBox from "../../design-system/CustomCheckBox";
import FilterSwitch from "./FilterSwitch";
import { useWindowSize } from "@uidotdev/usehooks";
import AssetsCheckBox from "./AssetsCheckBox";
import { useEffect, useState } from "react";

export default function FilterIconButton({ itmes }: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const { height } = useWindowSize();

  return (
    <Menu
      placement="bottom-end"
      dismiss={{
        enabled: true,
      }}
      lockScroll={false}
    >
      <MenuHandler>
        <IconButton
          variant="outlined"
          className="rounded-full focus:ring-transparent"
          size="sm"
        >
          <FontAwesomeIcon
            icon={faSliders}
            className={`w-[14px] h-[14px] ${
              themeData === "light" || themeData === "" ? "" : "text-darkText"
            }`}
          />
        </IconButton>
      </MenuHandler>
      <MenuList className={`${height > 1024 ? undefined : "max-h-92"}`}>
        {/*<MenuItem className="focus:!bg-white hover:!bg-white">*/}
        {/*  <div className="flex flex-col gap-3">*/}
        {/*    <FilterSwitch itmes={itmes} />*/}
        {/*  </div>*/}
        {/*</MenuItem>*/}

        {/*<hr className="my-5" />*/}

        {/*<MenuItem className="hover:!bg-white">*/}
        {/*  <div className="flex flex-col gap-3">*/}
        {/*    <Text className="subtitle-3">콘텐츠 유형</Text>*/}
        {/*    <AssetsCheckBox />*/}
        {/*  </div>*/}
        {/*</MenuItem>*/}

        <MenuItem className="hover:!bg-white">
          <div className="flex flex-col gap-3 z-50">
            <KeywordCheckBox />
          </div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
