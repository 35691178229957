import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import SalesManage from "./card/SalesManage";
import WeekSales from "./card/WeekSales";
import SideDrawer from "../../components/design-system/SideDrawer";
import SalesChart from "./card/SalesChart";
import CustomFooter from "../../components/layout/footer/CustomFooter";

export default function SalesPage() {
  const { get } = useAxios();

  const { status, data } = useQuery({
    queryKey: ["sales"],
    queryFn: async () => await get(`${API_DEFAULT}/user/info/sales-record`),
  });

  return (
    <div className="flex  flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[40px] mx-[200px] w-full max-w-[1280px]">
          <div className="animate-bounce gap-1">
            <Text
              fontSize={14}
              fontWeight={600}
              color="black"
              className="dark:text-darkText"
            >
              메뉴
            </Text>
            <img src="/img/standbuy/icon/scroll-down.svg" alt="" />
          </div>
          <div className="flex items-center mb-[30px]">
            <SideDrawer />
            <Text className="title dark:text-darkText">판매 수익 관리</Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <SalesManage data={data?.data} />
          <WeekSales data={data?.data} />
          <SalesChart data={data?.data} />
        </div>
      </motion.div>

      <CustomFooter />
    </div>
  );
}
