/**
 * @name useContext
 * @description 글로벌공통으로 사용할수있는 변수,상수,함수를 관리합니다.
 */

import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router";
//@ts-ignore
import queryString from "query-string";
import { useAppDispatch, useAppSelector } from "../store/store";

export const useContext = () => {
  // hooks
  // const { lang } = useAppSelector((state) => state.context);
  const { isLogin, me } = useAppSelector((state) => state.auth);
  // const {user, login} = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state, pathname } = useLocation() as any; // navigate(MENU.TEST, {state: payload}) 사용
  const params = useParams() as any; // 라우팅할때 '/:id' 이런식으로 사용
  const query = queryString.parse(window.location.search); // http://192.168.1.45:3000/?intive=code&type=email
  // const
  const env = process.env.NODE_ENV === "development" ? "dev" : "prod";
  // *-------------------------------------------------------------------*
  return {
    isLogin,
    me,
    pathname,
    query,
    // lang,
    env,
    navigate,
    dispatch,
    state,
    params,
  };
};
/********************************************************
[사용법]
const {lang}=useContext()
const {state} = useContext()
const {params} = useContext()
const {query} = useContext() // queryString


*********************************************************/
