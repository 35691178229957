import React, { useEffect, useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { Textarea } from "@material-tailwind/react";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";

export default function ProfileStudioModal({ open, onClose, data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const { post } = useAxios();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<any>();

  async function onProfileEdit() {
    const cleanedMessage = message?.trimEnd();

    const res = await post(
      `${API_DEFAULT}/user/profile/desc?desc=${encodeURIComponent(cleanedMessage)}`,
    );
    if (res?.success) {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "프로필 메시지가 저장되었습니다.",
          type: "success",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onProfileEdit,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  useEffect(() => {
    if (data) {
      setMessage(data?.description);
    }
  }, [data]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      headerNone
      noOutSideClosed={true}
      width={isMobile ? "100%" : undefined}
    >
      <Text
        fontSize={18}
        color="#242424"
        fontWeight={700}
        className="dark:text-darkText"
      >
        소개 편집
      </Text>

      <div className={`w-full mt-5`}>
        <Textarea
          className="!border-[#F1F1F1] focus:border-[1px] min-h-[266px] focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10  shadow-gray-900/5 ring-4 ring-transparent dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
          placeholder="메시지를 입력해주세요."
          labelProps={{ className: "before:content-none after:content-none" }}
          value={message}
          onChange={(e: any) => {
            setMessage(e.target.value);
          }}
          maxLength={500}
        />

        <div className="flex justify-end">
          <Text
            fontSize={14}
            fontWeight={600}
            color="#535353"
            className="dark:text-darkText"
          >
            {message?.length || 0} / 500
          </Text>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <FillButton
          text="편집 완료"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
