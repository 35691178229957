import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EchartsModule from "../../../lib/modules/echarts";
import Text from "../../../components/design-system/Text";

export default function SalesChart({ data }: any) {
  const [dateData, setDateData] = useState([]);
  const [salesData, setSalesData] = useState([]);

  // hooks
  const [options, setOptions] = useState<any>({
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "cross" },
      formatter: function (params: any) {
        // params[0]은 첫 번째 데이터 포인트를 나타냅니다.
        // params[1]은 두 번째 데이터 포인트를 나타냅니다.
        // 각 데이터 포인트에는 시리즈 이름(a), x축 값(b), y축 값(value) 등이 포함됩니다.
        return `${params[0].name}일 ${params[0].value} ₩`;
      },
    },
    xAxis: {
      type: "category",
      name: "Day",
      data: [dateData],
      // x축 눈금선
      axisLine: {
        symbol: "none",
        lineStyle: {
          type: "dashed",
        },
      },
      axisTick: {
        show: true,
        alignWithLabel: true, //! 눈금선과 라벨의 정렬
        lineStyle: {
          type: "dashed",
          width: 1,
        },
      },
      axisLabel: {
        // formatter: "{value}요일",
        align: "center",
        rotate: 45,
        margin: 40,
      },
    },
    yAxis: [
      {
        type: "value",
        name: "수익금",
        position: "left",
        axisLabel: {
          formatter: "{value} ₩",
        },
      },
    ],
    series: [
      {
        name: "수익금",
        data: [salesData],
        type: "bar", // line, bar
        color: "#3CB265",
      },
    ],
  });

  useEffect(() => {
    const dates = data?.dailyRevenues?.map((item: any) => item.date);
    const sales = data?.dailyRevenues?.map((item: any) => item.totalSales);
    setDateData(dates);
    setSalesData(sales);
  }, [data?.dailyRevenues]);

  useEffect(() => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        data: dateData,
      },
      series: [
        {
          ...prevOptions.series,
          data: salesData,
        },
      ],
    }));
  }, [dateData]);

  return (
    <div className="mt-[100px]">
      <Text
        fontSize={16}
        fontWeight={600}
        color="#303030"
        className="dark:text-darkText"
      >
        최근 판매 수익
      </Text>

      <EchartsModule options={options} height={500} />
    </div>
  );
}
