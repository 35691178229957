import React from "react";
import styled from "styled-components";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { API_DEFAULT } from "../../../api/api";
import { signOut } from "../../../lib/modules/auth";
import { useAxios } from "../../../lib/api/useAxios";
import { useMediaQuery } from "react-responsive";
import { shallowEqual } from "react-redux";
import { LOCAL_STORAGE } from "../../../lib/const";

const Wrap = styled.div<any>`
  position: relative;
  background-color: #000;
  background-image: url("/img/standbuy/background/section-first-bg.png");

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
  }
`;

export default function SectionOne() {
  const { del } = useAxios();
  const navigate = useNavigate();
  const { me } = useContext();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );

  const isLogin: any = localStorage.getItem(LOCAL_STORAGE.LOGIN);

  console.log(isLogin);

  return (
    <Wrap
      className="h-screen flex flex-col justify-center items-center bg-cover overflow-x-hidden"
      isMobile={isMobile}
    >
      <div className="absolute top-24 left-28 z-30">
        {!isMobile && (
          <img src="/img/standbuy/logo/landing-standbuy.svg" width={200} />
        )}
      </div>

      {!isMobile && (
        <div className="absolute top-24 right-28 z-30">
          {!isLogin ? (
            <button
              className="rounded-full bg-white py-3 px-7 opacity-1 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <Text fontWeight={600}>로그인</Text>
            </button>
          ) : (
            <button
              className="rounded-full bg-white py-3 px-7 opacity-1 cursor-pointer"
              onClick={async () => {
                if (isLogin) {
                  await del(`${API_DEFAULT}/user/auth/sign-out`, {
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                    browser: browserData,
                  });
                  signOut({ dispatch: dispatch });
                }
              }}
            >
              <Text fontWeight={600}>로그아웃</Text>
            </button>
          )}
        </div>
      )}

      <div className="flex flex-col justify-center items-center">
        <Text
          fontSize={isMobile ? 40 : 110}
          color="#fff"
          fontWeight={800}
          className="z-[100] leading-[1]"
        >
          콘텐츠는
        </Text>
        <Text
          fontSize={isMobile ? 40 : 110}
          color="#fff"
          fontWeight={800}
          className="z-[100]"
        >
          원래 파는거야.
        </Text>
      </div>

      <div className="bg-[rgba(255,255,255,0.72)] z-[100] py-2 px-10 rounded-full flex items-center gap-8 mt-10">
        <Text
          fontSize={isMobile ? 20 : 36}
          color="#000"
          fontWeight={700}
          className="z-[100]"
        >
          내 갤러리 사진
        </Text>
        {isLogin && me?.agreementSelectiveTerms && (
          <button
            className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
            onClick={() => navigate(isLogin ? "/feed" : "/login")}
          >
            <Text color="#fff" fontWeight={600}>
              팔러가기
            </Text>
          </button>
        )}
        {isLogin && me?.agreementSelectiveTerms === false && (
          <button
            className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
            onClick={() => navigate("/social-check")}
          >
            <Text color="#fff" fontWeight={600}>
              팔러가기
            </Text>
          </button>
        )}
        {isLogin === false ||
          (isLogin === null && (
            <button
              className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <Text color="#fff" fontWeight={600}>
                팔러가기
              </Text>
            </button>
          ))}
      </div>

      <div className="absolute bottom-4 z-[100] animate-bounce flex flex-col items-center gap-1">
        <Text fontSize={18} fontWeight={600} color="#fff">
          스크롤 하여 더 알아보세요!
        </Text>
        <img src="/img/standbuy/icon/scroll-down.svg" alt="" />
      </div>
    </Wrap>
  );
}
