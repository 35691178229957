//@ts-nocheck
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setGeoPosition } from "../../../store/reducer/geoLocationSlice";
import useGeolocation from "../../../components/hooks/useGeoLocation";

export default function SearchByGoogle() {
  const dispatch = useAppDispatch();
  const { lat, lng } = useAppSelector((state) => state.geoLocation);
  const [address, setAddress] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [latLng, setLatLng] = useState({
    latitude: lat,
    longitude: lng,
  });

  const HandleLocationChange = (location: string) => {
    setLocation(location);
  };

  const handleSelect = (location: string) => {
    setAddress(location);
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) =>
        setLatLng({ latitude: latLng.lat, longitude: latLng.lng })
      )
      .catch((error) => console.error("Error", error));

    // onClose(); // 검색이 완료되었다면 modal을 닫는 fn ... 중략
  };

  useEffect(() => {
    dispatch(
      setGeoPosition({
        lat: latLng.latitude,
        lng: latLng.longitude,
      })
    );
  }, [latLng]);

  return (
    <PlacesAutocomplete
      value={location}
      onChange={HandleLocationChange}
      onSelect={handleSelect}
      //Debounce가 필요하다면
      // debounce={1000}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "장소, 주소를 검색해주세요.",
              className: "location-search-input dark:text-darkText",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>주소를 불러오고 있습니다.</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId} // Key prop 추가
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
