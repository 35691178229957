import React from "react";
import Text from "../../../components/design-system/Text";
import styled from "styled-components";
import TextSlide from "../../../components/landing/TextSlide";
import { useMediaQuery } from "react-responsive";

export default function SectionThree() {
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <div
      className={`${isMobile ? "h-[467px]" : "h-screen"} flex flex-col relative`}
    >
      <TextSlide delay={0.3}>
        <div
          className={`flex flex-col justify-center items-center ${isMobile ? "mt-[100px]" : "mt-[200px]"} `}
        >
          <Text fontSize={isMobile ? 24 : 80} fontWeight={400}>
            <span className="font-bold">우리</span>는 그럼
          </Text>
          <Text fontSize={isMobile ? 24 : 80} fontWeight={400}>
            이미 <span className="font-bold">아티스트(Artist)</span>야!
          </Text>
        </div>
      </TextSlide>
      <div className="absolute bottom-0 left-0 right-0 z-[1] flex justify-center">
        <img
          src="/img/standbuy/landing/people.png"
          // className="h-[50vh]"
          width="1200"
        />
      </div>
    </div>
  );
}
