// import IconButton from "@mui/material/IconButton";
import { IconButton } from "@material-tailwind/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Text from "../../design-system/Text";
import { useEffect, useState } from "react";
import FeedReportModal from "./FeedReportModal";
import FeedRemoveModal from "./FeedRemoveModal";
import { useContext } from "../../../util/useContext";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { useAppDispatch } from "../../../store/store";
import FeedModifyModal from "../../../pages/feed/modal/FeedModifyModal";
import { useNavigate } from "react-router-dom";
import FeedLocationModal from "./FeedLocationModal";
import { setGeoPosition } from "../../../store/reducer/geoLocationSlice";

export default function FeedReportBtn({ detail }: any) {
  const { me, isLogin } = useContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [feedReport, setFeedReport] = useState({ show: false });
  const [feedRemove, setFeedRemove] = useState({ show: false });
  const [feedModify, setFeedModify] = useState<any>({ show: false });
  const [feedLocation, setFeedLocation] = useState<any>({ show: false });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isLogin && me?.mobileVerification) {
      setAnchorEl(event.currentTarget);
    } else if (isLogin && !me?.mobileVerification) {
      dispatch(niceModalFetched({ show: true }));
    } else if (isLogin === false) {
      dispatch(modalFetched({ show: true }));
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const declarationList = [];

  if (me?.memberId === detail?.memberId) {
    declarationList.push({
      label: "삭제하기",
      onClick: () => {
        setFeedRemove({ show: true });
      },
    });
  }

  if (me?.memberId === detail?.memberId) {
    declarationList.push({
      label: "수정하기",
      onClick: () => {
        setFeedModify({ show: true });
      },
    });
  }

  if (me?.memberId === detail?.memberId) {
    declarationList.push({
      label: "위치 수정하기",
      onClick: () => {
        setFeedLocation({ show: true });
      },
    });
  }

  if (me?.memberId !== detail?.memberId) {
    declarationList.push({
      label: "신고하기",
      onClick: () => {
        setFeedReport({ show: true });
      },
    });
  }

  return (
    <>
      <div>
        <IconButton
          //@ts-ignore
          onClick={handleClick}
          color="white"
          children={undefined}
          placeholder={undefined}
          size="lg"
          className="border !border-[#E1E1E1]"
        >
          <img src="/img/standbuy/icon/info-dot.svg" alt="" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {declarationList.map((option: any, index: any) => (
            <MenuItem
              key={option.label}
              onClick={(e: any) => {
                // 옵션의 onClick 핸들러를 호출합니다.
                option.onClick();
                handleClose();
              }}
            >
              <Text fontSize={14} fontWeight={500} color="#2f2f2f">
                {option.label}
              </Text>
            </MenuItem>
          ))}
        </Menu>
      </div>

      {feedReport.show && (
        <FeedReportModal
          open={feedReport.show}
          onClose={() => setFeedReport({ show: false })}
          detail={detail}
        />
      )}

      {feedRemove.show && (
        <FeedRemoveModal
          open={feedRemove.show}
          onClose={() => setFeedRemove({ show: false })}
          detail={detail}
        />
      )}

      {feedModify.show && (
        <FeedModifyModal
          open={feedModify.show}
          onClose={() => setFeedModify({ show: false })}
          dataProps={detail}
        />
      )}

      {feedLocation.show && (
        <FeedLocationModal
          open={feedLocation.show}
          onClose={() => setFeedLocation({ show: false })}
          dataProps={detail}
        />
      )}
    </>
  );
}
