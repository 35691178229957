import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stat: "",
  thumbnailStat: "",
  thumbnailPercent: "",
};

export const uploadEndSlice = createSlice({
  name: "uploadEnd",
  initialState: initialState,
  reducers: {
    uploadFlagFetched: (state, action) => {
      state.stat = action.payload;
    },
    thumbnailFlagFetched: (state, action) => {
      state.thumbnailStat = action.payload;
    },
    thumbnailPercentFlagFetched: (state, action) => {
      state.thumbnailPercent = action.payload;
    },
  },
});

export const {
  uploadFlagFetched,
  thumbnailFlagFetched,
  thumbnailPercentFlagFetched,
} = uploadEndSlice.actions;

export default uploadEndSlice.reducer;
