import React from "react";

export default function SectionSeven() {
  return (
    <div>
      <img
        src="/img/standbuy/landing/group-people.png"
        alt=""
        className="w-full"
      />
    </div>
  );
}
