//@ts-nocheck

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
} from "@material-tailwind/react";
import Text from "../design-system/Text";
import { ProfileUnderLineGreen, WHITE } from "../../styles/color";
import styled from "styled-components";
import { checkRole } from "../../util";
import { useAxios } from "../../lib/api/useAxios";
import { useEffect, useState } from "react";
import { API_DEFAULT } from "../../api/api";
import { useContext } from "../../util/useContext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { signOut } from "../../lib/modules/auth";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import { addComma } from "../../util/number";
import { CustomImage } from "../design-system/CustomImg";
import { shallowEqual } from "react-redux";

const GradeCircle = styled.div`
  width: 24px;
  height: 24px;
  background-color: #42bc7f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ProfileAvatarMenu() {
  const { post, del, get } = useAxios();
  const { isLogin, me } = useContext();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );
  const navigate = useNavigate();

  const { status, data } = useQuery({
    queryKey: ["profile-simple"],
    queryFn: async () => await get(`${API_DEFAULT}/user/info/my-simple`),
  });

  return (
    <Menu placement="bottom-end">
      <MenuHandler>
        {status === "pending" ? (
          <Skeleton width={48} height={48} circle={true} />
        ) : (
          <Avatar
            variant="circular"
            alt="profile img"
            src={
              data?.data?.profileImage
                ? data?.data?.profileImage
                : "/img/standbuy/icon/no-profile.svg"
            }
            placeholder={undefined}
            className="border border-[#42BC7F] ring-2 hover:ring-2 ring-[#42BC7F] hover:ring-green-700 p-0.5"
          />
        )}
      </MenuHandler>

      <MenuList className="min-w-[240px]">
        <MenuItem className="flex items-center gap-2 hover:!bg-white cursor-default">
          <div className="flex items-center gap-4">
            <Avatar
              variant="circular"
              alt="profile img"
              src={
                data?.data?.profileImage
                  ? data?.data?.profileImage
                  : "/img/standbuy/icon/no-profile.svg"
              }
              placeholder={undefined}
              className="border border-[#42BC7F] ring-2 ring-[#42BC7F] p-0.5"
            />
            <div className="flex flex-col gap-2">
              <Text className="subtitle-3">{data?.data?.nickname || "-"}</Text>
              <Text
                className="label-2-strong underline underline-offset-1 cursor-pointer"
                color={ProfileUnderLineGreen}
                onClick={() => navigate("/profile-edit")}
              >
                프로필 편집
              </Text>
            </div>
          </div>
        </MenuItem>

        <MenuItem className="flex items-center gap-2 hover:!bg-white cursor-default">
          <div className="w-full flex flex-col gap-2 !cursor-default">
            {/*<div*/}
            {/*  className="flex items-center cursor-pointer"*/}
            {/*  onClick={() => navigate("/fof-list")}*/}
            {/*>*/}
            {/*  <Text className="label-2 mr-6">보유</Text>*/}
            {/*  <Text className="subtitle-3">*/}
            {/*    {addComma(data?.data?.haveCredit) || 0} FOF*/}
            {/*  </Text>*/}
            {/*</div>*/}

            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate("/fof-credit")}
            >
              <Text className="label-2 mr-6">수익</Text>
              <Text className="subtitle-3">
                ₩ {addComma(data?.data?.profit) || 0}
              </Text>
            </div>

            <div className="flex items-center">
              <Text className="label-2 mr-[13px]">구독자</Text>
              <Text className="subtitle-3">
                {data?.data?.subscriberCount || 0}
              </Text>
            </div>

            <div className="flex items-center">
              <Text className="label-2 mr-6">등급</Text>
              {/* <GradeCircle>
                <Text className="grade-text" color={WHITE}>
                  {checkRole(data?.data?.grade) === "아티스트" ? "Ar." : "Dr."}
                </Text>
              </GradeCircle> */}

              <Text className="subtitle-3">
                {checkRole(data?.data?.grade) === "아티스트"
                  ? "아티스트"
                  : "디렉터"}
              </Text>
            </div>
          </div>
        </MenuItem>

        <hr className="my-2 border-blue-gray-50" />

        <MenuItem
          className="flex items-center gap-2 mt-2"
          onClick={() => navigate("/locker")}
        >
          <img src="/img/google-icons/box.svg" alt="box" />

          <Text className="profile-menu-text">보관함</Text>
        </MenuItem>

        <MenuItem
          className="flex items-center gap-2"
          onClick={() => navigate("/my-subs")}
        >
          <img src="/img/google-icons/bookmark.svg" alt="box" />
          <Text className="profile-menu-text">구독</Text>
        </MenuItem>

        {/*<MenuItem*/}
        {/*  className="flex items-center gap-2"*/}
        {/*  onClick={() => navigate("/pay")}*/}
        {/*>*/}
        {/*  <img src="/img/google-icons/money.svg" alt="box" />*/}
        {/*  <Text className="profile-menu-text">FOF 충전</Text>*/}
        {/*</MenuItem>*/}

        <MenuItem
          className="flex items-center gap-2 mb-2"
          onClick={() => navigate("/my-page")}
        >
          <img src="/img/google-icons/mypage-edit.svg" alt="box" />
          <Text className="profile-menu-text">마이페이지</Text>
        </MenuItem>

        <MenuItem
          className="flex items-center gap-2 hover:!bg-white"
          onClick={() => navigate(`/my-studio/${me?.memberId}`)}
        >
          <div className="w-full flex justify-center items-center border-[1px] border-gray-300 h-[48px] rounded-md">
            <Text className="label-1-strong">내 스튜디오</Text>
          </div>
        </MenuItem>

        <MenuItem className="flex items-center gap-2 hover:!bg-white">
          <div
            className="w-full flex justify-center items-center"
            onClick={async () => {
              if (isLogin) {
                await del(`${API_DEFAULT}/user/auth/sign-out`, {
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                  browser: browserData,
                });
                signOut({ dispatch: dispatch });
              }
            }}
          >
            <Text className="label-1-strong underline underline-offset-4">
              로그아웃
            </Text>
          </div>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
