import React, { useState } from "react";
import Dayjs from "dayjs";
import Text from "../../../components/design-system/Text";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../components/design-system/Pagination";

export default function NoticeTable({ data }: any) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;
  const currentPosts = data?.slice(firstPostIndex, lastPostIndex);
  return (
    <>
      <table className="table-auto">
        <thead className="border-b border-[#AFAFAF]">
          <tr className="text-left">
            <th className="pb-3" style={{ width: 300 }}>
              <Text
                fontSize={15}
                fontWeight={600}
                color="#4c4c4c"
                className="dark:text-darkText"
              >
                번호
              </Text>
            </th>
            <th className="pb-3" style={{ width: 680 }}>
              <Text
                fontSize={15}
                fontWeight={600}
                color="#4c4c4c"
                className="dark:text-darkText"
              >
                제목
              </Text>
            </th>
            <th className="pb-3" style={{ width: 300 }}>
              <Text
                fontSize={15}
                fontWeight={600}
                color="#4c4c4c"
                className="dark:text-darkText"
              >
                작성일
              </Text>
            </th>
            {/* <th className="pb-3" style={{ width: 200 }}>
            <Text fontSize={15} fontWeight={600} color="#4c4c4c">
              조회수
            </Text>
          </th> */}
          </tr>
        </thead>
        <tbody>
          {currentPosts?.map((item: any, index: any) => (
            <tr
              key={item.noticeId}
              className={`py-5 ${
                index < currentPosts.length - 1
                  ? "border-b border-[#EBEBEB]"
                  : ""
              }`}
            >
              <td className="py-5 dark:text-darkText">{item.noticeId}</td>
              <td className="py-5 dark:text-darkText">
                <button
                  onClick={() => {
                    navigate(`/notice/${item.noticeId}`, {
                      state: item.noticeId,
                    });
                  }}
                >
                  {item.title}
                </button>
              </td>

              <td className="py-5 dark:text-darkText">
                {Dayjs(item.createTime).format("YYYY.MM.DD")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        postsNum={data?.length}
        postsPerPage={postsPerPage}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
}
