import React from "react";

type Props = {
  info: any[] | undefined;
  renderItem?: (props: { item: any; index: number }) => React.ReactNode; // 렌더링할 컴포넌트(리턴값)
};
export default function LoopItem({ renderItem, info }: Props) {
  // const
  return (
    <React.Fragment>
      {!!info &&
        info?.length > 0 &&
        info.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {!!renderItem && renderItem({ item: item, index: index })}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
}

/********************************************************
[사용법]
  <Loop
    info={widgets}
    renderItem={({item, index}) => (
      <RoundPanel style={{background: randomColor()}}>
        {index + 1} _ {item.title}
      </RoundPanel>
    )}
  />
*********************************************************/
