import React from "react";
import styled from "styled-components";
import Text from "../../../components/design-system/Text";
import TextSlide from "../../../components/landing/TextSlide";

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/img/standbuy/landing/man-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.4;
    z-index: 1;
  }
`;

export default function SectionSix() {
  return (
    <Wrap className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center mt-60 z-[3]">
        <TextSlide delay={0.3}>
          <Text
            fontSize={90}
            fontWeight={600}
            color="#fff"
            className="leading-[1]"
          >
            죄다 영어, 어려우니까
          </Text>
        </TextSlide>

        <Text
          fontSize={22}
          fontWeight={600}
          color="#fff"
          className="leading-[1] mt-4 ml-2"
        >
          *티이미지, *터스톡, **그리드 등 여러 플랫폼이 존재하지만
        </Text>
        <Text
          fontSize={22}
          fontWeight={600}
          color="#fff"
          className="leading-[1] mt-4 ml-2"
        >
          영어에 친화적이고, 작가가 되기위한 규정이 어려움.
        </Text>
      </div>
    </Wrap>
  );
}
