import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  owner: false,
  ticket: false,
  all: false,
};

export const priceInfoSlice = createSlice({
  name: "priceInfo",
  initialState: initialToastState,
  reducers: {
    onwerFetched: (state, action) => {
      state.owner = action.payload;
    },
    ticketFetched: (state, action) => {
      state.ticket = action.payload;
    },

    allFetched: (state, action) => {
      state.all = action.payload;
    },
  },
});

export const { onwerFetched, ticketFetched, allFetched } =
  priceInfoSlice.actions;

export default priceInfoSlice.reducer;
