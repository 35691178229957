import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Content = styled.div`
  .wrapper {
    padding: 24px;
    font-size: 16px;
    line-height: 140%;
    flex-direction: column;
    background-color: #fff;
    overflow-y: auto;
    max-height: 850px;
    h2 {
      padding-left: 10px;
      color: #111111;
      font-size: 14px;
    }
    ul {
      margin-bottom: 20px;
      padding-left: 20px;
      li {
        /* padding-left: 10px; */
        color: #5f5f5f;
        font-size: 12px;
      }
    }
    p {
      color: #5f5f5f;
      font-size: 12px;
    }
  }
`;

export default function JoinPolicyModal({ open, onClose }: any) {
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <Modal
      open={open}
      onClose={onClose}
      width={isMobile ? "375" : "600"}
      headerNone
    >
      <div className="flex justify-end pt-[50px]">
        <FontAwesomeIcon
          icon={faXmark}
          onClick={onClose}
          fontSize={24}
          className="cursor-pointer"
        />
      </div>
      <Content>
        <div className="wrapper">
          <p>
            '주식회사 FOF'(이하 '회사')이(가) 운영하는 ‘스탠바이’은(는)
            개인정보보호법 제30조에 의거하여 이용자의 개인정보 및 권익을
            보호하고 이와 관련한 고충 및 불만을 신속하게 처리하기 위하여 아래와
            같이 개인정보 처리방침을 수립하여 운영하고 있습니다. 우리 회사는
            관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해
            최선의 노력을 하고 있습니다.
          </p>
          <br />
          <p>
            STANDBUY 서비스(이하 “서비스”라 한다) 이용과 관련하여, 본인의
            개인정보를 수집•이용하고자 하는 경우에는 『개인정보 보호법』 제15조,
            제22조, 제39조의 3, 『신용정보의 이용 및 보호에 관한 법률』 제15조에
            따라 동의를 얻어야 합니다. 이에 본인은 주식회사 FOF (이하 “회사”라
            한다)가 아래의 내용과 같이 본인의 개인정보를 수집/이용하는데
            동의합니다.
          </p>
          <br />
          <p>시행일 : 2024-05-09</p>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>목차</li>
            <li> 제1조 개인정보의 수집 및 이용에 관한 안내</li>
            <li> 제2조 민감정보의 처리에 관한 사항</li>
            <li> 제3조 만 14세 미만 아동의 개인정보 처리에 관한 사항</li>
            <li>제4조 개인정보 자동수집 장치의 설치와 운영거부에 관한 사항</li>
            <li>
              제5조 개인정보의 보유·이용기간 및 파기 제6조 개인정보 처리 위탁
            </li>
            <li> 제6조 개인정보 처리 위탁</li>
            <li> 제7조 개인정보의 제3자 제공</li>
            <li> 제8조 개인정보의 국외 이전</li>
            <li> 제9조 개인정보의 안전성 확보조치</li>
            <li> 제10조 이용자 및 법정대리인의 권리와 그 행사 방법</li>
            <li>
              제11조 개인정보 보호책임자 및 이용자 권익침해에 대한 구제방법
            </li>
            <li>제12조 개인정보 처리방침 변경에 관한 사항</li>
          </ul>

          <h2>제1조 개인정보의 수집 및 이용에 관한 안내</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              1. 회사는 서비스 이용에 필요한 최소한의 개인정보를 수집하고
              있습니다.
            </li>
            <li>2. 회사는 이용자의 개인정보를 아래와 같이 처리합니다.</li>
          </ul>

          <Text fontSize={12}>개인정보 수집 및 이용</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 다양한 서비스 제공을 위해 이용자에게 동의를 받고 추가적인
              개인정보를 수집할 수 있습니다.
            </li>
          </ul>

          <Text fontSize={12}>서비스에서 별도로 추가 수집하는 개인정보</Text>

          <Text fontSize={12}>가. 정기 / 비 정기적인 개인정보 수집 목록</Text>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>1. 정산 신청 – 계좌번호, 이름, 전화번호 (수집일로부터 1년)</li>
            <li>
              2. 고객 만족도 설문조사 – 이메일, 이름, 전화번호 (수집일로부터
              1년)
            </li>
            <li>
              3. 인증서 (콘텐츠 거래 시 자동작성) – 이메일, 이름, 전화번호
              (수집일로부터 3년)
            </li>
          </ul>

          <Text fontSize={12}>
            나. "회사"는 “서비스” 제공을 위하여 이용자로부터 다음과 같이
            최소한의 개인정보를 제출 받아 수집, 이용합니다.
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <table className="table-auto w-full">
              <thead className="border-b border-[#AFAFAF]">
                <tr>
                  <th className="border p-2">구분</th>
                  <th className="border p-2">수집시점</th>
                  <th className="border p-2">수집항목</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">필수항목</td>
                  <td className="border p-2">회원가입</td>
                  <td className="border p-2">
                    성명, 생년월일, 성별, 아이디, 비밀번호, 이메일주소, 휴대폰
                    번호
                  </td>
                </tr>
              </tbody>
            </table>
          </ul>

          <Text fontSize={12}>
            다. 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집
            될 수 있습니다.
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              IP, 쿠키, 방문일시, 서비스 이용기록, OS정보, 브라우저 정보, 불량
              이용기록 등 서비스 이용 과정에서 자동으로 생성되어 수집되는 정보
            </li>
          </ul>

          <Text fontSize={12}>
            라. 회사는 다음의 방법을 통해 개인정보를 수집합니다.
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해
              동의를 하고, 직접 정보를 입력
            </li>
            <li>
              회원가입 과정에서 이용자의 본인확인(휴대폰본인확인, 아이핀)을 통한
              인증결과 수집
            </li>
            <li>서비스 이용 과정에서의 수집(자동생성정보)</li>
          </ul>

          <h2>제1조 개인정보의 수집 및 이용에 관한 안내</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 정보주체의 동의 없이 처리할 수 있는 개인정보에 대해서는 그
              항목과 처리의 법적 근거를 전자우편 등 대통령령으로 정하는 방법에
              따라 정보주체에게 알려드립니다.
            </li>
          </ul>

          <br />
          <h2>제2조 민감정보의 처리에 관한 사항</h2>
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>회사는 이용자의 민감한 개인정보를 수집하지 않습니다.</li>
          </ul>

          <br />
          <h2>제3조 만 14세 미만 아동의 개인정보 처리에 관한 사항</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 만 14세 미만 아동에게 당사의 서비스를 제공하지 않으며 이와
              관련한 개인정보를 수집하지 않습니다.
            </li>
          </ul>
          <br />

          <h2>
            제4조 개인정보 자동수집 장치의 설치/운영과 행태정보 처리 및 거부
            등에 관한 사항
          </h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              1. 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용
              정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
              2. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의
              하드디스크에 저장되기도 합니다.
            </li>
            <li>
              3. 회사는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스
              및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를
              수집·이용하고 있습니다.
            </li>
          </ul>

          <Text fontSize={12}>개인정보 자동수집정보 사용목적</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              관련법규의 준수 : 회사는 관련법규를 준수하기 위하여 이용자의
              접속기록(로그인)을 보관할 의무가 있습니다.
            </li>
            <li>
              홈페이지 이용자 통계 및 서비스 개선 : 방문일시, 서비스 이용기록,
              접속 IP정보, 쿠키 항목을 수집하며 수집일부터 24 개월 또는
              Datadog의 경우 45 일 까지 보유이용되며 기간 경과후 즉시 삭제됩니다
            </li>
            <li>
              부정이용 방지 : IP, 서비스 이용기록, 이메일, 이름, 소속 항목을
              수집하며 수집일부터 36 개월 까지 보유이용되며 기간 경과후 즉시
              삭제됩니다
            </li>
          </ul>

          <Text fontSize={12}>개인정보 자동수집안내 및 거부방법</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              아래의 분석도구를 활용하여 이용자의 주요행동(행태정보)을 수집 및
              분석합니다. 수집된 정보는 특정 개인을 식별할 가능성이 낮습니다.
            </li>
            <li
              style={{
                marginLeft: "8px",
              }}
            >
              Google
            </li>
            <li
              style={{
                marginLeft: "8px",
              }}
            >
              Datadog
            </li>
            <li
              style={{
                marginLeft: "8px",
              }}
            >
              Hotjar
            </li>
            <li>
              개인정보 자동수집 장치의 설치, 운영 및 거부방법: 아래의 방법으로
              쿠키 저장 또는 행태정보의 수집을 거부할 수 있습니다.
            </li>
          </ul>

          <Text fontSize={12}>[Web]</Text>
          <Text fontSize={11}>
            다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에
            영향을 미칠 수 있습니다.
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>웹 브라우저를 통한 맞춤형 광고 차단/허용</li>
          </ul>

          <ol
            style={{
              // listStyle: "inside",
              color: "#1A1A1A",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li
              style={{
                color: "#1A1A1A",
              }}
            >
              <strong>
                인터넷 익스플로러(Windows 10용 Internet Explorer 11)
              </strong>
              <ol>
                <li>
                  Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을
                  선택합니다.
                </li>
                <li>
                  개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의
                  차단 또는 허용을 선택합니다.
                </li>
              </ol>
            </li>

            <br />
            <li>
              <strong>Microsoft Edge</strong>
              <ol>
                <li>
                  Edge에서 오른쪽 상단 '…' 표시를 클릭한 후, 설정을 클릭합니다.
                </li>
                <li>
                  설정 페이지 좌측의 '개인정보, 검색 및 서비스'를 클릭 후
                  「추적방지」 섹션에서 '추적방지' 여부 및 수준을 선택합니다.
                </li>
                <li>
                  'InPrivate를 검색할 때 항상 "엄격" 추적 방지 사용' 여부를
                  선택합니다.
                </li>
                <li>
                  아래 「개인정보」 섹션에서 '추적 안함 요청보내기' 여부를
                  선택합니다.
                </li>
              </ol>
            </li>

            <br />
            <li>
              <strong>크롬 브라우저</strong>
              <ol>
                <li>
                  Chrome에서 오른쪽 상단 '⋮' 표시(chrome 맞춤설정 및 제어)를
                  클릭한 후, 설정 표시를 클릭합니다.
                </li>
                <li>
                  설정 페이지 하단에 '고급 설정 표시'를 클릭하고 「개인정보」
                  섹션에서 콘텐츠 설정을 클릭합니다.
                </li>
                <li>
                  쿠키 섹션에서 '타사 쿠키 및 사이트 데이터 차단'의 체크박스를
                  선택합니다.
                </li>
              </ol>
            </li>
          </ol>

          <br />

          <Text fontSize={12}>기타</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을
              수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동
              경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는
              민감한 행태정보를 수집하지 않습니다.
            </li>
            <li>
              회사는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주
              이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를
              수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를
              제공하지 않습니다.
            </li>
            <li>
              정보주체는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과
              거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.
            </li>
          </ul>

          <Text fontSize={14}>개인정보 보호 담당부서</Text>
          <Text fontSize={12}>부서명 : 개인정보보호팀</Text>
          <Text fontSize={12}>담당자 : 최재영</Text>
          <Text fontSize={12}>연락처 : fof@frameofframe.com</Text>

          <br />

          <Text fontSize={14}>
            회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
            불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
          </Text>

          <br />

          <Text fontSize={14}>이용자 정보</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              (1) 개인정보를 수집한 이용목적을 달성한 경우 회사는 이용자의 모든
              개인정보를 삭제합니다.
            </li>
            <li>
              (2) 장기 미접속자 정책 : 1년 이상 미접속자의 개인정보를 3개월 동안
              분리보관 후 삭제
            </li>
            <li>
              (3) 단, 관계 법령에서 개인정보를 보존해야 할 필요가 있는 경우 해당
              법률의 규정에 따릅니다.
            </li>
          </ul>

          <br />

          <Text fontSize={14}>관련 법령에 따른 의무 보존기간</Text>
          <Text fontSize={12}>
            관련 법령에 따라 보존해야 하는 근거와 보존하는 개인정보 항목은
            아래와 같습니다.
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              보존항목 : 카드사명, 카드번호 일부, IP, 브라우저 정보, 단말기 정보
            </li>
          </ul>

          <br />
          <h2>제5조 개인정보의 보유·이용기간 및 파기</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              가. 회사는 이용자로부터 동의 받은 목적을 달성하기 위한 기간 동안
              수집한 개인정보를 이용할 수 있습니다. 다만, 목적을 달성한 이후에도
              관련 법령에서 정한 바가 있으면 그에 따라 개인정보를 보존, 활용하고
              있습니다.
            </li>
          </ul>

          <Text fontSize={12}>[관련 법령에 따른 개인정보의 보존]</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <table className="table-auto w-full">
              <thead className="border-b border-[#AFAFAF]">
                <tr>
                  <th className="border p-2">개인정보</th>
                  <th className="border p-2">보존근거</th>
                  <th className="border p-2">보존기간</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">
                    서비스 이용 관련 기록 (접속IP정보, 접속기기정보)
                  </td>
                  <td className="border p-2">통신비밀보호법</td>
                  <td className="border p-2">1년</td>
                </tr>

                <tr>
                  <td className="border p-2">표시/광고에 관한 기록</td>
                  <td className="border p-2">
                    전자상거래 등에서의 소비자보호에 관한 법률
                  </td>
                  <td className="border p-2">6개월</td>
                </tr>

                <tr>
                  <td className="border p-2">
                    계약 또는 청약철회 등에 관한 기록
                  </td>
                  <td className="border p-2">
                    전자상거래 등에서 소비자보호에 관한 법률
                  </td>
                  <td className="border p-2">5년</td>
                </tr>

                <tr>
                  <td className="border p-2">
                    대금결제 및 재화 등에 공급에 과한 기록
                  </td>
                  <td className="border p-2">
                    전자상거래 등에서의 소비자보호에 관한 법률
                  </td>
                  <td className="border p-2">5년</td>
                </tr>

                <tr>
                  <td className="border p-2">
                    소비자의 불만 또는 분쟁처리에 관한 기록
                  </td>
                  <td className="border p-2">
                    전자상거래 등에서의 소비자보호에 관한 법률
                  </td>
                  <td className="border p-2">3년</td>
                </tr>

                <tr>
                  <td className="border p-2">전자금융 거래에 관한 기록</td>
                  <td className="border p-2">전자금융거래법</td>
                  <td className="border p-2">5년</td>
                </tr>
              </tbody>
            </table>
          </ul>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              나. 회사는 민원처리를 위하여 내부지침이 정한 바에 따라 이용자가
              회원 탈퇴한 이후 1년간, 성명, 생년월일, 내/외국인구분, 성별,
              이메일주소, 휴대폰 정보를 보관합니다.
            </li>
            <li>
              개인정보처리 관련 자세한 내용은 홈페이지 내 “개인정보처리방침”에서
              확인할 수 있습니다.
            </li>
            <li>
              이용자는 개인정보 수집 이용에 대한 동의를 거부할 권리가 있으나,
              이에 대한 동의가 없을 경우 서비스 이용이 불가할 수 있음을
              알려드립니다.
            </li>
          </ul>

          <h2>제6조 개인정보 처리 위탁</h2>

          <p>
            회사는 원활한 개인정보 업무처리를 위하여 일부 개인정보 처리 업무를
            위탁하고 있으며 위탁 계약 체결 시 관련 법령에 따라 수탁자가
            개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의
            내용이나 수탁자가 추가, 변경될 경우에는 지체 없이 관련 법령에 따른
            사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록
            하겠습니다.
          </p>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <table className="table-auto w-full">
              <thead className="border-b border-[#AFAFAF]">
                <tr>
                  <th className="border p-2">수탁자</th>
                  <th className="border p-2">위탁업무</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border p-2">naver</td>
                  <td className="border p-2">로그인</td>
                </tr>
                <tr>
                  <td className="border p-2">google</td>
                  <td className="border p-2">로그인</td>
                </tr>
                <tr>
                  <td className="border p-2">카카오</td>
                  <td className="border p-2">로그인</td>
                </tr>
                <tr>
                  <td className="border p-2">키움페이</td>
                  <td className="border p-2">결제 시스템</td>
                </tr>
                <tr>
                  <td className="border p-2">(주)나이스평가인증</td>
                  <td className="border p-2">회원가입, 본인인증, 계좌확인</td>
                </tr>
              </tbody>
            </table>
          </ul>

          <h2>제7조 개인정보의 제3자 제공</h2>

          <p>
            회사는 정보주체의 개인정보를 ‘제1조 개인정보의 수집 및 이용에 관한
            안내’에서 명시한 범위 내에서만 처리하며, 이용자의 별도 사전동의,
            관련 법령의 특별한 요구가 발생하는 경우에만 개인정보를 제3자에게
            제공합니다.
          </p>

          <br />
          <Text fontSize={12}>
            관계법령에 의거 사전 동의 없이 제3자에게 제공되는 정보
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              (1) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
              개인을 식별할 수 없는 형태로 제공하는 경우
            </li>
            <li>(2) 관계법령에 의하여 국가기관으로부터 요구받은 경우</li>
            <li>
              (3) 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의
              요청이 있는 경우
            </li>
            <li>(4) 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</li>
          </ul>

          <Text fontSize={12}>긴급상황 발생 시 개인정보 제공 안내</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한
              재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이
              관계기관에 개인정보를 제공할 수 있습니다.
            </li>
            <li>
              긴급상황은 정부 관계부처가 합동으로 발표한 「긴급상황 시 개인정보
              처리 및 보호수칙」을 기준으로 합니다.
            </li>
          </ul>

          <h2>제8조 개인정보의 국외 이전</h2>

          <p>
            회사는 개인정보를 국외의 다른 사업자에게 제공하지 않습니다. 다만,
            정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진 등을
            위하여 다음과 같이 개인정보 처리 업무를 국외에 전송하고 있습니다.
          </p>

          <br />

          <h2>제9조 개인정보의 안전성 확보조치</h2>

          <p>
            회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난,
            유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한
            기술적·관리적 및 물리적 조치를 하고 있습니다.
          </p>

          <br />

          <Text fontSize={12}>개인정보 취급 직원의 최소화 및 교육</Text>

          <p>
            개인정보를 취급하는 직원을 최소화하고, 주기적인 개인정보 보호 교육을
            실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.
          </p>

          <br />

          <Text fontSize={12}>내부관리계획의 수립 및 시행</Text>

          <p>
            개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
            있습니다.
          </p>

          <br />

          <Text fontSize={12}>접속기록의 보관 및 위변조 방지</Text>

          <p>
            개인정보 침해사고 발생 시 대응이 용이하도록 개인정보 처리시스템에
            접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관·관리하고
            있으며, 접속 기록이 위변조 및 도난·분실되지 않도록 보안기능을
            사용하고 있습니다.
          </p>

          <br />

          <Text fontSize={12}>개인정보의 암호화</Text>

          <p>이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다.</p>

          <br />

          <Text fontSize={12}>해킹 등에 대비한 기술적 대책</Text>

          <p>
            회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을
            막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 합니다.
            또한 외부로부터 접근이 통제된 구역에 시스템을 설치하고
            기술적·물리적으로 감시 및 차단하고 있습니다.
          </p>

          <br />

          <Text fontSize={12}>개인정보에 대한 접근통제 제한</Text>

          <p>
            개인정보를 처리하는 개인정보 처리시스템에 대한 접근권한의 부여,
            변경, 말소를 통하여 개인정보에 대한 접근통제를 위한 조치를 하고
            있습니다.
          </p>

          <br />

          <h2>제10조 이용자 및 법정대리인의 권리와 그 행사 방법</h2>

          <p>
            10조 이용자 및 법정대리인의 권리와 그 행사 방법 회사는 이용자(또는
            법정대리인)의 개인정보 권리를 보호하기 위해 아래와 같이 행사 방법을
            마련하고 있습니다.
          </p>

          <br />

          <Text fontSize={12}>이용자의 권리 및 행사방법</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>(1) 열람/수정 : 마이페이지 &gt; 내정보</li>
            <li>(2) 회원탈퇴 : 프로필편집 &gt; 탈퇴하기</li>
            <li>
              (3) 개인정보의 이전을 거부하는 방법, 절차: 고객센터를 통한 연락
            </li>
            ㄴ 거부의 효과 : (자동으로 수집되는 정보의 경우) 개인정보취급자의
            개인정보 처리 중지
            <li>
              (4) 그 밖에 서면, 전자우편 등을 통하여 개인정보의 처리 정지 및
              삭제를 요구할 수 있습니다.
            </li>
            <li>
              (5) 회사는 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한
              경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를
              이용하거나 제공하지 않습니다.
            </li>
            <li>
              (6) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
              수집 대상으로 명시되어 있는 경우에는 당해 개인정보의 삭제를 요구할
              수 없습니다.
            </li>
            <li>
              (7) 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구,
              처리정지의 요구 시 열람 등 요구를 한 자가 본인인지를 확인합니다.
            </li>
          </ul>

          <Text fontSize={12}>법정대리인의 권리 및 행사방법</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              (1) 이용자의 법정대리인이나 위임을 받은 자 등 대리인이 이용자의
              권리(열람, 정정, 처리정지, 삭제)를 행사하는 경우 '개인정보보호법
              시행규칙 별지 제11호 서식'의 위임장을 제출해야 합니다.
            </li>
            <li>
              (2) 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구,
              처리정지의 요구 시 열람 등 요구를 한 자가 정당한 대리인인지를
              확인합니다.
            </li>
          </ul>

          <h2>제11조 개인정보 보호책임자 및 이용자 권익침해에 대한 구제방법</h2>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와
              같이 개인정보 보호책임자를 지정하고 있습니다.
            </li>
            <li>
              2. 이용자는 개인정보의 열람청구를 하실 수 있습니다. 회사는
              이용자의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
            </li>
          </ul>

          <Text fontSize={12}>개인정보 보호책임자의 역할</Text>

          <p>
            이용자는 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의,
            불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자에게 문의하실
            수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및
            처리해드릴 것입니다.
          </p>

          <br />

          <Text fontSize={12}>권익침해 관련 도움받을 수 있는 기관</Text>

          <p>
            이용자의 권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여
            주시기 바랍니다.
          </p>

          <br />

          <Text fontSize={12}>
            (1) 개인정보 침해신고센터 (한국인터넷진흥원 운영)
          </Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
            <li>홈페이지 : privacy.kisa.or.kr</li>
            <li>전화 : (국번없이) 118</li>
            <li>
              주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
              개인정보침해신고센터
            </li>
          </ul>

          <Text fontSize={12}>(2) 개인정보 분쟁조정위원회</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
            </li>
            <li>홈페이지 : www.kopico.go.kr</li>
            <li>전화 : (국번없이) 1833-6972</li>
            <li>
              주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
            </li>
          </ul>

          <Text fontSize={12}>(3) 대검찰청 사이버범죄수사단</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>전화 : (국번없이)1301</li>
            <li>홈페이지 : www.spo.go.kr</li>
          </ul>

          <Text fontSize={12}>(3) 대검찰청 사이버범죄수사단</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>전화 : (국번없이)1301</li>
            <li>홈페이지 : www.spo.go.kr</li>
          </ul>

          <Text fontSize={12}>(4) 경찰청 사이버안전국</Text>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>전화 : 182</li>
            <li>홈페이지 : cyberbureau.police.go.kr</li>
          </ul>

          <p>
            또한, 개인정보의 열람, 정정·삭제, 처리정지 등의 규정에 의한 요구에
            대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는
            이익을 침해 받은 자는 행정심판법이 정하는 바에 따라 행정심판을
            청구할 수 있습니다.
          </p>

          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "8px",
            }}
          >
            <li>
              중앙행정심판위원회 : (www.simpan.go.kr)의 전화번호 안내 참조
            </li>
          </ul>

          <h2>제12조 개인정보 처리방침 변경에 관한 사항</h2>

          <p>
            개인정보 처리방침은 시행일로부터 적용되며, 관련 법령 및 방침에 따른
            변경내용의 추가, 삭제 및 정정이 있는 경우에는 지체없이 홈페이지를
            통하여 고지할 것입니다. 아래 셀렉트 박스를 클릭하시면 이전 버전의
            개인정보 처리방침을 볼 수 있습니다.
          </p>
        </div>
      </Content>
    </Modal>
  );
}
