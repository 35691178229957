import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setSearchStudioList } from "../../store/reducer/mainStock";
import StockListCard from "../feed/stock-list/StockListCard";
import { FeedNav } from "../../components/design-system/FeedNav";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "../../util/useContext";
import { useAxios } from "../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { API_DEFAULT } from "../../api/api";
import { ChipDelete } from "../../components/feed/filter/ChipDelete";
import FilterBar from "../../components/feed/filter/FilterBar";
import { useMediaQuery } from "react-responsive";

export default function FeedFilterPage() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const navigate = useNavigate();
  const { state } = useContext();
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { filterImage } = useAppSelector((state) => state.assets);
  const [chips, setChips] = useState(state);
  const [groupKey, setGroupKey] = useState<any>(null);
  const [itmes, setItems] = useState<any>([]);

  async function getItems(nextGroupKey: any, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await post(`${API_DEFAULT}/contents/search/filter`, {
      classification: chips?.includes("IMAGE")
        ? "IMAGE"
        : chips?.includes("VIDEO")
          ? "VIDEO"
          : null,
      grade: chips.includes("디렉터") ? "ROLE_DIRECTOR" : null,
      keywords:
        chips && chips.length > 0
          ? chips.includes("IMAGE") ||
            chips.includes("VIDEO") ||
            chips.includes("구독자") ||
            chips.includes("디렉터")
            ? (() => {
                const filtered = chips.filter(
                  (item: any) =>
                    item !== "IMAGE" &&
                    item !== "VIDEO" &&
                    item !== "구독자" &&
                    item !== "디렉터",
                );
                return filtered.length > 0 ? filtered : null;
              })()
            : chips
          : null,
      sub: !!chips.includes("구독자"),
      page: nextGroupKey,
      size: 30,
    });
    if (res?.success) {
      nextItems = res?.data;
      if (nextItems.length === 0) {
        return [];
      }
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  useEffect(() => {
    const fetchItems = async () => {
      const newItems = await getItems(1, 30);
      setItems(newItems);
    };

    if (chips.length > 0) {
      setItems([]); // Reset items when chips change
      fetchItems();
    }
  }, [chips]);

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (chips.length < 1) {
      navigate("/feed");
    }
  }, [chips, navigate]);

  return (
    <div
      className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10`}
    >
      <div className="my-[50px] mb-[30px]">
        <Banner />
      </div>
      <div className="flex items-center gap-4 mb-[30px]">
        <Text className="title dark:text-darkText">
          {chips?.map((item: any, index: any) => (
            <Text key={index} span color="#42BC7F" className="title mr-[10px]">
              {chips.length > 1 ? item + "," : item}
            </Text>
          ))}
          필터링 결과
        </Text>
      </div>

      <div className="mb-[30px] flex gap-5 overflow-auto">
        <FilterBar itmes={itmes} />
        <ChipDelete
          state={state}
          chips={chips}
          setChips={setChips}
          setGroupKey={setGroupKey}
        />
      </div>

      <div className="mt-[30px]">
        <JustifiedInfiniteGrid
          loading={
            <FadeLoader
              color={
                themeData === "light" || themeData === ""
                  ? "#121212"
                  : "#ffffff"
              }
            />
          }
          placeholder={
            <div className="placeholder">추가 데이터를 불러오는 중...</div>
          }
          columnRange={isMobile ? [0, 1] : [0, 4]}
          threshold={3000}
          gap={20}
          onRequestAppend={(e) => {
            const nextGroupKey = (+e.groupKey! || 0) + 1;
            // 같은 그룹키는 무시

            if (nextGroupKey === groupKey) {
              return;
            }
            setGroupKey(nextGroupKey);
            e.wait();

            setTimeout(async () => {
              const add = await getItems(nextGroupKey, 10);

              if (add?.length === 0) {
                e.ready();
                return true;
              }

              dispatch(setSearchStudioList({ data: add, reset: false }));
              setItems([...itmes, ...add]);
              e.ready();
            }, 1);
          }}
        >
          {itmes?.length < 1 ? (
            <div className="flex flex-col items-center justify-center">
              <Text className="subtitle-2 dark:text-darkText">
                조회된 피드가 없습니다.
              </Text>
            </div>
          ) : (
            itmes?.map((item: any, index: any) => (
              <StockListCard
                data-grid-groupkey={item.groupKey}
                key={index}
                index={index}
                item={item}
                onClick={undefined}
              />
            ))
          )}
        </JustifiedInfiniteGrid>
      </div>

      <div className="fixed bottom-10 right-10">
        <div className="flex flex-col justify-center items-center gap-4">
          <FeedNav />

          <IconButton
            //@ts-ignore
            className="rounded-full px-[28px] py-[28px]"
            placeholder={undefined}
            children={undefined}
            size="lg"
            onClick={moveToTop}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
