//@ts-nocheck
import { useNavigate } from "react-router-dom";
import Text from "../../design-system/Text";
import { FooterTextColor } from "../../../styles/color";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState } from "react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";

function Icon({ id, open }) {
  return (
    <svg
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6L8 10L12 6"
        stroke="#838383"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default function MobileFooter() {
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const [open, setOpen] = useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  return (
    <footer className="px-10 max-h-full bg-[#F8F8F8] flex w-full border-t border-blue-gray-50 py-10 text-center dark:bg-darkBg dark:!border-[#1a1a1a] transition duration-700 ease-out">
      <div className="flex flex-col items-start gap-4">
        <div className="flex gap-10">
          <div className="flex flex-col items-start gap-2">
            <Text
              className="profile-menu-text-mobile cursor-pointer"
              color={FooterTextColor}
              onClick={() => navigate("/")}
            >
              스탠바이
            </Text>
            <Text
              fontWeight={700}
              className="profile-menu-text-mobile cursor-pointer"
              color={FooterTextColor}
              onClick={() => navigate("/policy")}
            >
              개인정보 처리방침
            </Text>
          </div>

          <Text
            className="profile-menu-text-mobile cursor-pointer"
            color={FooterTextColor}
            onClick={() => navigate("/notice")}
          >
            공지사항
          </Text>
        </div>

        <div className={"h-[1px] bg-gray-300 w-full"}></div>

        <div className={`flex flex-col gap-1`}>
          <div className="flex gap-1 justify-between">
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              상호명 : 주식회사 에프오에프
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              사업자등록번호 : 476-81-03372
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              대표자명 : 최재영
            </Text>
          </div>
          <div className="flex gap-1 justify-between">
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              통신판매업신고 : 제 2024-서울성동-0442호
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              주소 : 서울특별시 성동구 왕십리로 273-1 3층
            </Text>
          </div>
          <div className="flex gap-1">
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              대표번호 : 02.2282.8766
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              이메일 : fof@frameofframe.com
            </Text>
          </div>
          <div className="mt-4 mb-1 w-[100px]">
            <img
              src={"/img/standbuy/logo/logo-gray.svg"}
              alt="스탠바이"
              onClick={() => navigate("/")}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3">
          <div className="flex flex-col gap-1 items-start">
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              스탠바이의 모든 콘텐츠 저작권 책임은 사용자에게 있습니다.
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              자세한 사항은 이용약관을 참조 부탁드립니다.
            </Text>
          </div>

          <div className="flex flex-col gap-1 items-start">
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              스탠바이 사이트의 아티스트/디렉터 정보, 콘텐츠, UI/UX 등에 대한
              무단 복제, 전송, 배포, 크롤링,
            </Text>
            <Text className="profile-menu-text-mobile" color={FooterTextColor}>
              스크래핑 등의 행위는 저작권법, 콘텐츠 산업 진흥법 등 관련 법령에
              의하여 엄격히 금지 됩니다.
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
}
