import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../store/store";
import { useReadyElement } from "@egjs/react-imready";
import styled from "styled-components";
import ReactHlsPlayer from "react-hls-player";
import FeedOrignalImgModal from "./FeedOrignalImgModal";
import Text from "../../design-system/Text";
import { useMediaQuery } from "react-responsive";

export default function AssetsMode() {
  const { detail } = useAppSelector((state) => state.feed);
  const detailRef = useRef() as any;
  const { register, isReady } = useReadyElement({
    selector: "img",
  }) as any;
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <>
      <Content>
        <div ref={register()}>
          {detail?.classification === "IMAGE" &&
            ({ isReady } ? (
              <>
                <div className={`${isMobile ? "" : "img-wrap"}`}>
                  <img
                    src={detail?.previewPath}
                    alt="썸네일"
                    className="rounded-md"
                    onClick={() => setInfoModal({ show: true, data: detail })}
                  />

                  {!isMobile && (
                    <div
                      className="status"
                      onClick={() => setInfoModal({ show: true, data: detail })}
                    >
                      <div className="h-full flex flex-col justify-end items-start gap-4 cursor-pointer">
                        <Text fontSize={15} fontWeight={600} color="#fff">
                          클릭 시 이미지 원본 비율 보기
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <span>loading</span>
            ))}
        </div>

        {detail?.classification === "VIDEO" && !!detail?.previewPath && (
          <div className={`${isMobile ? "bg-black" : "video-wrap"}`}>
            <ReactHlsPlayer
              className={`vide rounded-md ${isMobile && "h-[440px]"}`}
              width={"100%"}
              autoPlay={true}
              controls={true}
              muted={true}
              loop={true}
              playerRef={detailRef}
              src={detail?.previewPath}
              hlsConfig={{
                maxLoadingDelay: 4,
                minAutoBitrate: 0,
                lowLatencyMode: true,
              }}
            />
          </div>
        )}
      </Content>

      {infoModal.show && (
        <FeedOrignalImgModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}
    </>
  );
}

const Content = styled.div<any>`
  margin-bottom: 20px;
  /* list-wrap */
  .img-wrap {
    /* display: flex; */
    cursor: pointer;
    /* overflow: hidden; */
    max-width: 440px;
    max-height: 440px;
    /* align-items: center; */
    /* justify-content: center; */
    background-color: "#000";
    position: relative; /* 추가: 위치 지정 기준 설정 */

    &:hover .status {
      opacity: 1; /* img-wrap에 마우스를 올렸을 때 .status 스타일 변경 */
    }

    img {
      min-width: 440px;
      height: 440px;
      object-fit: cover;
    }
  }

  .status {
    border-radius: 12px;
    position: absolute;
    opacity: 0; /* 기본적으로 투명 */
    transition: all 0.3s ease-in-out;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%; /* 비디오 전체를 덮도록 수정 */
    padding: 18px 25px;
    background: linear-gradient(
      to bottom,
      rgba(249, 249, 249, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  .video-wrap {
    min-width: 440px;
    max-width: 440px;
    max-height: 440px;
    margin-bottom: 23px;
    background: black;
    video {
      width: 100%;
      height: 440px;
      background-color: "#000";
    }
  }
`;
