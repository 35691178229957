import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoLockerData() {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title dark:text-darkText mt-[90px]">
          보관함에 담긴 파일이 없어요.
        </Text>

        <img src={"/img/standbuy/icon/thumbnail-upload.svg"} />

        <div className="flex flex-col items-center">
          <Text className="upload-text" color={UploadTextColor}>
            원하는 이미지나 영상을
          </Text>
          <Text className="upload-text" color={UploadTextColor}>
            구경하고 보관함에 담아보세요.
          </Text>
        </div>
      </div>
    </div>
  );
}
