import React from "react";
import { useNavigate } from "react-router-dom";
import Switcher from "../../components/common/Switcher";
import ResetPasswordForm from "./card/ResetPasswordForm";

export default function ResetPasswordPage() {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
      <Switcher className="hidden" />
      <div className="w-full flex flex-col items-center gap-12">
        <img
          src={"/img/standbuy/logo/logo-green.svg"}
          alt="standbuy"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          width={200}
        />

        <ResetPasswordForm />
      </div>
    </div>
  );
}
