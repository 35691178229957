import React from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoPayList() {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title dark:text-darkText mt-[90px]">
          결제 내역이 없습니다.
        </Text>

        {/*<img src={"/img/standbuy/icon/thumbnail-upload.svg"} />*/}

        {/*<div className="flex flex-col items-center">*/}
        {/*  <Text className="upload-text" color={UploadTextColor}>*/}
        {/*    아직 충전한 FOF 내역이 없어요.*/}
        {/*  </Text>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
