import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import FillButton from "../../components/design-system/button/FillButton";
import PreloadedImage from "../../components/design-system/PreloadedImage";
import { useAppDispatch } from "../../store/store";
import { toastFetched } from "../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";

export default function UploadCheckBlock({ setStep }: any) {
  const dispatch = useAppDispatch();
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [stepThree, setStepThree] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col justify-between">
          <motion.div
            className={`w-full flex justify-center mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div
              className={`${stepTwo ? "mt-[520px]" : stepOne ? "mt-[240px]" : "mt-[50px]"} mx-[200px] w-full max-w-[1280px]`}
            >
              <div className="flex justify-between items-center mb-[30px]">
                <Text className="title dark:text-darkText">
                  콘텐츠 업로드 주의사항
                </Text>
              </div>

              <div className="flex flex-col gap-10">
                <div>
                  <img
                    src="/img/standbuy/background/mobile-step-1.svg"
                    alt=""
                    className={"min-w-[320px]"}
                  />

                  <div className={"mt-4 flex justify-between"}>
                    <div className={"flex flex-col gap-3"}>
                      <Text fontSize={14} className={"dark:text-darkText"}>
                        스탠바이에서 저작권이 확보되지 않은 콘텐츠를 판매 시,
                        모든 책임은 판매자에게 있습니다.{" "}
                      </Text>
                      <Text
                        fontSize={11}
                        fontWeight={700}
                        className={"dark:text-darkText"}
                      >
                        *스탠바이 운영지침에 따라 회원정지, 영구정지 등의 조치가
                        가능합니다.
                      </Text>
                    </div>
                  </div>

                  <div className={"flex justify-end gap-4 mt-4"}>
                    <FillButton
                      onClick={() => {
                        setStepOne(true);
                      }}
                      text={"네"}
                      size={"lg"}
                      className={"w-[100px]"}
                    />

                    <FillButton
                      onClick={() => {
                        setStepOne(false);
                        setStepTwo(false);
                        setStepThree(false);
                        dispatch(
                          toastFetched({
                            show: true,
                            text: "저작권이 타인에게 있다면 업로드할 수 없습니다.",
                            type: "error",
                          }),
                        );
                      }}
                      text={"아니오"}
                      size={"lg"}
                      className={
                        stepOne ? "w-[100px] bg-gray-300" : "w-[100px]"
                      }
                      color={!stepTwo ? "red" : "gray"}
                    />
                  </div>
                </div>

                {stepOne && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <PreloadedImage
                      src="/img/standbuy/background/mobile-step-2.svg"
                      alt=""
                      className={"min-w-[320px]"}
                      content={
                        <>
                          <div className={"mt-4 flex justify-between"}>
                            <div className={"flex flex-col gap-3"}>
                              <Text
                                fontSize={14}
                                className={"dark:text-darkText"}
                              >
                                사진/영상에 시각적으로 인식될 수 있는 인물의
                                초상권을 반드시 확보해야 합니다.
                              </Text>
                              <Text
                                fontSize={11}
                                fontWeight={700}
                                className={"dark:text-darkText"}
                              >
                                *타인의 이미지를 AI나 합성을 통해 재구성한
                                이미지도 초상권 침해에 해당 됩니다.
                              </Text>
                            </div>
                          </div>
                          <div className={"flex justify-end mt-4 gap-4"}>
                            <FillButton
                              onClick={() => {
                                setStepTwo(true);
                              }}
                              text={"네"}
                              size={"lg"}
                              className={"w-[100px]"}
                            />

                            <FillButton
                              className={
                                stepTwo ? "w-[100px] bg-gray-300" : "w-[100px]"
                              }
                              onClick={() => {
                                setStepTwo(false);
                                setStepThree(false);
                                dispatch(
                                  toastFetched({
                                    show: true,
                                    text: "초상권을 확보하지 않으면 업로드할 수 없습니다.",
                                    type: "error",
                                  }),
                                );
                              }}
                              text={"아니오"}
                              size={"lg"}
                              color={!stepTwo ? "red" : "gray"}
                            />
                          </div>
                        </>
                      }
                    />
                  </motion.div>
                )}

                {stepTwo && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <PreloadedImage
                      src="/img/standbuy/background/mobile-step-3.svg"
                      alt=""
                      className={"min-w-[320px]"}
                      content={
                        <>
                          <div className={"mt-4 flex justify-between"}>
                            <div className={"flex flex-col gap-3"}>
                              <Text
                                fontSize={14}
                                className={"dark:text-darkText"}
                              >
                                <strong>판매자(원작자)의 저작권 권리</strong>는
                                모두 상실하게 되며,{" "}
                                <strong>
                                  콘텐츠를 무단으로 사용할 수 없습니다.
                                </strong>
                              </Text>
                            </div>
                          </div>
                          <div className={"flex justify-end mt-4 gap-4"}>
                            <FillButton
                              onClick={() => {
                                setStep(1);
                              }}
                              text={"네"}
                              size={"lg"}
                              className={"w-[100px]"}
                            />

                            <FillButton
                              onClick={() => {
                                setStepTwo(false);
                                dispatch(
                                  toastFetched({
                                    show: true,
                                    text: "동의하지 않는다면, 판매할 수 없습니다.",
                                    type: "error",
                                  }),
                                );
                              }}
                              text={"아니오"}
                              size={"lg"}
                              className={
                                stepThree
                                  ? "w-[100px] bg-gray-300"
                                  : "w-[100px]"
                              }
                              color={!stepThree ? "red" : "gray"}
                            />
                          </div>
                        </>
                      }
                    />
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <motion.div
            className={`w-full flex justify-center mb-10`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
          >
            <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
              <div className="flex justify-between items-center mb-[30px]">
                <Text className="title dark:text-darkText">
                  콘텐츠 업로드 주의사항
                </Text>
              </div>

              <div className="flex flex-col gap-10">
                <div>
                  <img
                    src="/img/standbuy/background/step-1.svg"
                    alt=""
                    className={"min-w-[880px]"}
                  />

                  <div className={"mt-4 flex justify-between"}>
                    <div className={"flex flex-col gap-3"}>
                      <Text fontSize={16} className={"dark:text-darkText"}>
                        스탠바이에서 저작권이 확보되지 않은 콘텐츠를 판매 시,
                        모든 책임은 판매자에게 있습니다.{" "}
                      </Text>
                      <Text
                        fontSize={12}
                        fontWeight={700}
                        className={"dark:text-darkText"}
                      >
                        *스탠바이 운영지침에 따라 회원정지, 영구정지 등의 조치가
                        가능합니다.
                      </Text>
                    </div>

                    <div className={"flex gap-4"}>
                      <FillButton
                        onClick={() => {
                          setStepOne(true);
                        }}
                        text={"네"}
                        size={"lg"}
                        className={"w-[100px]"}
                      />

                      <FillButton
                        onClick={() => {
                          setStepOne(false);
                          setStepTwo(false);
                          setStepThree(false);
                          dispatch(
                            toastFetched({
                              show: true,
                              text: "저작권이 타인에게 있다면 업로드할 수 없습니다.",
                              type: "error",
                            }),
                          );
                        }}
                        text={"아니오"}
                        size={"lg"}
                        className={
                          stepOne ? "w-[100px] bg-gray-300" : "w-[100px]"
                        }
                        color={!stepTwo ? "red" : "gray"}
                      />
                    </div>
                  </div>
                </div>

                {stepOne && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <PreloadedImage
                      src="/img/standbuy/background/step-2.svg"
                      alt=""
                      className={"min-w-[880px]"}
                      content={
                        <div className={"mt-4 flex justify-between"}>
                          <div className={"flex flex-col gap-3"}>
                            <Text
                              fontSize={16}
                              className={"dark:text-darkText"}
                            >
                              사진/영상에 시각적으로 인식될 수 있는 인물의
                              초상권을 반드시 확보해야 합니다.
                            </Text>
                            <Text
                              fontSize={12}
                              fontWeight={700}
                              className={"dark:text-darkText"}
                            >
                              *타인의 이미지를 AI나 합성을 통해 재구성한
                              이미지도 초상권 침해에 해당 됩니다.
                            </Text>
                          </div>

                          <div className={"flex gap-4"}>
                            <FillButton
                              onClick={() => {
                                setStepTwo(true);
                              }}
                              text={"네"}
                              size={"lg"}
                              className={"w-[100px]"}
                            />

                            <FillButton
                              className={
                                stepTwo ? "w-[100px] bg-gray-300" : "w-[100px]"
                              }
                              onClick={() => {
                                setStepTwo(false);
                                setStepThree(false);
                                dispatch(
                                  toastFetched({
                                    show: true,
                                    text: "초상권을 확보하지 않으면 업로드할 수 없습니다.",
                                    type: "error",
                                  }),
                                );
                              }}
                              text={"아니오"}
                              size={"lg"}
                              color={!stepTwo ? "red" : "gray"}
                            />
                          </div>
                        </div>
                      }
                    />
                  </motion.div>
                )}

                {stepTwo && (
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    <PreloadedImage
                      src="/img/standbuy/background/step-3.svg"
                      alt=""
                      className={"min-w-[880px]"}
                      content={
                        <div className={"mt-4 flex justify-between"}>
                          <div className={"flex flex-col gap-3"}>
                            <Text
                              fontSize={16}
                              className={"dark:text-darkText"}
                            >
                              <strong>판매자(원작자)의 저작권 권리</strong>는
                              모두 상실하게 되며,{" "}
                              <strong>
                                콘텐츠를 무단으로 사용할 수 없습니다.
                              </strong>
                            </Text>
                          </div>

                          <div className={"flex gap-4"}>
                            <FillButton
                              onClick={() => {
                                setStep(1);
                              }}
                              text={"네"}
                              size={"lg"}
                              className={"w-[100px]"}
                            />

                            <FillButton
                              onClick={() => {
                                setStepTwo(false);
                                dispatch(
                                  toastFetched({
                                    show: true,
                                    text: "동의하지 않는다면, 판매할 수 없습니다.",
                                    type: "error",
                                  }),
                                );
                              }}
                              text={"아니오"}
                              size={"lg"}
                              className={
                                stepThree
                                  ? "w-[100px] bg-gray-300"
                                  : "w-[100px]"
                              }
                              color={!stepThree ? "red" : "gray"}
                            />
                          </div>
                        </div>
                      }
                    />
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
}
