//@ts-nocheck
import React from "react";
import Text from "../../../components/design-system/Text";
import Slide from "../../../components/landing/Slide";
import TextSlide from "../../../components/landing/TextSlide";
import { useMediaQuery } from "react-responsive";

export default function MobileSectionTwo() {
  const isMobile = useMediaQuery({ maxWidth: 575 });

  return (
    <div className="h-full w-full flex flex-col bg-[#000] relative">
      <div className="flex flex-col justify-center items-center mt-20 gap-10">
        <div className="w-[280px]">
          <img src="/img/standbuy/landing/error.png" />
        </div>

        <TextSlide delay={1.2}>
          <div
            className={`flex flex-col justify-center items-center gap-1 pb-10`}
          >
            <Text fontSize={30} fontWeight={700} color="#fff">
              사진/영상으로 가득찬
            </Text>
            <Text fontSize={30} fontWeight={700} color="#fff">
              내 스마트폰
            </Text>
          </div>
        </TextSlide>
      </div>
    </div>
  );
}
