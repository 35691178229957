import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { Input } from "@material-tailwind/react";
import { addComma } from "../../../util/number";
import FillButton from "../../../components/design-system/button/FillButton";
import { useContext } from "../../../util/useContext";
import ExChangeModal from "../modal/ExChangeModal";
import CustomTooltip from "../../../components/design-system/CustomTooltip";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMediaQuery } from "react-responsive";

export default function ExChangeForm({ data }: any) {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const { me } = useContext();
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState<any>();
  const [checkModal, setCheckModal] = useState<any>({
    show: false,
    data: "",
  });
  const handleInputChange = (event: any) => {
    const value = event.target.value;
    // 입력값이 me.earnCredit보다 크면, me.earnCredit 값을 사용
    if (value > me.earnCredit) {
      setInputValue(me.earnCredit.toString());
    } else {
      setInputValue(value);
    }
  };

  useEffect(() => {
    setCheckModal({ data: inputValue });
  }, [inputValue]);

  const handleValueCheck = () => {
    setCheckModal({ show: true });
  };

  return (
    <>
      <div className="flex flex-col items-start gap-4">
        <div className="flex items-center gap-2 mt-3">
          <Text
            fontSize={23}
            color="#303030"
            fontWeight={600}
            className="dark:text-darkText"
          >
            정산 요청하기
          </Text>
          {/*<CustomTooltip title="정산 최소 단위는 500 FOF 입니다." />*/}
        </div>

        <div className="flex justify-around mt-3">
          <div className="flex flex-col gap-10">
            <div className="w-full flex items-center gap-3">
              <Input
                type="number"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={
                  //@ts-ignore
                  addComma(data?.possible) >= 5000
                    ? addComma(data?.possible)
                    : `${addComma(data?.possible)}₩ (5,000원 부터 정산 요청 가능해요)`
                }
                className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
                labelProps={{
                  className: "hidden",
                }}
                containerProps={{
                  className: isMobile ? "max-w-[140px]" : "min-w-[100px]",
                }}
                crossOrigin={undefined}
                size="lg"
                max={me?.earnCredit}
                min={1}
                disabled={true}
              />

              <div>
                <FillButton
                  onClick={handleValueCheck}
                  text="정산"
                  className="w-[100px]"
                  size="lg"
                  disabled={data?.possible < 4999 ? true : false}
                />
              </div>
            </div>

            <div
              className={`bg-[#fbfbfb] dark:bg-darkBanner flex ${isMobile ? "flex-col items-center justify-center w-full" : "items-center justify-evenly  min-w-[550px] w-[550px]"} rounded-xl`}
            >
              <div className="px-[30px] py-5 flex flex-col justify-between items-center">
                <div className="flex items-center gap-3">
                  <Text
                    className="dark:text-darkText mb-[6px]"
                    fontSize={15}
                    color="#636363"
                    fontWeight={500}
                  >
                    총 수익금
                  </Text>
                </div>
                <Text className="subtitle-1 dark:text-darkText">
                  {addComma(data?.profit) || 0} ₩
                </Text>
              </div>

              <Text fontSize={30} fontWeight={600} color="#b3b3b3">
                -
              </Text>

              <div className="px-[30px] py-5 flex flex-col justify-between items-center">
                <div className="flex items-center gap-3">
                  <Text
                    className="dark:text-darkText mb-[6px]"
                    fontSize={15}
                    color="#636363"
                    fontWeight={500}
                  >
                    정산요청 금액
                  </Text>
                </div>
                <Text className="subtitle-1 dark:text-darkText">
                  {addComma(data?.possible) || 0} ₩
                </Text>
              </div>

              <Text fontSize={30} fontWeight={600} color="#b3b3b3">
                =
              </Text>

              <div className="px-[30px] py-5 flex flex-col justify-between items-center">
                <div className="flex items-center gap-3">
                  <Text
                    className="dark:text-darkText mb-[6px]"
                    fontSize={15}
                    color="#636363"
                    fontWeight={500}
                  >
                    정산 후 보유 수익금
                  </Text>
                </div>
                <Text className="subtitle-1 dark:text-darkText">
                  {data?.possible === undefined || data?.possible === ""
                    ? addComma(data?.profit)
                    : addComma(data?.profit - data?.possible)}{" "}
                  ₩
                </Text>
              </div>
            </div>
          </div>
        </div>
        <ul
          style={{
            listStyle: "inside",
            color: "#A7A7A7",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          <li>수익금에 대하여 현금으로 정산받을 수 있어요.</li>
          <li>
            정산 수수료(아티스트 : 33%, 디렉터 22%)를 제외하고 등록하신 계좌로
            정산해요.
          </li>
          <li>전체 수익 중, 구매가 확정된 금액까지 정산 받을 수 있어요</li>
        </ul>
      </div>
      {checkModal.show && (
        <ExChangeModal
          open={checkModal.show}
          onClose={() => setCheckModal({ show: false })}
          fof={data?.possible}
          me={me}
        />
      )}
    </>
  );
}
