import styled from "styled-components";
import Text from "../../components/design-system/Text";
import UploadMap from "../../components/google/UploadMap";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { LabelInputReset } from "../../components/design-system/input/LabelInputReset";
import {
  getGeocoder,
  getLatLng,
} from "../../lib/modules/google-map/google-map-util";
import { setUploadPayload } from "../../store/reducer/uploadSlice";
import { setGeoPosition } from "../../store/reducer/geoLocationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useGeolocation from "../../components/hooks/useGeoLocation";
import { LabelInputView } from "../../components/design-system/input/LabelInputView";
import FillButton from "../../components/design-system/button/FillButton";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import UploadCheckModal from "./modal/UploadCheckModal";
import UploadStepper from "../../components/upload/UploadStepper";
import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import { useMutation } from "@tanstack/react-query";
import UploadLoading from "./UploadLoading";
import SearchByGoogle from "../../lib/modules/google-map/SearchByGoogle";
import { useContext } from "../../util/useContext";
import { SwitchButton } from "../../components/design-system/SwitchButton";
import { useMediaQuery } from "react-responsive";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import CustomFooterFixed from "../../components/layout/footer/CustomFooterFixed";
import { uploadFlagFetched } from "../../store/reducer/uploadEndSlice";
import MobileFooter from "../../components/layout/footer/MobileFooter";

const BorderWrap = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`;

export default function UploadEnd() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const { state } = useContext();
  const location = useLocation();
  // const locationSet = useGeolocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { form, formProgress } = useAxios();
  const { lat, lng } = useAppSelector((state) => state.geoLocation);
  const { upload_payload } = useAppSelector((state) => state.upload);
  const [formData, setFormData] = useState<any>();
  const [progressData, setProgressData] = useState(0);
  const [switchData, setSwitchData] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [viewGeo, setViewGeo] = useState("");
  const [loading, setLoading] = useState(false);

  const [clickPos, setClickPos] = useState({
    lat: lat !== "" ? lat : Number(state?.lat),
    lng: lng !== "" ? lng : Number(state?.lon),
  });

  const extractThumbnailPath = (url: any) => {
    try {
      const urlObject = new URL(url);
      const pathname = urlObject.pathname;
      const index = pathname.indexOf("preview/thumbnail/");
      if (index !== -1) {
        return pathname.slice(index);
      }
      return url; // 만약 예상된 부분을 찾지 못하면 원래 URL 반환
    } catch (e) {
      return url;
    }
  };

  const onSubmit = async () => {
    // 주소-좌표 변환 객체를 생성합니다
    const res = await getLatLng(keyword);

    dispatch(
      setGeoPosition({
        lat: res?.lat,
        lng: res?.lng,
      }),
    );
  };

  async function onClickPos() {
    const data = {
      lat: clickPos?.lat,
      lng: clickPos?.lng,
    };

    const posRes = await getGeocoder(data);

    if (posRes) {
      setViewGeo(posRes?.address);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      onClickPos();
    }, 1000);
  }, [lat, lng, clickPos]);

  useEffect(() => {
    setClickPos({
      lat: lat !== "" ? lat : Number(state?.lat),
      lng: lng !== "" ? lng : Number(state?.lon),
    });
  }, [lat, lng]);

  useEffect(() => {
    setFormData(location.state);
  }, []);

  // onSubmit
  async function onUploadEnd(payload: any) {
    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;

      let percentage = Math.floor((loaded * 100) / total);
      setProgressData(percentage);
    };
    const res = await formProgress(
      `${API_DEFAULT}/contents/create-convert`,
      payload,
      onUploadProgress,
    );

    if (res?.success) {
      dispatch(uploadFlagFetched(res?.data.data.message));
      navigate("/upload-sccuess", { state: res?.data.data.message });
    } else {
      alert("업로드에 실패했습니다. 다시 시도해주세요.");
      navigate("/upload");
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onUploadEnd,
  });

  useEffect(() => {
    if (clickPos?.lat === 0 || clickPos?.lng === 0) {
      setViewGeo("등록된 주소 정보가 없습니다.");
    }
  }, []);

  return (
    <div className={"h-full"}>
      <FadeMotionDiv>
        <div
          className={`flex flex-col justify-center p-12 ${isMobile && "max-w-[420px]"}`}
        >
          <div className="flex flex-col justify-start items-start">
            <div
              className={`flex justify-between items-center w-full ${!isMobile && "mt-[80px]"}`}
            >
              <Text className={`title mb-5 dark:text-darkText `}>
                이제 거의 다 왔어요!
              </Text>

              {isMobile ? null : <UploadStepper />}
            </div>

            <div className="flex flex-col gap-2 mb-5">
              <Text className="location-info-text dark:text-[#535353]">
                지역 정보
              </Text>
              <Text className="label-1" color="#737373">
                촬영지, 창작지 정보를 등록하면, 검색에 유리해요!
              </Text>
            </div>

            <UploadMap lat={clickPos?.lat} lng={clickPos?.lng} />

            <BorderWrap
              className={`p-6 mt-10 ${isMobile ? "w-full" : "w-[880px]"}`}
            >
              <SearchByGoogle />
            </BorderWrap>

            <BorderWrap
              className={`p-6 mt-10 ${isMobile ? "w-full" : "w-[880px]"}`}
            >
              <LabelInputView
                readOnly={true}
                value={viewGeo}
                setValue={undefined}
                placeholder={""}
                label="가장 최근에 표시된 마커 주소의 정보가 저장됩니다."
                onKeyUp={(event: any) => {
                  if (event.key === "Enter") {
                    !!onSubmit && onSubmit();
                  }
                }}
              />
            </BorderWrap>

            <div className="flex flex-col items-center justify-center w-full mt-10">
              <div className="flex flex-col justify-center items-center gap-4 mb-8">
                <div className="flex items-center gap-3">
                  <SwitchButton
                    value={switchData}
                    onChange={() => setSwitchData(!switchData)}
                  />
                  <Text className="label-1 dark:text-darkText">
                    위치를 모르겠어요!
                  </Text>
                </div>

                {/* <Text className="label-1" color="#737373">
                  *활성화 하면 콘텐츠 위치 정보에 '위치를 모르겠어요!' 라는
                  문구가 들어갑니다.
                </Text> */}
              </div>

              <FillButton
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  mutate({
                    originFileName: formData.originFileName,
                    address:
                      switchData || clickPos?.lat === 0 || clickPos?.lng === 0
                        ? ""
                        : viewGeo,
                    content_latitude:
                      clickPos?.lat === 0
                        ? ""
                        : clickPos?.lat === "" && switchData === false
                          ? clickPos?.lat
                          : switchData
                            ? ""
                            : clickPos?.lat,
                    content_longitude:
                      clickPos?.lng === 0
                        ? ""
                        : clickPos?.lng === "" && switchData === false
                          ? clickPos?.lng
                          : switchData
                            ? ""
                            : clickPos?.lng,
                    ownership_price: Number(formData.data.ownershipPrice),
                    ticket_price: Number(formData.data.ticketPrice),
                    content_name: formData.data.content_name,
                    camera_info: formData.data.camera_info,
                    keyword: formData.data.keyword,
                    description: formData.data.description,
                    thumbnail: extractThumbnailPath(formData.data.thumbnail),
                    thumbnail_select: formData.data.thumbnail_select,
                    saveFileName: formData.saveFileName,
                    fileType: formData.fileType,
                    // format_name: formData.detailData.metadata.format_long_name,
                    // format_long_name:
                    //   formData.detailData.metadata.format_long_name,
                    // duration_in_seconds:
                    //   formData.detailData.metadata.duration_in_seconds,
                    // size: formData.detailData.metadata.size,
                    // bit_rate: formData.detailData.metadata.bit_rate,
                    // codec_name: formData.detailData.metadata.codec_name,
                    // width: formData.detailData.metadata.width,
                    // height: formData.detailData.metadata.height,
                    // channels: formData.detailData.metadata.channels,
                    // r_frame_rate: formData.detailData.metadata.r_frame_rate,
                    s: formData.data.s,
                  });
                }}
                text={"완료"}
                className="w-[360px] mb-4 flex justify-center"
                size="lg"
                loading={loading}
              />
            </div>
          </div>
        </div>
      </FadeMotionDiv>
      {isMobile ? <MobileFooter /> : <CustomFooter />}
    </div>
  );
}
