import React from "react";
import Modal from "../../design-system/modal/Modal";
import Text from "../../design-system/Text";
import FillButton from "../../design-system/button/FillButton";
import { useNavigate } from "react-router-dom";

export default function CartAddSelectorModal({ open, onClose }: any) {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          장바구니로 이동하시겠습니까?
        </Text>

        <FillButton
          text="장바구니로 이동"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => navigate("/cart")}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
