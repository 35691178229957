import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CustomImage } from "./CustomImg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";

export default function SideDrawer() {
  const navigate = useNavigate();
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  // 리스트 아이템 데이터 정의 (텍스트, 이미지 경로 및 클릭 액션 포함)
  const items = [
    {
      text: "내 정보 수정",
      icon: "/img/google-icons/mypage-edit.svg",
      action: () => navigate("/my-page"),
    },
    {
      text: "정산",
      icon: "/img/google-icons/money.svg",
      action: () => navigate("/exchange"),
    },
    {
      text: "정산내역",
      icon: "/img/google-icons/money.svg",
      action: () => navigate("/exchange-detail"),
    },
    // {
    //   text: "FOF 충전 내역",
    //   icon: "/img/google-icons/money.svg",
    //   action: () => navigate("/pay-list"),
    // },
    {
      text: "결제내역",
      icon: "/img/google-icons/money.svg",
      action: () => navigate("/fof-list"),
    },
    {
      text: "콘텐츠 거래내역",
      icon: "/img/google-icons/money.svg",
      action: () => navigate("/fof-credit"),
    },
    // {
    //   text: "판매 수익 관리",
    //   icon: "/img/google-icons/account.svg",
    //   action: () => navigate("/sales"),
    // },
  ];

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        width: 300,
        // "&:hover": {
        //   backgroundColor: "rgba(0, 0, 0, 0.1)", // 마우스 오버 시 적용될 배경색
        // },
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "30px",
        }}
      >
        <img
          src="/img/standbuy/logo/logo.svg"
          alt="Logo"
          style={{ maxWidth: "600px" }}
        />
      </Box>
      <List>
        {items.map((item, index) => (
          <>
            <ListItem
              key={index}
              disablePadding
              className="flex"
              onClick={item.action}
            >
              <ListItemButton sx={{ paddingLeft: "40px" }}>
                <ListItemIcon sx={{ minWidth: "32px" }}>
                  <img src={item.icon} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Button
        disableRipple
        onClick={toggleDrawer(true)}
        sx={{
          minWidth: 20,
          marginRight: 3,
          padding: 0,
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        <CustomImage
          src="/img/standbuy/icon/hamburger.svg"
          color={
            themeData === "light" || themeData === "" ? "#1a1a1a" : "#ececec"
          }
        />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
}
