//@ts-nocheck

import Banner from "../../components/common/Banner";
import Text from "../../components/design-system/Text";
import FilterBar from "../../components/feed/filter/FilterBar";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import { FadeLoader } from "react-spinners";
import { setStockList } from "../../store/reducer/mainStock";
import StockListCard from "./stock-list/StockListCard";
import { IconButton } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FeedNav } from "../../components/design-system/FeedNav";
import { setCookie } from "../../util/cookies";
import { useContext } from "../../util/useContext";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewFeedPage() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const themeData = useAppSelector((state) => state.theme.data);
  const { post } = useAxios();
  const { stockList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual,
  );
  const location = useLocation();
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const [itmes, setItems] = useState<any>([]);
  const { isLogin, me } = useContext();

  // useEffect(() => {
  //   setCookie("isLogin", isLogin);
  // }, [isLogin]);

  useEffect(() => {
    dispatch(setStockList({ data: itmes, reset: false }));
  }, [itmes]);

  // 진입 페이지
  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await post(
      `${API_DEFAULT}/contents/search/main?page=${nextGroupKey}&size=${count}`,
    );
    if (res?.success) {
      nextItems = res?.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  const moveToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        className={`${isMobile ? "mx-3  mt-[80px]" : "mx-12  mt-[120px]"} mb-10`}
      >
        <div className="my-[50px] mb-[30px]">
          <Banner />
        </div>

        <div className="mb-[30px] flex gap-5 overflow-auto">
          <FilterBar itmes={itmes} />
        </div>

        <JustifiedInfiniteGrid
          loading={
            <FadeLoader
              color={
                themeData === "light" || themeData === ""
                  ? "#121212"
                  : "#ffffff"
              }
            />
          }
          placeholder={
            <div className="placeholder">추가 데이터를 불러오는 중...</div>
          }
          stretch={true}
          passUnstretchRow={true}
          sizeRange={[228, 228]}
          stretchRange={[144, 320]}
          {...(isMobile && { columnRange: [0, 2] })}
          threshold={3000}
          gap={8}
          onRequestAppend={(e) => {
            const nextGroupKey = (+e.groupKey! || 0) + 1;
            if (nextGroupKey === groupKey) {
              return;
            }
            setGroupKey(nextGroupKey);
            e.wait();

            setTimeout(async () => {
              const add = await getItems(nextGroupKey, 15);
              if (add?.length === 0) {
                e.ready();
                return true;
              }

              dispatch(setStockList({ data: add, reset: false }));
              setItems([...itmes, ...add]);
              e.ready();
            }, 1);
          }}
        >
          {stockList.length < 1 ? (
            <div className="flex flex-col items-center justify-center">
              <Text className="subtitle-2 dark:text-darkText">
                조회된 피드가 없습니다.
              </Text>
              <Text className="label-1 dark:text-darkText">
                필터를 사용해서 다시 조회 해주세요!
              </Text>
            </div>
          ) : (
            stockList.map((item: any, index: any) => (
              <StockListCard
                data-grid-groupkey={item.groupKey}
                key={index}
                index={index}
                item={item}
                onClick={undefined}
              />
            ))
          )}
        </JustifiedInfiniteGrid>
      </div>

      <div className="fixed bottom-10 right-10 z-[9999]">
        <div className="flex flex-col justify-center items-center gap-4 z-[9999]">
          <FeedNav />

          <IconButton
            //@ts-ignore
            className="rounded-full px-[28px] py-[28px]"
            placeholder={undefined}
            children={undefined}
            size="lg"
            onClick={moveToTop}
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </IconButton>
        </div>
      </div>
    </>
  );
}
