import React from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import Modal from "../../../components/design-system/modal/Modal";
import { useNavigate } from "react-router-dom";

export default function ResetEndModal({ open, onClose }: any) {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={onClose} headerNone noOutSideClosed>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          비밀번호 설정을 완료했습니다.
        </Text>
        <Text
          className="upload-text dark:text-darkText mt-4"
          color={UploadTextColor}
        >
          로그인 후 스탠바이 서비스를 이용하실 수 있습니다.
        </Text>

        <FillButton
          text="로그인하러 가기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => navigate("/login")}
        />
      </div>
    </Modal>
  );
}
