import { TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";

export const InputTextField = styled(TextField)<{ themeData: string }>`
  & label {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-focused {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-error {
    color: #d32f2f;
  }
  & .MuiOutlinedInput-root {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
    & fieldset {
      border-color: ${({ themeData }) =>
        themeData === "dark" ? "transparent" : "#363636"};
    }
  }
  & .MuiInputBase-input {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
`;

export default function UploadNormalInput({
  value,
  setValue,
  label,
  placeholder,
  maxLength = 150,
  error = false,
  helperText,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  return (
    <InputTextField
      value={value}
      onChange={setValue}
      themeData={themeData}
      label={label}
      placeholder={placeholder}
      variant="standard"
      sx={{
        minWidth: "100%",
        borderBottom:
          themeData === "dark" && !value && helperText
            ? "1px solid transparent"
            : "1px solid white",
      }}
      InputLabelProps={{
        // 입력 값이 있을 때 라벨을 상단으로 이동
        shrink: !!value,
      }}
      inputProps={{
        maxLength: maxLength,
      }}
      error={error}
      helperText={!value && helperText}
    />
  );
}
