import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  detail: "",
  reportText: "",
};

export const feedSlice = createSlice({
  name: "feed",
  initialState: initialToastState,
  reducers: {
    setDetail: (state, action) => {
      state.detail = action.payload;
    },
    setReportText: (state, action) => {
      state.reportText = action.payload;
    },
  },
});

export const { setDetail, setReportText } = feedSlice.actions;

export default feedSlice.reducer;
