import FadeMotionDiv from "../../components/design-system/motion/FadeMotionDiv";
import UploadForm from "./UploadForm";

export default function UploadPage() {
  return (
    <FadeMotionDiv flag>
      <UploadForm />
    </FadeMotionDiv>
  );
}
