import styled from "styled-components";

const CustomText = styled.div<Props>`
  /* word-break: keep-all; */
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  /* white-space: pre-wrap; */
`;
const CustomTextSpan = styled.span<Props>`
  /* word-break: keep-all; */
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  /* white-space: pre-wrap; */
`;

export default function StockText({
  children,
  color = "#1A1A1A",
  fontSize = 16,
  fontWeight = "400",
  className = "",
  span = false,
  onClick,
  title,
  style,
}: Props) {
  return span ? (
    <CustomTextSpan
      title={title}
      className={className}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={onClick}
    >
      {children}
    </CustomTextSpan>
  ) : (
    <CustomText
      title={title}
      className={className}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      onClick={onClick}
      style={style}
    >
      {children}
    </CustomText>
  );
}

interface Props {
  children: any;
  className?: string;
  span?: boolean;
  onClick?: any;
  color?: string;
  fontSize?: string | number;
  fontWeight?: string | number;
  title?: string;
  style?: any;
}
