import React, { useEffect } from "react";
import { useContext } from "../useContext";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCAL_STORAGE, SESSION_STORAGE } from "../../lib/const";

export default function AuthContextProvider({ children }: any) {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem(LOCAL_STORAGE.LOGIN);
  const { me } = useContext();
  const location = useLocation();

  useEffect(() => {
    if (isLogin && me?.agreementSelectiveTerms) {
      return;
    } else if (isLogin && me?.agreementSelectiveTerms === false) {
      navigate("/social-check");
    } else if (isLogin && location.pathname === "/feed") {
      return;
    } else if (!isLogin) {
      navigate("/");
    }
  }, [navigate, me, location]);

  return <>{children}</>;
}
