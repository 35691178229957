//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Chip } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

export function ChipDelete({ state, chips, setChips, setGroupKey }: any) {
  const navigate = useNavigate();

  const handleDelete = (index: number) => {
    setChips(chips.filter((_, chipIndex) => chipIndex !== index));

    const checkedItemsString = chips.filter(
      (_, chipIndex) => chipIndex !== index,
    );
    navigate(`/feed/filter/${checkedItemsString}`, {
      state: checkedItemsString,
      replace: true,
    });
  };

  useEffect(() => {
    setChips(state);
  }, [state]);

  return (
    <>
      {chips?.map((item: any, index: any) => (
        <Chip key={index} value={item} onClose={() => handleDelete(index)} />
      ))}
    </>
  );
}
