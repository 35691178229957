import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import CustomCheckBox from "../../components/design-system/CustomCheckBox";
import NoCartData from "./card/NoCartData";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT, URL_HOST } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import { Chip } from "@material-tailwind/react";
import FillButton from "../../components/design-system/button/FillButton";
import CartDeleteModal from "./modal/CartDeleteModal";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { toastFetched } from "../../store/reducer/toastSlice";
import { useAppDispatch } from "../../store/store";
import CartEndModal from "./modal/CartEndModal";
import { useContext } from "../../util/useContext";
import FeedInfoModal from "../feed/modal/FeedInfoModal";
import { niceModalFetched } from "../../store/reducer/globalModalSlice";
import { nanoid } from "nanoid";
import { addComma } from "../../util/number";
import { setCookie } from "../../util/cookies";
import PriceTicketInfoModal from "../../components/common/PriceTicketInfoModal";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import PriceOwnerInfoModal from "../../components/common/PriceOwnerInfoModal";
import PriceInfoAllModal from "../../components/common/PriceInfoAllModal";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import { useNavigate } from "react-router-dom";

export default function CartPage() {
  const { get, post } = useAxios();
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const navigate = useNavigate();
  const customerKey = nanoid();
  const { me, isLogin } = useContext();
  const dispatch = useAppDispatch();
  const { ticket, owner, all } = useSelector((state: any) => state.priceInfo);
  const [selectedItemPrice, setSelectedItemPrice] = useState<any>([]);
  const [endCheck, setEndCheck] = useState(true);
  const [allPrice, setAllPrice] = useState(0);
  const [payToken, setPayToken] = useState<any>();
  const [checkItems, setCheckItems] = useState<any>([]);
  const [checkItemsName, setCheckItemsName] = useState<any>([]);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [deleteModal, setDeleteModal] = useState<any>({
    show: false,
    data: "",
    flag: "",
  });
  const [endModal, setEndModal] = useState<any>({
    show: false,
    data: "",
  });
  const [priceInfoModal, setPriceInfoModal] = useState({ show: false });
  const [priceOwnerModal, setPriceOwnerModal] = useState({ show: false });
  const [priceAllModal, setPriceAllModal] = useState({ show: false });
  const [containsTicket, setContainsTicket] = useState<any>();
  const [containsOwner, setContainsOwner] = useState<any>();
  const [containsBoth, setContainBoth] = useState<any>();

  const { data } = useQuery({
    queryKey: ["cart"],
    queryFn: async () => await get(`${API_DEFAULT}/user/cart/get`),
  });

  // useEffect(() => {
  //   if (me) {
  //     setCookie("isLogin", true);
  //   }
  // }, [me]);

  useEffect(() => {
    if (data?.data) {
      setContainsTicket(
        data?.data.cartList.some((item: any) => item.buyType === "TICKET"),
      );

      setContainsOwner(
        data?.data.cartList.some((item: any) => item.buyType === "OWNERSHIP"),
      );
      setContainBoth(
        data?.data.cartList.some((item: any) => item.buyType === "OWNERSHIP") &&
          data?.data.cartList.some((item: any) => item.buyType === "TICKET"),
      );
    }
  }, [data]);

  useEffect(() => {
    const bothContain = containsTicket && containsOwner;

    if (!isMobile) {
      if (!all && bothContain) {
        setPriceAllModal({ show: true });
      } else {
        if (!ticket && containsTicket) {
          setPriceInfoModal({ show: true });
        }
        if (!owner && containsOwner) {
          setPriceOwnerModal({ show: true });
        }
      }
    }
  }, [containsTicket, containsOwner]);

  const checkItemHandler = (cartId: any, isChecked: any, itemInfo: any) => {
    if (isChecked) {
      setCheckItems((prev: any) => [...prev, cartId]);
      setCheckItemsName((prev: any) => [...prev, itemInfo?.contentName]);

      setSelectedItemPrice((prev: any) => {
        // 이미 존재하는 cartId를 가진 아이템이 있는지 확인
        const isItemExists = prev.some(
          (i: any) => i.cartId === itemInfo.cartId,
        );
        // 중복되지 않는 경우에만 새 아이템 추가
        if (!isItemExists) {
          return [...prev, itemInfo];
        } else {
          return prev;
        }
      });
    } else {
      setCheckItems(checkItems.filter((item: any) => item !== cartId));
      setCheckItemsName(
        checkItemsName.filter((item: any) => itemInfo?.contentName !== item),
      );

      setSelectedItemPrice((prev: any) =>
        prev.filter((i: any) => i.cartId !== itemInfo.cartId),
      );
    }
  };

  const allCheckedHandler = (e: any, itemInfo: any) => {
    if (e.target.checked) {
      setCheckItems(data?.data?.cartList.map((item: any) => item.cartId));
      setCheckItemsName(
        data?.data?.cartList.map((item: any) => item.contentName),
      );
      setSelectedItemPrice(itemInfo);
    } else {
      setCheckItems([]);
      setSelectedItemPrice([]);
    }
  };

  useEffect(() => {
    if (
      checkItems?.length >= 1 ||
      checkItems?.length === data?.data?.cartList.length
    ) {
      const totalPrice = selectedItemPrice?.map((item: any) => item.price);

      const totalSum = totalPrice.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        },
        0,
      );
      setAllPrice(totalSum);
    } else {
      setAllPrice(0);
    }
  }, [checkItems, selectedItemPrice]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://ssl-v2.kiwoompay.co.kr/sdk/v1/latest/kiwoompay.js";
    script.charset = "EUC-KR";
    document.body.appendChild(script);

    script.onload = () => {
      // KiwoomPaySDK is ready to use
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  async function itemGetPay() {
    const payload = {
      amount: allPrice,
      orderName:
        checkItemsName?.length >= 2
          ? checkItemsName[0] + ` 외 ${checkItemsName?.length - 1}건`
          : checkItemsName[0],
      cartIds: checkItems,
    };
    const res = await post(`${API_DEFAULT}/user/payment/request`, payload);

    if (res?.success) {
      pay(
        res?.data.orderId,
        res?.data.amount,
        res?.data.orderName,
        me?.memberId,
        res?.data.payToken,
        res?.data.cpid,
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        }),
      );
    }
  }

  const pay = (
    orderId: any,
    amount: any,
    orderName: any,
    memberId: any,
    payToken: any,
    cpid: any,
  ) => {
    //@ts-ignore
    if (window.KiwoomPaySDK) {
      // const homeUrl = `${URL_HOST}pay/bill/${payToken}/success`;
      const homeUrl = `https://www.standbuy.io/fof-list`;

      //@ts-ignore
      window.KiwoomPaySDK.f_setInit("REAL", "FRAME");
      //@ts-ignore
      window.KiwoomPaySDK.f_payTotalLink({
        TYPE: isMobile ? "M" : "P",
        CPID: cpid,
        ORDERNO: orderId,
        AMOUNT: amount,
        PRODUCTNAME: orderName,
        USERID: memberId,
        SVC_CD_IN: "19, 24, 26",
        HOMEURL: homeUrl,
        FAILURL: URL_HOST + "pay/bill/fail",
      });
    } else {
      console.error("KiwoomPaySDK is not loaded yet.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <motion.div
          className={`w-full flex justify-center mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] mx-[200px] w-full ${isMobile ? "" : "max-w-[1280px]"}`}
          >
            <div
              className={`flex justify-between items-center mb-[30px] ${isMobile && "mx-5"}`}
            >
              <Text className="title dark:text-darkText">장바구니</Text>
            </div>

            {data?.data?.cartList !== undefined && (
              <div className={`flex items-center gap-4 ${isMobile && "px-5"}`}>
                <div className="flex gap-3">
                  <CustomCheckBox
                    checked={
                      checkItems.length === data?.data?.cartList.length
                        ? true
                        : false
                    }
                    onChange={(e: any) =>
                      allCheckedHandler(e, data?.data?.cartList)
                    }
                    label={""}
                  />
                  <Text className="dark:text-darkText">
                    전체선택 ({checkItems?.length || 0}/
                    {data?.data?.cartList?.length})
                  </Text>
                </div>

                <div className="w-[1px] h-[10px] bg-[#ccc]"></div>

                <Text
                  className="dark:text-darkText cursor-pointer"
                  onClick={() => {
                    if (checkItems?.length < 1) {
                      dispatch(
                        toastFetched({
                          show: true,
                          text: "선택된 항목이 없습니다.",
                          type: "error",
                        }),
                      );
                    } else {
                      setDeleteModal({
                        show: true,
                        data: checkItems,
                        flag: "all",
                      });
                    }
                  }}
                >
                  선택삭제
                </Text>
              </div>
            )}

            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />
            {data?.data?.cartList === undefined ? (
              <NoCartData />
            ) : (
              data?.data?.cartList.map((item: any, index: any) => {
                return (
                  <div
                    key={item?.cartId}
                    className={`${isMobile && "px-5"} mt-[30px]`}
                  >
                    <div className="flex justify-between">
                      <div className="flex items-start gap-3">
                        <CustomCheckBox
                          name={item?.cartId}
                          checked={
                            checkItems.includes(item.cartId) ? true : false
                          }
                          onChange={(e: any) =>
                            checkItemHandler(
                              item.cartId,
                              e.target.checked,
                              item,
                            )
                          }
                          label={""}
                        />
                        <img
                          src={item?.thumbnailPath}
                          className={`${isMobile ? "w-[50px] h-[50px]" : "w-[100px] h-[100px]"} rounded-lg cursor-pointer`}
                          onClick={() => {
                            if (isMobile) {
                              navigate(`/mobile-feed/${item?.contentId}`, {
                                state: item,
                              });
                            } else {
                              setInfoModal({ show: true, data: item });
                            }
                          }}
                        />

                        <div
                          className={`flex flex-col justify-between h-[100px]`}
                        >
                          <div className={`${isMobile && "max-w-[120px]"}`}>
                            <Text
                              className="dark:text-darkText"
                              fontSize={isMobile ? 12 : 18}
                              fontWeight={600}
                            >
                              {item?.contentName}
                            </Text>
                            <Text
                              className="dark:text-darkText"
                              fontWeight={500}
                              fontSize={isMobile ? 10 : 15}
                            >
                              {item?.nickname}
                            </Text>
                          </div>

                          <Chip
                            value={
                              item?.buyType === "TICKET" ? "이용권" : "소유권"
                            }
                            variant="outlined"
                            className="w-fit rounded-full text-[#909090] dark:text-darkText border-[#E5E5E5]"
                          />
                        </div>
                      </div>

                      <div className="flex items-center gap-[30px]">
                        <Text
                          className="dark:text-darkText"
                          fontWeight={600}
                          fontSize={isMobile ? 12 : 16}
                        >
                          ₩ {addComma(item?.price)}
                        </Text>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setDeleteModal({
                              show: true,
                              data: item?.cartId,
                            });
                          }}
                        >
                          <img
                            src="/img/google-icons/close.svg"
                            alt=""
                            width={24}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="w-full h-[1px] bg-[#E5E5E5] mt-[30px]"></div>
                  </div>
                );
              })
            )}

            {data?.data?.cartList !== undefined && (
              <div className="bg-[#fbfbfb] dark:bg-darkBg pt-[20px] px-[35px] pb-[10px]">
                <div className="flex items-center justify-evenly">
                  <div className="px-[30px] py-5 flex flex-col justify-between items-center">
                    <div className="flex items-center gap-3">
                      <Text
                        className="dark:text-darkText mb-[6px]"
                        fontSize={15}
                        color="#636363"
                        fontWeight={500}
                      >
                        구매 예정 상품 가격
                      </Text>
                    </div>
                    <Text className="subtitle-1 dark:text-darkText">
                      ₩ {addComma(allPrice)}
                    </Text>
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center mt-5 mb-5">
                  <div className="flex items-center mb-5">
                    <CustomCheckBox
                      checked={undefined}
                      onChange={() => {
                        setEndCheck(!endCheck);
                      }}
                      label={""}
                    />

                    <Text
                      className="label-2-weak dark:text-darkText"
                      color={"#909090"}
                    >
                      회원 본인은 주문내용을 확인했으며, 구매조건 및 개인정보
                      처리방침과 결제에 동의합니다.
                    </Text>
                  </div>

                  <FillButton
                    onClick={() => {
                      if (checkItems?.length === 0) {
                        dispatch(
                          toastFetched({
                            show: true,
                            text: "선택된 항목이 없습니다.",
                            type: "error",
                          }),
                        );
                      } else if (
                        isLogin &&
                        me?.mobileVerification &&
                        !selectedItemPrice.every(
                          (item: any) => item.price === 0,
                        )
                      ) {
                        itemGetPay();
                      } else if (
                        isLogin &&
                        me?.mobileVerification &&
                        selectedItemPrice.every((item: any) => item.price === 0)
                      ) {
                        setEndModal({ show: true, data: checkItems });
                      } else if (isLogin && !me?.mobileVerification) {
                        dispatch(niceModalFetched({ show: true }));
                      }
                    }}
                    text={`총 ${checkItems?.length || 0}개 구매하기`}
                    className="w-[350px]"
                    size="lg"
                    disabled={endCheck}
                  />
                </div>
              </div>
            )}

            {data?.data?.cartList === undefined ? null : (
              <div className={`flex mt-10 ${isMobile && "mx-5"}`}>
                <div>
                  <Text
                    fontSize={14}
                    color="#838383"
                    fontWeight={600}
                    className="mb-[10px] dark:text-darkText"
                  >
                    유의사항
                  </Text>
                  <ul
                    style={{
                      listStyle: "inside",
                      color: "#686868",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    <li>결제 전, 콘텐츠 유의사항을 꼭 확인해주세요.</li>
                    <li>
                      해당 콘텐츠는 인물의 초상권을 확보한 콘텐츠입니다. 초상권,
                      저작권 문제시 원작자에게 책임이 있습니다.
                    </li>
                    <li>
                      인공지능(AI), NFT, 빅데이터의 분석가공 등에 사용을
                      금합니다.
                    </li>
                    <li>콘텐츠내 폰트는 이미지 요소로만 제공하고 있습니다.</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </motion.div>

        {isMobile ? <MobileFooter /> : <CustomFooter />}

        {endModal.show && (
          <CartEndModal
            open={endModal.show}
            onClose={() => setEndModal({ show: false })}
            data={endModal.data}
          />
        )}

        {deleteModal.show && (
          <CartDeleteModal
            open={deleteModal.show}
            onClose={() => setDeleteModal({ show: false })}
            data={deleteModal.data}
            flag={deleteModal.flag}
            setCheckItems={setCheckItems}
          />
        )}

        {infoModal.show && (
          <FeedInfoModal
            open={infoModal.show}
            onClose={() => setInfoModal({ show: false })}
            data={infoModal.data}
          />
        )}

        {!containsBoth && priceInfoModal.show && (
          <PriceTicketInfoModal
            open={priceInfoModal.show}
            onClose={() => setPriceInfoModal({ show: false })}
          />
        )}

        {!containsBoth && priceOwnerModal.show && (
          <PriceOwnerInfoModal
            open={priceOwnerModal.show}
            onClose={() => setPriceOwnerModal({ show: false })}
            noRemoveBtn={true}
          />
        )}

        {priceAllModal.show && (
          <PriceInfoAllModal
            open={priceAllModal.show}
            onClose={() => setPriceAllModal({ show: false })}
            noRemoveBtn={true}
          />
        )}
      </div>
    </>
  );
}
