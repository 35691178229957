import React, { useState } from "react";

import { DarkModeSwitch } from "react-toggle-dark-mode";
import useDarkSide from "../hooks/useDarkSide";
import { useAppDispatch } from "../../store/store";
import { themeFetched } from "../../store/reducer/themeSlice";

export default function Switcher({ className }: any) {
  const dispatch = useAppDispatch();
  const [colorTheme, setTheme] = useDarkSide();
  const [darkSide, setDarkSide] = useState(
    colorTheme === "light" ? true : false
  );

  const toggleDarkMode = (checked: boolean) => {
    setTheme(colorTheme);
    setDarkSide(checked);
    dispatch(themeFetched(colorTheme));
  };

  return (
    <>
      <div className={className}>
        <DarkModeSwitch
          checked={darkSide}
          onChange={toggleDarkMode}
          size={24}
        />
      </div>
    </>
  );
}
