import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useContext } from "../../../util/useContext";

export default function AlarmDeleteModal({ open, onClose, data }: any) {
  const { del } = useAxios();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { me } = useContext();

  async function onRemoveAlarmList(alarmId: any) {
    const res = await del(
      `${API_DEFAULT}/user/alarm/${alarmId}/delete?memberId=${me?.memberId}`,
    );

    // if (res?.success) {
    //   onClose();
    //   dispatch(
    //     toastFetched({
    //       show: true,
    //       text: "항목을 삭제하였습니다.",
    //       type: "success",
    //     }),
    //   );
    //   window.location.reload();
    // }
    window.location.reload();
  }

  const { mutate } = useMutation({
    mutationFn: onRemoveAlarmList,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alarm-list"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          알림을 삭제하시겠어요?
        </Text>
        {/*<Text*/}
        {/*  className="upload-text dark:text-darkText mt-4"*/}
        {/*  color={UploadTextColor}*/}
        {/*>*/}
        {/*  상품을 삭제하면 장바구니에서 사라져요.*/}
        {/*</Text>*/}

        <FillButton
          text="삭제하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => {
            mutate(data?.alarmId);
          }}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
