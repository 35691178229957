import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  contentId: number | null; //마지막 contentId를 넣어 줍니다
  isAppend: boolean;
  stockList: any[];
  popup_mode: string;
  [key: string]: any;
};

const initialState: StateType = {
  isAppend: true,
  stockList: [],
  keywordStockList: [],
  hotStockList: [],
  resetStockList: "",
  contentId: null,
  popup_mode: "",
  data: {},
  flag: "",
  switchFollower: false,
  switchDirector: false,
  followerList: [],
  directorList: [],
  assetsList: [],
  videoList: [],
  studioList: [],
  othersStudioList: [],
  searchStudioList: [],
  keyword: "",
};

export const mainStockSlice = createSlice({
  name: "mainStock",
  initialState,
  reducers: {
    setContentId(state: StateType, action) {
      state.contentId = action.payload;
    },
    setContentIdReset(state: StateType) {
      state.contentId = null;
    },
    setStockListInit(state: StateType) {
      state.stockList = [];
    },
    setStockList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.stockList = action.payload?.data;
      } else {
        state.stockList = state.stockList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    // 팝업모드
    setPopupMode(state: StateType, action) {
      state.popup_mode = action.payload;
    },
    setKeywordStockList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.keywordStockList = action.payload?.data;
      } else {
        state.keywordStockList = state.videoList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },

    setKeyword(state: StateType, action) {
      state.keyword = action.payload.keyword;
    },
    setKeywordStockListInit(state: StateType) {
      state.keywordStockList = [];
    },
    setStockFlag(state: StateType, action) {
      state.flag = action.payload.flag;
    },
    setResetStockList(state: StateType, action) {
      state.resetStockList = action.payload;
    },
    setHotList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.hotStockList = action.payload?.data;
      } else {
        state.hotStockList = state.hotStockList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    setFollowerList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.followerList = action.payload?.data;
      } else {
        state.followerList = state.followerList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    setDirectorList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.directorList = action.payload?.data;
      } else {
        state.directorList = state.directorList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    setAssetsList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.assetsList = action.payload?.data;
      } else {
        state.assetsList = state.assetsList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },

    setAssetsListInit(state: StateType) {
      state.assetsList = [];
    },
    setVideoList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.videoList = action.payload?.data;
      } else {
        state.videoList = state.videoList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    setStudioList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.studioList = action.payload?.data;
      } else {
        state.studioList = state.studioList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
    setStudioListInit(state: StateType) {
      state.studioList = [];
    },
    setOthersStudioList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.othersStudioList = action.payload?.data;
      } else {
        state.othersStudioList = state.othersStudioList.concat(
          action.payload?.data
        );
      }
      state.contentId = tempId;
    },
    setOtherStudioListInit(state: StateType) {
      state.othersStudioList = [];
    },

    setSearchStudioList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.searchStudioList = action.payload?.data;
      } else {
        state.searchStudioList = state.searchStudioList.concat(
          action.payload?.data
        );
      }
      state.contentId = tempId;
    },
    setSearchStudioListInit(state: StateType) {
      state.searchStudioList = [];
    },
  },
});

export const {
  setContentId,
  setStockListInit,
  setStockList,
  setKeywordStockList,
  setStockFlag,
  setResetStockList,
  setFollowerList,
  setDirectorList,
  setAssetsList,
  setVideoList,
  setKeywordStockListInit,
  setStudioList,
  setOthersStudioList,
  setOtherStudioListInit,
  setKeyword,
  setContentIdReset,
  setAssetsListInit,
  setHotList,
  setStudioListInit,
  setSearchStudioList,
  setSearchStudioListInit,
} = mainStockSlice.actions;

export default mainStockSlice.reducer;
