//PRIMARY
export const PRIMARY25 = "#F9F9FF";
export const PRIMARY50 = "#F3F3FF";
export const PRIMARY100 = "#E0E2FF";
export const PRIMARY200 = "#CDCFFF";
export const PRIMARY300 = "#B2B6FF";
export const PRIMARY400 = "#7F7CFF";
export const PRIMARY500 = "#6558FF";
export const PRIMARY600 = "#532CFF";
export const PRIMARY700 = "#3F1DD1";
export const PRIMARY800 = "#341AA6";
export const PRIMARY900 = "#291778";
//SKYBLUE
export const SKYBLUE50 = "#E5F2FF";
export const SKYBLUE100 = "#D3E7FF";
export const SKYBLUE200 = "#AFCFFF";
export const SKYBLUE300 = "#87B0FF";
export const SKYBLUE400 = "#638EFF";
export const SKYBLUE500 = "#4B75E4";
export const SKYBLUE600 = "#355DC6";
export const SKYBLUE700 = "#2D4EA6";
export const SKYBLUE800 = "#203E8C";
export const SKYBLUE900 = "#18306D";
//PURPLE
export const PURPLE50 = "#DFD8FB";
export const PURPLE100 = "#CBC0F5";
export const PURPLE200 = "#C3B5FF";
export const PURPLE300 = "#B6A8EE";
export const PURPLE400 = "#8873DD";
export const PURPLE500 = "#6E56CF";
export const PURPLE600 = "#5640AD";
export const PURPLE700 = "#463392";
export const PURPLE800 = "#392A76";
export const PURPLE900 = "#2D215A";
//GREEN
export const GREEN50 = "#ECFFF5";
export const GREEN100 = "#C3F6DB";
export const GREEN200 = "#82E1BF";
export const GREEN300 = "#4FCD9F";
export const GREEN400 = "#35B790";
export const GREEN500 = "#0DA782";
export const GREEN600 = "#018E6C";
export const GREEN700 = "#047158";
export const GREEN800 = "#085D49";
export const GREEN900 = "#034A3A";
//YELLOW
export const YELLOW50 = "#FEF8EB";
export const YELLOW100 = "#FFECBA";
export const YELLOW200 = "#FFDA7C";
export const YELLOW300 = "#FFC75B";
export const YELLOW400 = "#FEB11C";
export const YELLOW500 = "#EB9B00";
export const YELLOW600 = "#DA8301";
export const YELLOW700 = "#B06A01";
export const YELLOW800 = "#844F00";
export const YELLOW900 = "#633C01";
//ORANGE
export const ORANGE50 = "#FFEEE2";
export const ORANGE100 = "#FFD4B7";
export const ORANGE200 = "#FFBC8D";
export const ORANGE300 = "#FFA363";
export const ORANGE400 = "#FF8C3E";
export const ORANGE500 = "#ED7422";
export const ORANGE600 = "#E16611";
export const ORANGE700 = "#C15103";
export const ORANGE800 = "#A14200";
export const ORANGE900 = "#7D3301";
//RED
export const RED50 = "#FFEDEB";
export const RED100 = "#FFCDC7";
export const RED200 = "#FFA69A";
export const RED300 = "#FF8271";
export const RED400 = "#FD5E49";
export const RED500 = "#E2331B";
export const RED600 = "#BD1700";
export const RED700 = "#AA0A00";
export const RED800 = "#8F0900";
export const RED900 = "#700700";
//GRAY
export const GRAY25 = "#FBFBFC";
export const GRAY50 = "#F7F8FA";
export const GRAY100 = "#F0F1F4";
export const GRAY200 = "#E7E9ED";
export const GRAY300 = "#DBDEE2";
export const GRAY400 = "#CBCFD5";
export const GRAY500 = "#AAB0B8";
export const GRAY600 = "#838991";
export const GRAY700 = "#484E55";
export const GRAY800 = "#33383D";
export const GRAY900 = "#0A0A0E";

//WHITE
export const WHITE = "#fff";
//BLACK
export const BLACK = "#000";

export const ErrorText = "#dc281f";
export const ProfileUnderLineGreen = "#42BC7F";
export const FooterTextColor = "#838383";
export const UploadTextColor = "#828282";
export const LabelGray = "#797979";
