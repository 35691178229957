/**
 * @name Help
 * @description object 관련유틸리티
 */

// 아티스트,디렉터체크
export const checkRole = (str: string) => {
  if (str === 'ROLE_ARTIST') return '아티스트'
  if (str === 'ROLE_DIRECTOR') return '디렉터'
  return ''
}
