import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE } from "../../lib/const";

export default function AuthContextFalseProvider({ children }: any) {
  const navigate = useNavigate();
  const isLogin = localStorage.getItem(LOCAL_STORAGE.LOGIN);

  useEffect(() => {
    if (isLogin === null) {
      return;
    } else {
      navigate("/feed");
    }
  }, [isLogin, navigate]);

  return <>{children}</>;
}
