import React from "react";
import Text from "../../../components/design-system/Text";
import { useScroll, useTransform } from "framer-motion";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useContext } from "../../../util/useContext";
import { useMediaQuery } from "react-responsive";

export default function MobileSectionFour() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const navigate = useNavigate();
  const { isLogin, me } = useContext();
  const { scrollYProgress } = useScroll();
  const x = useTransform(
    scrollYProgress,
    [0, 1],
    [isMobile ? "-1600%" : "-200%", "300%"],
  );

  return (
    <div className="h-fit flex flex-col relative bg-[#000] overflow-x-hidden">
      <div className="flex flex-col justify-center items-center gap-3 mt-[50px]">
        <motion.div style={{ x }}>
          <div className="max-w-full flex gap-20">
            <img src="/img/standbuy/landing/slide-1.png" />
            <img src="/img/standbuy/landing/slide-2.png" />
            <img src="/img/standbuy/landing/slide-3.png" />
            <img src="/img/standbuy/landing/slide-4.png" />
          </div>
        </motion.div>

        <div className="mt-20 flex flex-col items-center">
          <Text
            fontSize={isMobile ? 24 : 70}
            fontWeight={600}
            color="#fff"
            className="leading-[0.5]"
          >
            내 스마트폰 갤러리 속
          </Text>
          <Text
            fontSize={isMobile ? 24 : 70}
            fontWeight={600}
            color="#fff"
            className="mb-24"
          >
            사진 영상을 팔아보세요
          </Text>
        </div>

        <div className={`w-full flex flex-col`}>
          <img src="/img/standbuy/landing/coin.svg" className={"px-4"} />
        </div>

        <div className="bg-[rgba(255,255,255,0.72)] z-[100] py-2 px-10 rounded-full flex items-center gap-8 mb-10">
          <Text
            fontSize={isMobile ? 20 : 36}
            color="#000"
            fontWeight={700}
            className="z-[100]"
          >
            내 갤러리 사진
          </Text>
          {isLogin && me?.agreementSelectiveTerms && (
            <button
              className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
              onClick={() => navigate(isLogin ? "/feed" : "/login")}
            >
              <Text color="#fff" fontWeight={600}>
                팔러가기
              </Text>
            </button>
          )}
          {isLogin && me?.agreementSelectiveTerms === false && (
            <button
              className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
              onClick={() => navigate("/social-check")}
            >
              <Text color="#fff" fontWeight={600}>
                팔러가기
              </Text>
            </button>
          )}
          {isLogin === false && (
            <button
              className="rounded-full bg-[#42B77F] py-3 px-7 opacity-1 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <Text color="#fff" fontWeight={600}>
                팔러가기
              </Text>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
