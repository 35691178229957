import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  browser: "",
};

export const browserSlice = createSlice({
  name: "browser",
  initialState: initialToastState,
  reducers: {
    browserFetched: (state, action) => {
      state.browser = action.payload;
    },
  },
});

export const { browserFetched } = browserSlice.actions;

export default browserSlice.reducer;
