import { Autocomplete, TextField } from "@mui/material";
import { Chip as MaterialChip } from "@material-tailwind/react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { useEffect, useState } from "react";

export const InputTextField = styled(TextField)<{ themeData: string }>`
  & label {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-focused {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & label.Mui-error {
    color: #d32f2f;
  }
  & .MuiOutlinedInput-root {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
    & fieldset {
      border-color: ${({ themeData }) =>
        themeData === "dark" ? "white" : "#363636"};
    }
  }
  & .MuiInputBase-input {
    color: ${({ themeData }) => (themeData === "dark" ? "white" : "#363636")};
  }
  & .MuiInput-underline:after {
    border-bottom-color: ${({ themeData }) =>
      themeData === "dark" ? "white" : "#363636"};
  }
`;

export default function UploadAutoInput({
  value,
  setValue,
  readOnly = false,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);

  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      readOnly={readOnly}
      multiple
      options={[]}
      defaultValue={[]}
      freeSolo
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        const cleanedValue = newInputValue.replace("#", "");
        setInputValue(cleanedValue);
      }}
      value={value || []}
      renderTags={(value: any, getTagProps) =>
        value.map((option: string, index: number) => (
          <MaterialChip
            variant="filled"
            value={option}
            {...getTagProps({ index })}
            className="rounded-full my-1 mr-1"
            style={{ textTransform: "none" }}
          />
        ))
      }
      renderInput={(params) => (
        <InputTextField
          themeData={themeData}
          {...params}
          label="키워드(입력하고 엔터를 꼭 눌러주세요!)"
          variant="standard"
          placeholder="최소 5개 이상 키워드를 입력해주세요. (ex.제주도, 바다..)"
          sx={{
            borderBottom:
              themeData === "dark" && inputValue?.length <= 4
                ? "1px solid transparent"
                : "1px solid white",
          }}
          error={value?.length < 5} // 5개 미만 입력 시 에러 상태로 표시
          helperText={
            value?.length < 5 ? "최소 5개의 키워드를 입력해야 합니다." : ""
          }
        />
      )}
    />
  );
}
