import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  data: "",
  recordChecked: "",
};

export const loginEmailSlice = createSlice({
  name: "email",
  initialState: initialToastState,
  reducers: {
    loginEmailFetched: (state, action) => {
      state.data = action.payload;
    },
    checkEmailFetched: (state, action) => {
      state.recordChecked = action.payload;
    },
  },
});

export const { loginEmailFetched, checkEmailFetched } = loginEmailSlice.actions;

export default loginEmailSlice.reducer;
