import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  data: { show: false, text: "" },
  niceData: { show: false, text: "" },
  overlapData: { show: false, text: "" },
};

export const globalModalSlice = createSlice({
  name: "modal",
  initialState: initialToastState,
  reducers: {
    modalFetched: (state, action) => {
      state.data = action.payload;
    },
    niceModalFetched: (state, action) => {
      state.niceData = action.payload;
    },
    overlapModalFetched: (state, action) => {
      state.overlapData = action.payload;
    },
  },
});

export const { modalFetched, niceModalFetched, overlapModalFetched } =
  globalModalSlice.actions;

export default globalModalSlice.reducer;
