import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import NoticeTable from "./card/NoticeTable";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../lib/api/useAxios";
import { API_DEFAULT } from "../../api/api";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { useContext } from "../../util/useContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function NoticePage() {
  const { get } = useAxios();
  const { state, me, isLogin } = useContext();
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (me?.agreementSelectiveTerms === false) {
  //     navigate("/social-check");
  //   }
  // }, [location.pathname, me]);

  const { status, data } = useQuery({
    queryKey: ["notice"],
    queryFn: async () => await get(`${API_DEFAULT}/user/notice/get`),
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">공지사항</Text>
          </div>

          <NoticeTable data={data?.data} />
        </div>
      </motion.div>

      <CustomFooter />
    </div>
  );
}
