import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { UploadTextColor } from "../../../styles/color";
import { useNavigate } from "react-router-dom";

export default function JoinEndSelectModal({ open, onClose }: any) {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={onClose} noOutSideClosed={true} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          원활한 서비스 이용을 위해 본인인증이 필요합니다.
        </Text>
        <Text
          className="dark:text-darkText mt-4"
          color={UploadTextColor}
          fontSize={16}
          fontWeight={600}
        >
          로그인은 가능하지만
        </Text>

        <Text
          className="dark:text-darkText"
          color={UploadTextColor}
          fontSize={16}
          fontWeight={600}
        >
          일부 기능이 제한될 수 있습니다.
        </Text>

        <FillButton
          text="본인인증 하러가기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => navigate("/nice-auth")}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px]"
          onClick={() => navigate("/feed")}
        >
          나중에 할게요!
        </Text>
      </div>
    </Modal>
  );
}
