import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import styled from "styled-components";

const Content = styled.div`
  .wrapper {
    padding: 24px;
    /* border: 1px solid #5f5f5f; */
    /* border-radius: 8px; */
    font-size: 16px;
    line-height: 140%;
    flex-direction: column;
    background: #fff;
    background-color: #fff;
    h2 {
      padding-left: 10px;
      color: #111111;
      font-size: 14px;
    }
    ul {
      margin-bottom: 20px;
      padding-left: 20px;
      li {
        /* padding-left: 10px; */
        color: #5f5f5f;
        font-size: 12px;
      }
    }
    p {
      color: #5f5f5f;
      font-size: 12px;
    }
  }
`;

export default function LocationTermsPage() {
  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[120px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">
              위치기반서비스 이용약관
            </Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px]" />

          <Content>
            <div className="wrapper">
              <h2>제 1조 (목적)</h2>
              <p>
                본 약관은 이용자(회사가 정한 소정의 절차를 걸쳐서 가입한 회원과
                회원으로 가입하지 않고 스탠바이 서비스를 이용하는 비회원을
                포괄하여 지칭합니다. 이하 “이용자”라고 합니다.)가
                (주)에프오에프(이하 “회사”라 합니다”)이 제공하는 제반 서비스 중
                위치기반서비스(이하”서비스”라 합니다)를 이용함에 있어 “회사”와
                “이용자”간의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
              </p>

              <br />

              <h2>제 2조 이용약관의 효력 및 변경</h2>

              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.본 약관은 서비스를 신청한 이용자 또는 개인위치정보주체가 본
                  약관에 동의하고 “회사”가 정한 소정의 절차에 따라 서비스의
                  “이용자”로 등록함으로써 효력이 발생합니다.
                </li>
                <li>
                  2.회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
                  화면에 게시하거나 기타의 방법으로 공지합니다.
                </li>
                <li>
                  3.회사는 위치기반서비스의 변경사항을 반영하기 위한 목적 등으로
                  필요한 경우 관련 법령을 위배하지 않는 범위에서 본 약관을
                  수정할 수 있습니다.
                </li>
                <li>
                  4.약관이 변경되는 경우 회사는 변경사항을 그 적용일자 최소 7일
                  전에 회사의 홈페이지 또는 서비스 공지사항 등(이하, 홈페이지
                  등)을 통해 공지합니다. 다만, 개정되는 내용이 이용자 권리의
                  중대한 변경을 발생시키는 경우 적용일 최소 30일 전에 홈페이지
                  등을 통해 공지합니다.
                </li>
                <li>
                  5.회사가 전항에 따라 공지 또는 통지를 하면서 공지 또는
                  통지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지
                  아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도
                  이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로
                  봅니다. 이용자가 개정약관에 동의하지 않을 경우 본 약관에 대한
                  동의를 철회할 수 있습니다.
                </li>
              </ul>

              <br />

              <h2>제 3조 약관 외 준칙</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  본 약관에 규정되지 않은 사항에 대해서는 위치정보의 보호 및
                  이용 등에 관한 법률(이하 “위치정보법”이라고 합니다),
                  전기통신사업법, 정보통신망 이용촉진 및 보호 등에 관한
                  법률(이하 “정보통신망법”이라고 합니다), 개인정보 보호법 등
                  관련법령 또는 회사가 정한 서비스의 운영정책 및 규칙 등(이하
                  “세부지침”이라고 합니다)의 규정에 따릅니다.
                </li>
              </ul>

              <br />

              <h2>제 4조 서비스의 가입 및 해지</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1."이용자"는 회사의 서비스를 이용하고자 하는 경우, 약관의 고지
                  내용에 따라 개인위치정보 서비스에 가입하게 됩니다. 회원의 경우
                  회원가입 시 동의절차를 밟으며, 비회원인 경우 서비스를 이용하는
                  동안 이 약관에 동의한 것으로 간주합니다.
                </li>
                <li>
                  2."회원"이 서비스 이용을 해지하고자 할 때에는 회사가 정한
                  절차를 통해 서비스 해지를 신청하거나, "회사"의 개인정보보호
                  담당자에게 이메일을 통해 해지신청을 하여야 합니다.
                </li>
              </ul>

              <br />

              <h2>제 5조 서비스의 내용</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <ul
                  style={{
                    listStyle: "inside",
                    color: "#A7A7A7",
                    fontSize: "12px",
                    fontWeight: "500",
                    marginTop: "8px",
                  }}
                >
                  <li>
                    1.서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 단,
                    회사의 업무 또는 기술상의 이유로 서비스가 일시 중지될 수
                    있으며, 운영상의 목적으로 회사가 정한 기간에도 서비스는 일시
                    중지될 수 있습니다. 이때 회사는 사전 또는 사후에 이를
                    공지합니다.
                  </li>
                  <li>
                    2.“회사”가 제공하는 서비스는 아래와 같습니다.
                    <ol>
                      <li>1) 서비스 명 : 스탠바이</li>
                      <li>
                        2) 서비스 내용 <br />- 콘텐츠 관한 정보제공 서비스{" "}
                        <br />- 콘텐츠 등록 서비스 <br />- 콘텐츠 위치 등록
                        서비스 <br />- 콘텐츠 지도로 찾기 서비스
                      </li>
                    </ol>
                  </li>
                  <li>
                    3."회사"는 "이용자"가 등록한 매물의 위치정보만을
                    "이용자"에게 제공하며, 해당 위치정보를 다른 정보와 결합하여
                    개인위치정보로 이용하지 않습니다.
                  </li>
                  <li>
                    4.제공되는 "이용자"의 매물 위치정보는 해당 유, 무선 단말기의
                    종류와 상관없이 서비스를 이용할 수 있는 기기에서 제공합니다.
                  </li>
                  <li>
                    5."회사"는 위치정보사업자인 이동통신사로부터 위치정보를
                    전달받아 "스탠바이"의 모바일 단말기 전용 애플리케이션(이하
                    "애플리케이션")을 통해 아래와 같은 위치기반서비스를
                    제공합니다.
                    <ol>
                      <li>
                        -접속 위치 제공 서비스 : 위치 정보 사용을 승인한
                        "이용자"들의 서비스 최종 접속 위치를 기반으로 서비스
                        내의 정보를 지도 위에 혹은 리스트를 통해 제공합니다.
                      </li>
                      <li>
                        -위치 정보 : 모바일 단말기 등의 WPS(Wifi Positioning
                        System), GPS 기반으로 추출된 좌표를 이용하여 "이용자"가
                        생성하는 지점을 말합니다.
                      </li>
                      <li>
                        -최종 접속 위치를 활용한 검색 결과 제공 서비스 : 정보
                        검색 요청 시 개인위치정보주체의 현위치를 이용한 서비스
                        내의 기능에 따라 제공되는 정보에 대하여 검색 결과를
                        제시합니다.
                      </li>
                      <li>
                        -"이용자"의 위치 정보의 갱신은 "스탠바이" 실행 시 또는
                        실행 후, 위치 관련 메뉴 이용 시 이루어지며, "이용자"가
                        갱신한 사용자의 위치정보를 기준으로 최종 위치를
                        반영합니다.
                      </li>
                    </ol>
                  </li>
                </ul>
              </ul>

              <br />

              <h2>제 6조 서비스 이용요금</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.“회사”가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의
                  유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용
                  가능합니다.
                </li>
                <li>
                  2.“회사”는 유료 서비스 이용요금을 “회사”와 계약한
                  전자지불업체에서 정한 방법에 의하거나 “회사”가 정한 청구서에
                  합산하여 청구할 수 있습니다.
                </li>
                <li>
                  3.유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은
                  “회사”의 결제 이용약관 등 관계법에 따릅니다.
                </li>
                <li>
                  4.“이용자”의 개인정보도용 및 결제사기로 인한 환불요청 또는
                  결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수
                  있습니다.
                </li>
                <li>
                  5.무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한
                  각 이동통신사의 정책에 따릅니다.
                </li>
                <li>
                  6.MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의
                  정책에 따릅니다.
                </li>
              </ul>

              <br />

              <h2>제 7조 접속자의 위치정보 이용</h2>
              <p>
                "회사"는 "회원"이 약관 등에 동의하는 경우 또는 비회원이 위치
                관련 메뉴 이용 시에 한해 단말기를 통해 수집된 위치정보를
                활용하여 정보 및 회원의 게시물을 제공할 수 있습니다.
              </p>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.약관 등에 동의를 한 회원 또는 비회원이 위치관련 메뉴 사용시
                  서비스 이용을 위해 본인의 위치를 자의적으로 노출하였다고
                  간주하며 "회사"는 "이용자"의 실시간 위치정보를 바탕으로
                  콘텐츠를 제공합니다.
                </li>
                <li>
                  2.장소정보 및 콘텐츠 입력 등 서비스 이용 시 "이용자"가 생성한
                  콘텐츠에 대해 "회사"는 "이용자"의 위치에 대한 정보를 저장,
                  보존하지 않습니다. "회사"는 장소정보 또는 "이용자"가 등록한
                  게시물을 이용자의 현재위치를 기반으로 추천하기 위해 위치정보를
                  이용합니다.
                </li>
              </ul>

              <br />

              <h2>제 8조 개인위치정보의 이용 또는 제공에 관한 동의</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1."회사"는 개인위치정보주체의 동의 없이 당해
                  개인위치정보주체의 개인위치정보를 제3자에게 제공하지 아니
                  하며, 제3자 제공 "서비스"를 제공하는 경우에는 제공 받는 자 및
                  제공목적을 사전에 개인위치정보주체에게 고지하고 동의를
                  받습니다.
                </li>
                <li>
                  2."회사"는 개인위치정보를 개인위치정보주체가 지정하는
                  제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해
                  통신단말장치로 매회 개인위치정보주체에게 제공받는 자, 제공
                  일시 및 제공목적을 즉시 통보합니다. 다만, 아래에 해당하는
                  경우에는 개인위치정보주체가 미리 특정하여 지정한 통신단말장치
                  또는 전자우편주소 등으로 통보합니다.
                  <ol>
                    <li>
                      1)개인위치정보를 수집한 당해 통신단말장치가 문자, 음성
                      또는 영상의 수신기능을 갖추지 아니한 경우
                    </li>
                    <li>
                      2)가입신청자가 이전에 회원 자격을 상실한 적이 있는
                      경우(다만 회원 자격 상실 후 회사가 필요하다고 판단하여
                      회원 재가입에 대한 승낙을 얻은 경우에는 예외로 합니다.
                    </li>
                    <li>
                      3)개인위치정보주체가 개인위치정보를 수집한 당해
                      통신단말장치 외의 통신단말장치 또는 전자우편주소 등으로
                      통보할 것을 미리 요청한 경우
                    </li>
                  </ol>
                </li>
                <li>
                  3."회사"는 본 약관의 목적 외 다른 용도로 개인위치정보의 이용
                  또는 제공사실 확인자료를 기록하거나 보존하지 아니합니다.
                </li>
              </ul>

              <br />

              <h2>제 9조 개인위치정보주체의 권리</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.개인위치정보주체는 개인위치정보의 이용•제공에 대한 동의의
                  전부 또는 일부를 철회할 수 있습니다.
                </li>

                <li>
                  2.개인위치정보주체는 언제든지 개인위치정보의 이용 또는 제공의
                  일시적인 중지를 요구할 수 있습니다. 이 경우 "회사"는 요구를
                  거절하지 아니하며, 이를 위한 기술적 수단을 갖추고 있습니다.
                </li>

                <li>
                  3.개인위치정보주체는 "회사"에 대하여 아래 자료의 열람 또는
                  고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그
                  정정을 요구할 수 있습니다. 이 경우 "회사"는 정당한 이유 없이
                  요구를 거절하지 아니합니다.
                  <ol>
                    <li>
                      1)개인위치정보주체에 대한 위치정보 이용, 제공사실 확인자료
                    </li>
                    <li>
                      2)개인위치정보주체의 개인위치정보가 위치정보의 보호 및
                      이용 등에 관한 법률 또는 다른 법령의 규정에 의하여
                      제3자에게 제공된 이유 및 내용
                    </li>
                  </ol>
                </li>

                <li>
                  4."회사"는 개인위치정보주체가 동의의 전부 또는 일부를 철회한
                  경우에는 지체 없이 수집된 개인위치정보 및 위치정보
                  이용제공사실 확인자료(동의의 일부를 철회하는 경우에는 철회하는
                  부분의 개인위치정보 및 위치정보 이용제공사실 확인자료에
                  한합니다.)를 파기합니다.
                </li>

                <li>
                  5.개인위치정보주체는 제1항 내지 제3항의 권리행사를 위하여 이
                  약관 제15조의 연락처를 이용하여 "회사"에 요구할 수 있습니다.
                </li>
              </ul>

              <br />

              <h2>제 10조 서비스의 변경 및 중지</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1."회사"는 위치정보사업자의 정책변경 등과 같이 "회사"의 제반
                  사정 또는 법률상의 장애 등으로 서비스를 유지할 수 없는 경우,
                  서비스의 전부 또는 일부를 제한, 변경하거나 중지할 수 있습니다.
                </li>
                <li>
                  2.제1항에 의한 서비스 중단의 경우에는 "회사"는 사전에 인터넷
                  및 서비스 화면 등에 공지하거나 개인위치정보 주체에게
                  통지합니다.
                </li>
              </ul>

              <br />

              <h2>제 11조 위치정보관리책임자의 지정</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1."회사"는 위치정보를 관리, 보호하고, 거래 시 이용자의
                  개인위치정보로 인한 불만을 원활히 처리할 수 있는
                  위치정보관리책임자를 지정해 운영합니다.
                </li>
                <li>
                  2.위치정보관리책임자는 위치기반서비스를 제공하는 부서의
                  부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.
                </li>
              </ul>

              <br />

              <h2>제 12조 손해배상 및 면책</h2>

              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.개인위치정보주체는 "회사"의 다음 각 호에 해당하는 행위로
                  손해를 입은 경우에 "회사"에 대해 손해배상을 청구할 수
                  있습니다. 이 경우 개인위치정보주체는 "회사"의 고의 또는 과실이
                  있음을 직접 입증하여야 합니다.
                  <ol>
                    <li>
                      1)법령에서 허용하는 경우를 제외하고 이용자 또는
                      개인위치정보주체의 동의 없이 위치정보를 수집, 이용하는
                      행위
                    </li>
                    <li>2)개인위치정보의 누출, 변조, 훼손 등의 행위</li>
                  </ol>
                </li>
                <li>
                  2."회사"는 천재지변 등 불가항력적인 사유나 "이용자"의 고의
                  또는 과실로 인하여 발생한 때에는 손해를 배상하지 아니합니다.
                </li>
                <li>
                  3."회사"는 "이용자"가 망사업자의 통신환경에 따라 발생할 수
                  있는 오차 있는 위치정보를 이용함으로써 "이용자" 및 제3자가
                  입은 손해에 대하여는 배상하지 아니합니다.
                </li>
              </ul>

              <br />

              <h2>제 13조 약관의 변경</h2>
              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1."회사"가 약관을 변경하고자 할 때는 사전에 공지사항을 통해
                  변경내용을 게시합니다. 이에 관해 "회원"이 이의를 제기할 경우
                  "회사"는 "이용자"에게 적절한 방법으로 약관 변경내용을
                  통지하였음을 확인해 주어야 합니다. 다만, 법령의 개정이나
                  제도의 개선 등으로 인하여 긴급히 약관을 변경할 때는 해당
                  서비스를 이용하는 통신 단말장치에 즉시 이를 게시하고 가입 시
                  등록된 "회원"의 전자우편 주소로 통지하여야 합니다.
                </li>
                <li>
                  2."회원"은 제1항의 규정에 따른 약관의 변경내용이 게시되거나
                  통지된 후부터 변경되는 약관의 시행일 전 영업 일까지 계약을
                  해지할 수 있습니다. 단 전단의 기간 안에 "회원"의 이의가
                  "회사"에 도달하지 않으면 "회원"이 이를 승인한 것으로 봅니다.
                </li>
              </ul>

              <br />

              <h2>제 14조 분쟁 해결</h2>

              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <p>
                  "회사"는 위치정보와 관련된 분쟁의 당사자간 협의가 이루어지지
                  아니하거나 협의를 할 수 없는 경우에는 전기통신기본법의 규정에
                  따라 방송통신위원회에 재정을 신청하거나 정보통신망 이용촉진 및
                  정보보호 등에 관한 법률의 규정에 의한 개인정보분쟁조정위원회에
                  조정을 신청할 수 있습니다.
                </p>
              </ul>

              <br />

              <h2>제 15조 사업자 정보 및 위치정보관리책임자 지정</h2>

              <ul
                style={{
                  listStyle: "inside",
                  color: "#A7A7A7",
                  fontSize: "12px",
                  fontWeight: "500",
                  marginTop: "8px",
                }}
              >
                <li>
                  1.회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과
                  같습니다.
                  <ol>
                    <li>상호 : (주)에프오에프</li>
                    <li>대표 : 최재영</li>
                    <li>주소 : 서울특별시 성동구 왕십리로 273-1 3층</li>
                    <li>전화번호 : 02-2292-8766</li>
                  </ol>
                </li>
                <li>
                  2.위치정보관리책임자는 다음과 같이 지정합니다.
                  <ol>
                    <li>대표 : 최재영</li>
                    <li>주소 : 서울특별시 성동구 왕십리로 273-1 3층</li>
                    <li>전화번호 : 02-2292-8766</li>
                  </ol>
                </li>
              </ul>

              <br />

              <h2>부칙</h2>

              <p>제1조 (시행일) 본 약관은 2024년 6월 7일부터 시행합니다.</p>
            </div>
          </Content>
        </div>
      </motion.div>
      <CustomFooter />
    </div>
  );
}
