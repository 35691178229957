import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import NoLockerData from "./card/NoLockerData";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import { useAxios } from "../../lib/api/useAxios";
import LockerList from "./LockerList";
import CustomFooter from "../../components/layout/footer/CustomFooter";
import { UploadTextColor } from "../../styles/color";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";

export default function LockerPage() {
  const { get } = useAxios();
  const isMobile = useMediaQuery({ maxWidth: 575 });

  const { status, data } = useQuery({
    queryKey: ["locker-get"],
    queryFn: async () => await get(`${API_DEFAULT}/user/contents-box/get`),
  });

  return (
    <>
      <div className={`flex flex-col justify-between h-full ${isMobile && ""}`}>
        <motion.div
          className={`w-full ${isMobile ? "" : "flex justify-center"} mb-10`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div
            className={`mt-[50px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
          >
            <div className="flex items-center mb-[30px] gap-3 mx-5">
              <Text className="title dark:text-darkText">보관함</Text>
              <Text
                className="dark:text-darkText"
                fontSize={14}
                fontWeight={500}
                color={UploadTextColor}
              >
                구매한 콘텐츠
              </Text>
            </div>
            <div className="h-[1px] bg-[#afafaf] mb-[30px] mt-[10px] mx-5" />

            {data?.data === undefined ? (
              <NoLockerData />
            ) : (
              <LockerList lockerItem={data?.data} />
            )}
          </div>
        </motion.div>
        {isMobile ? null : <CustomFooter />}
      </div>
    </>
  );
}
