/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  LOGIN: "STANDBUY_LOGIN",
};

export const LOCAL_STORAGE = {
  TOKEN: "STANDBUY_TOKEN",
  R_TOKEN: "STANDBUY_R_TOKEN",
  EXPIRES_AT: "STANDBUY_TIME",
  LOGIN: "STANDBUY_LOGIN",
};

/********************************************************


*********************************************************/
