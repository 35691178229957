import React from "react";
import styled from "styled-components";
import Text from "../../../components/design-system/Text";
import TextSlide from "../../../components/landing/TextSlide";

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("/img/standbuy/landing/korea-man.png");
  background-size: cover;
  background-repeat: no-repeat;
`;

export default function SectionFive() {
  return (
    <Wrap className="">
      <div className="w-full h-full flex flex-col pt-[24vh] pl-[15vw]">
        <TextSlide delay={0.3}>
          <Text
            fontSize={90}
            fontWeight={600}
            color="#fff"
            className="leading-[1]"
          >
            왜 스톡콘텐츠는
          </Text>
          <Text fontSize={90} fontWeight={600} color="#fff">
            전부 외국일까?
          </Text>

          <Text
            fontSize={22}
            fontWeight={600}
            color="#fff"
            className="leading-[1] mt-4 ml-2"
          >
            *10년차 영화감독 인터뷰 발췌
          </Text>
        </TextSlide>
      </div>
    </Wrap>
  );
}
