//@ts-nocheck
import React, { useEffect, useRef } from "react";
import Text from "../../../components/design-system/Text";
import { motion, useAnimation, useInView } from "framer-motion";
import { useWindowSize } from "@uidotdev/usehooks";

export default function SectionEight() {
  const { height } = useWindowSize();
  const ref = useRef(null);
  const isInview = useInView(ref, { once: true });
  const controls = useAnimation();

  useEffect(() => {
    if (isInview) {
      controls.start({ opacity: 1 });
    } else {
      controls.start({ opacity: 0 });
    }
  }, [controls, isInview]);

  return (
    <div className="h-screen flex flex-col items-center relative">
      <div className="mt-[200px] flex items-end gap-4">
        <img src="/img/standbuy/landing/standbuy.png" alt="" />
        <Text fontSize={50} fontWeight={600}>
          는 한국이 기준이야
        </Text>
      </div>

      <div className="mt-[70px] rounded-full bg-[#000000] py-3 px-7 cursor-pointer w-[440px] flex justify-center">
        <Text fontSize={30} fontWeight={600} color="#fff">
          한국인에게 안성맞춤
        </Text>
      </div>

      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={controls}
        transition={{ duration: 2, ease: "easeInOut" }}
      >
        <div className="absolute bottom-0 left-0 right-0 z-[1] flex justify-center">
          <img
            src="/img/standbuy/landing/korea-mark.png"
            alt=""
            width={`${height <= 1110 ? 500 : 700}`}
          />
        </div>
      </motion.div>
    </div>
  );
}
