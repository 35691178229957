import React, { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoExchangeData() {
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <div className="hidden">로딩 중...</div>;
  }
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <Text className="no-data-title dark:text-darkText mt-[90px]">
          정산 내역이 없어요.
        </Text>

        <img src={"/img/standbuy/icon/pay-coin-big.svg"} />

        <div className="flex flex-col items-center">
          <Text className="upload-text" color={UploadTextColor}>
            수익금으로
          </Text>
          <Text className="upload-text" color={UploadTextColor}>
            정산을 할 수 있습니다.
          </Text>
        </div>
      </div>
    </div>
  );
}
