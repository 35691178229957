import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  data: "",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialToastState,
  reducers: {
    themeFetched: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { themeFetched } = themeSlice.actions;

export default themeSlice.reducer;
