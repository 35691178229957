import { JustifiedInfiniteGrid } from "@egjs/react-infinitegrid";
import React, { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import { setStudioList } from "../../../store/reducer/mainStock";
import Text from "../../../components/design-system/Text";
import StockListCard from "../../feed/stock-list/StockListCard";
import { getCookie } from "../../../util/cookies";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function StudioFeedCard({ data, otherData }: any) {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const parms = useParams();
  const { post } = useAxios();
  const isInfo = getCookie("me");
  const [contentId, setContentId] = useState<number | null>(null);
  const [groupKey, setGroupKey] = useState<number | null>(null);
  const themeData = useAppSelector((state) => state.theme.data);
  const dispatch = useAppDispatch();
  const { studioList } = useAppSelector(
    (state) => state.mainStock,
    shallowEqual,
  );
  const [itmes, setItems] = useState<any>([]);

  async function getItems(nextGroupKey: number, count: number) {
    let nextItems = [];
    const nextKey = nextGroupKey * count;
    const res = await post(
      `${API_DEFAULT}/contents/search/studio/my?page=${nextGroupKey}&size=${count}`,
    );
    if (res?.success) {
      nextItems = res?.data;
      if (nextItems.length === 0) return [];
      nextItems.map((item: any, index: number) => {
        item.groupKey = nextGroupKey;
        item.key = nextKey + index;
      });
    }

    return nextItems;
  }

  return (
    <div className={`mt-10 ${isMobile && "mx-4"}`}>
      <JustifiedInfiniteGrid
        loading={
          <FadeLoader
            color={
              themeData === "light" || themeData === "" ? "#121212" : "#ffffff"
            }
          />
        }
        placeholder={
          <div className="placeholder">추가 데이터를 불러오는 중...</div>
        }
        columnRange={isMobile ? [0, 1] : [0, 4]}
        threshold={3000}
        gap={20}
        onRequestAppend={(e) => {
          const nextGroupKey = (+e.groupKey! || 0) + 1;
          // 같은 그룹키는 무시
          if (nextGroupKey === groupKey) {
            return;
          }
          setGroupKey(nextGroupKey);
          e.wait();

          setTimeout(async () => {
            const add = await getItems(nextGroupKey, 15);

            if (add?.length === 0) {
              e.ready();
              return true;
            }

            dispatch(setStudioList({ data: add, reset: false }));
            setItems([...itmes, ...add]);
            e.ready();
          }, 1);
        }}
      >
        {itmes?.length < 1 ? (
          <div className="flex flex-col items-center justify-center">
            <Text className="subtitle-2 dark:text-darkText">
              조회된 피드가 없습니다.
            </Text>
          </div>
        ) : (
          itmes?.map((item: any, index: any) => (
            <StockListCard
              data-grid-groupkey={item.groupKey}
              key={index}
              index={index}
              item={item}
              onClick={undefined}
            />
          ))
        )}
      </JustifiedInfiniteGrid>
    </div>
  );
}
