import { useEffect, useState } from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";

export default function NoAlarmList({ value }: any) {
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <div className="hidden">로딩 중...</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col gap-10 justify-center items-center">
        <img
          src={"/img/standbuy/icon/alarm-bell.svg"}
          className={"mt-[90px]"}
        />

        <Text className="no-data-title dark:text-darkText ">
          알림이 없어요.
        </Text>
      </div>
    </div>
  );
}
