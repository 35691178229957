import { Input } from "@material-tailwind/react";
import { useAppSelector } from "../../../store/store";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import { useRef } from "react";

const Wrap = styled.div<any>`
  width: ${(props) => props.width};
  position: relative;
`;

export function LabelInputLength({
  type = "text",
  value,
  setValue,
  width,
  placeholder,
  label,
  size = "md",
  error = false,
  success = false,
  disable = false,
  minLength,
  maxLength,
  onKeyUp,
  readOnly = false,
  onKeyDown,
}: Props) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const focusRef = useRef<any>();

  const handleInput = (e: any) => {
    setValue(e);
    return focusRef.current.focus();
  };

  return (
    <Wrap width={width}>
      <Input
        readOnly={readOnly}
        className="dark:text-darkText"
        type={type}
        variant="static"
        label={label}
        placeholder={placeholder}
        crossOrigin={undefined}
        color={themeData === "dark" ? "white" : undefined}
        size={size}
        error={error}
        success={success}
        disabled={disable}
        ref={focusRef}
        value={value}
        onChange={(e: any) => handleInput(e.target.value)}
        onKeyUp={onKeyUp}
        minLength={minLength}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
      />

      <div className="absolute right-0 top-[15px] dark:text-darkText">
        {value?.length || 0}/15
      </div>
    </Wrap>
  );
}

interface Props {
  value: any;
  setValue: any;
  type?: string;
  width?: string;
  placeholder: string;
  size?: "md" | "lg";
  error?: boolean;
  success?: boolean;
  disable?: boolean;
  label?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  onKeyUp?: any;
  readOnly?: boolean;
  onKeyDown?: any;
}
