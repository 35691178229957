import React, { useEffect, useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import CustomCheckBox from "../../../components/design-system/CustomCheckBox";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import JoinTermsModal from "../../join/modal/JoinTermsModal";
import JoinPolicyModal from "../../join/modal/JoinPolicyModal";
import { useMediaQuery } from "react-responsive";
import { useContext } from "../../../util/useContext";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { signOut } from "../../../lib/modules/auth";
import { LOCAL_STORAGE } from "../../../lib/const";
import { shallowEqual } from "react-redux";

export default function SocialCheckForm() {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );
  const { post, del } = useAxios();
  const { isLogin, me } = useContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [infoModal, setInfoModal] = useState<any>({ show: false, data: "" });
  const [policyModal, setPolicyModal] = useState<any>({
    show: false,
    data: "",
  });
  const [allAgree, setAllAgree] = useState(false); // 전체 동의

  const [agreements, setAgreements] = useState({
    mustOne: false,
    mustTwo: false,
    mustThree: false,
    choiceAd: false,
    choiceInfo: false,
  });

  const handleAgreementChange = (event: any) => {
    const { name, checked } = event.target;

    setAgreements((prev: any) => ({ ...prev, [name]: checked }));
    const allChecked = Object.values({ ...agreements, [name]: checked }).every(
      (value: any) => value === true,
    );
    setAllAgree(allChecked);
  };

  const handleAllAgreementChange = (event: any) => {
    const { checked } = event.target;

    setAgreements((prev: any) =>
      Object.keys(prev).reduce((newAgreements: any, agreementKey: any) => {
        return {
          ...newAgreements,
          [agreementKey]: checked,
        };
      }, {}),
    );

    setAllAgree(checked);
  };

  const _token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

  async function handleTermsEnd() {
    const res = await post(
      `${API_DEFAULT}/user/info/selective-agreement?advertisingInfo=${agreements.choiceAd}`,
    );
    setLoading(true);

    if (res?.success) {
      window.location.reload();
      navigate("/feed", { replace: true });
    } else {
      if (isLogin) {
        await del(`${API_DEFAULT}/user/auth/sign-out`, {
          accessToken: _token,
          refreshToken: r_token,
          browser: browserData,
        });
        signOut({ dispatch: dispatch });
      }

      // dispatch(
      //   toastFetched({
      //     show: true,
      //     text: res?.error?.message,
      //     type: "error",
      //   }),
      // );
    }
  }

  return (
    <>
      {isMobile ? (
        <div>
          <div className="flex flex-col gap-12">
            <Text className="subtitle-2 dark:text-darkText">
              귀한 곳에 귀한 분이!{""}
              <Text className="subtitle-2 dark:text-darkText">
                아티스트가 되려면 약관에 동의해주세요.
              </Text>
            </Text>

            <div className="flex flex-col justify-center gap-6">
              <CustomCheckBox
                label="약관에 모두 동의"
                labelStyle="subtitle-3 dark:text-darkText"
                checked={allAgree}
                onChange={handleAllAgreementChange}
                ripple={false}
              />
              <div className="h-[1px] bg-gray-200"></div>
              <CustomCheckBox
                label="만 14세 이상 (필수)"
                checked={agreements.mustOne}
                onChange={handleAgreementChange}
                name="mustOne"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              <div className="flex  items-center justify-between">
                <CustomCheckBox
                  label="스탠바이 이용 약관 (필수)"
                  checked={agreements.mustTwo}
                  onChange={handleAgreementChange}
                  name="mustTwo"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setInfoModal({ show: true })}
                >
                  이용약관
                </Text>
              </div>

              <div
                className={`flex ${isMobile ? "flex-col" : "items-center justify-between"} `}
              >
                <CustomCheckBox
                  label="개인정보 수집 및 이용 동의 (필수)"
                  checked={agreements.mustThree}
                  onChange={handleAgreementChange}
                  name="mustThree"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setPolicyModal({ show: true })}
                >
                  (보기)
                </Text>
              </div>

              <CustomCheckBox
                label="광고성 정보 수신 동의 (선택)"
                checked={agreements.choiceAd}
                onChange={handleAgreementChange}
                name="choiceAd"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              {/* <CustomCheckBox
              label="개인정보 수집 및 이용 동의 (선택)"
              checked={agreements.choiceInfo}
              onChange={handleAgreementChange}
              name="choiceInfo"
              labelStyle="dark:text-darkText"
              ripple={false}
            /> */}
            </div>
          </div>

          <div className="mt-12">
            <FillButton
              text={"동의하고 시작하기"}
              disabled={
                allAgree ||
                (agreements.mustOne &&
                  agreements.mustTwo &&
                  agreements.mustThree)
                  ? false
                  : true
              }
              onClick={handleTermsEnd}
              className="w-full"
              size="lg"
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <Card>
          <div className="flex flex-col gap-12">
            <Text className="subtitle-2 dark:text-darkText">
              귀한 곳에 귀한 분이!{""}
              <Text className="subtitle-2 dark:text-darkText">
                아티스트가 되려면 약관에 동의해주세요.
              </Text>
            </Text>

            <div className="flex flex-col justify-center gap-6">
              <CustomCheckBox
                label="약관에 모두 동의"
                labelStyle="subtitle-3 dark:text-darkText"
                checked={allAgree}
                onChange={handleAllAgreementChange}
                ripple={false}
              />
              <div className="h-[1px] bg-gray-200"></div>
              <CustomCheckBox
                label="만 14세 이상 (필수)"
                checked={agreements.mustOne}
                onChange={handleAgreementChange}
                name="mustOne"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              <div className="flex  items-center justify-between">
                <CustomCheckBox
                  label="스탠바이 이용 약관 (필수)"
                  checked={agreements.mustTwo}
                  onChange={handleAgreementChange}
                  name="mustTwo"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />
                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setInfoModal({ show: true })}
                >
                  이용약관
                </Text>
              </div>
              <div className="flex  items-center justify-between">
                <CustomCheckBox
                  label="개인정보 수집 및 이용 동의 (필수)"
                  checked={agreements.mustThree}
                  onChange={handleAgreementChange}
                  name="mustThree"
                  labelStyle="dark:text-darkText"
                  ripple={false}
                />

                <Text
                  className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                  onClick={() => setPolicyModal({ show: true })}
                >
                  (보기)
                </Text>
              </div>

              <CustomCheckBox
                label="광고성 정보 수신 동의 (선택)"
                checked={agreements.choiceAd}
                onChange={handleAgreementChange}
                name="choiceAd"
                labelStyle="dark:text-darkText"
                ripple={false}
              />
              {/* <CustomCheckBox
              label="개인정보 수집 및 이용 동의 (선택)"
              checked={agreements.choiceInfo}
              onChange={handleAgreementChange}
              name="choiceInfo"
              labelStyle="dark:text-darkText"
              ripple={false}
            /> */}
            </div>
          </div>
          <div className="mt-12">
            <FillButton
              text={"동의하고 시작하기"}
              disabled={
                allAgree ||
                (agreements.mustOne &&
                  agreements.mustTwo &&
                  agreements.mustThree)
                  ? false
                  : true
              }
              onClick={handleTermsEnd}
              className="w-full"
              size="lg"
              loading={loading}
            />
          </div>
        </Card>
      )}
      {infoModal.show && (
        <JoinTermsModal
          open={infoModal.show}
          onClose={() => setInfoModal({ show: false })}
          data={infoModal.data}
        />
      )}

      {policyModal.show && (
        <JoinPolicyModal
          open={policyModal.show}
          onClose={() => setPolicyModal({ show: false })}
          data={policyModal.data}
        />
      )}
    </>
  );
}
