import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  mode: "thumbnail" | "form";
  thumbnail: string[];
  thumbnail_select: number | null;
  upload_payload: {};
  [key: string]: any;
};

const initialState: StateType = {
  mode: "thumbnail", // 'thumbnail' | 'form
  thumbnail: [],
  thumbnail_select: 0,
  upload_payload: {
    content_latitude: 37.5280674292228,
    content_longitude: 126.917885535023,
    ownership_price: 0,
    ticket_price: 0,
    content_name: "",
    // ownership_price: 3000,
    // ticket_price: 30,
    camera_info: "cannon",
    keyword: [],
    description: "설명입니다.",
  },
  status: "",
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    onInit(state: StateType) {
      state.mode = "thumbnail";
      state.thumbnail = [];
      state.thumbnail_select = 0;
    },

    setThumbnail(state: StateType, action) {
      state.thumbnail = action.payload;
      state.mode = "form";
    },

    setThumbnailSelect(state: StateType, action) {
      state.thumbnail_select = action.payload;
    },

    setUploadPayload(state: StateType, action) {
      state.upload_payload = Object.assign(
        state.upload_payload,
        action.payload
      );
    },

    setStatus(state: StateType, action) {
      state.status = action.payload;
    },
  },
});

export const {
  onInit,
  setThumbnail,
  setThumbnailSelect,
  setUploadPayload,
  setStatus,
} = uploadSlice.actions;

export default uploadSlice.reducer;
