import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { addComma } from "../../../util/number";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useQuery } from "@tanstack/react-query";
import Dayjs from "dayjs";
import getBankName from "../../../util/bankCode";

export default function PayListInfoModal({ open, onClose, data }: any) {
  const { get } = useAxios();

  const { status, data: pay } = useQuery({
    queryKey: ["payInfo"],
    queryFn: async () =>
      await get(`${API_DEFAULT}/user/payment/detail`, {
        orderId: data?.orderId,
      }),
  });

  const updatedOrders = (order: string) => {
    const parts = order?.split("_");
    const numberPart = parts && parts[1];
    const newOrderName = `${numberPart} FOF`;
    return newOrderName;
  };

  function removeAfterPlus(str: string) {
    const plusIndex = str.indexOf("+");
    if (plusIndex !== -1) {
      // '+'가 발견된 경우
      return str.substring(0, plusIndex);
    }
    return str;
  }

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <Text
            fontSize={15}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            주문 번호
          </Text>
          <Text
            fontSize={22}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            {pay?.data?.orderId}
          </Text>
        </div>

        <div className="flex flex-col gap-1">
          <Text
            fontSize={15}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            결제 방법
          </Text>
          <Text
            fontSize={22}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            {pay?.data?.payMethod}
          </Text>
        </div>

        {pay?.data.virtualAccountNumber && (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              가상 계좌
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {pay?.data?.virtualAccountNumber}
            </Text>
          </div>
        )}

        {pay?.data?.virtualBank && (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              은행 정보
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {getBankName(pay?.data?.virtualBank)}
            </Text>
          </div>
        )}

        {pay?.data.virtualBank || pay?.data.cardNumber === null ? null : (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              결제 수단
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {pay?.data?.cardName}
            </Text>
          </div>
        )}

        {pay?.data?.virtualDueDate && (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              입금 기한
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {removeAfterPlus(pay?.data?.virtualDueDate)}
            </Text>
          </div>
        )}

        {pay?.data.cardNumber ? (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              카드 번호
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {pay?.data.cardNumber || "-"}
            </Text>
          </div>
        ) : null}

        <div className="flex flex-col gap-1">
          <Text
            fontSize={15}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            결제 상품
          </Text>
          <Text
            fontSize={22}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            {pay?.data?.orderName}
          </Text>
        </div>

        <div className="flex flex-col gap-1">
          <Text
            fontSize={15}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            결제 금액
          </Text>
          <Text
            fontSize={22}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            {addComma(pay?.data?.amount)} 원
          </Text>
        </div>

        <div className="flex flex-col gap-1">
          <Text
            fontSize={15}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            결제 일시
          </Text>
          <Text
            fontSize={22}
            fontWeight={600}
            color="#4c4c4c"
            className={"dark:text-darkText"}
          >
            {Dayjs(pay?.data.createTime).format("YYYY.MM.DD")}
          </Text>
        </div>

        {pay?.data?.cancelReason && (
          <div className="flex flex-col gap-1">
            <Text
              fontSize={15}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              취소 사유
            </Text>
            <Text
              fontSize={22}
              fontWeight={600}
              color="#4c4c4c"
              className={"dark:text-darkText"}
            >
              {pay?.data?.cancelReason}
            </Text>
          </div>
        )}

        <div className="flex justify-center">
          <Text
            fontSize={16}
            fontWeight={600}
            color="#5C5C5C"
            className="underline underline-offset-4 cursor-pointer mt-1 dark:text-darkText"
            onClick={onClose}
          >
            닫기
          </Text>
        </div>
      </div>
    </Modal>
  );
}
