import React, { useEffect, useState } from "react";
import Modal from "../design-system/modal/Modal";
import Text from "../design-system/Text";
import FillButton from "../design-system/button/FillButton";
import { useAppDispatch } from "../../store/store";
import {
  onwerFetched,
  ticketFetched,
} from "../../store/reducer/priceInfoSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PreloadedImage from "../design-system/PreloadedImage";

export default function PriceOwnerInfoModal({
  open,
  onClose,
  noRemoveBtn,
}: any) {
  const dispatch = useAppDispatch();
  const { owner } = useSelector((state: any) => state.priceInfo);
  const location = useLocation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerNone
      width={"1100px"}
      noOutSideClosed={location.pathname === "/cart"}
    >
      <Text fontSize={28} fontWeight={800} className={"mb-8"}>
        소유권 구매 주의사항
      </Text>

      <div className={"flex gap-4"}>
        <div className={"relative"}>
          <PreloadedImage
            src={"/img/standbuy/background/owner-info-bg-1.svg"}
            alt="owner-info"
            content={
              <div className={"absolute top-12 left-8"}>
                <Text color={"white"} fontSize={24} fontWeight={700}>
                  해당 콘텐츠의 저작권을
                </Text>

                <Text
                  color={"white"}
                  fontSize={24}
                  fontWeight={700}
                  className={"leading-11"}
                >
                  양도 받아요.
                </Text>

                <div className={"mt-4"}>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    콘텐츠를 구매한 뒤 재판매가 가능해요
                  </Text>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    재판매를 하고 허락없이 사용한다면
                  </Text>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    저작권법 위반 이예요.
                  </Text>
                </div>
              </div>
            }
          />
        </div>

        <div className={"relative"}>
          <PreloadedImage
            src={"/img/standbuy/background/owner-info-bg-2.svg"}
            alt="owner-info"
            content={
              <div className={"absolute top-12 left-8"}>
                <Text color={"white"} fontSize={24} fontWeight={700}>
                  인물 콘텐츠는
                </Text>

                <Text
                  color={"white"}
                  fontSize={24}
                  fontWeight={700}
                  className={"leading-11"}
                >
                  재편집이 불가능해요.
                </Text>

                <div className={"mt-4"}>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    인물 콘텐츠는 인물의 초상권 구매가 아닌
                  </Text>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    해당 콘텐츠의 저작권을 구매하는 것 입니다.
                  </Text>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    따라서, 해당 콘텐츠를 재편집할 수 없어요.
                  </Text>

                  <Text
                    color={"white"}
                    fontSize={9}
                    fontWeight={400}
                    className={"mt-2"}
                  >
                    *AI편집,누끼 활용,병원광고,인물의 인권을 해치는 콘텐츠 등
                  </Text>
                </div>
              </div>
            }
          />
        </div>
        <div className={"relative"}>
          <PreloadedImage
            src={"/img/standbuy/background/owner-info-bg-3.svg"}
            alt="owner-info"
            content={
              <div className={"absolute top-12 left-8"}>
                <Text color={"white"} fontSize={24} fontWeight={700}>
                  원작자도 해당 콘텐츠의
                </Text>

                <Text
                  color={"white"}
                  fontSize={24}
                  fontWeight={700}
                  className={"leading-11"}
                >
                  권리를 행사할 수 없어요.
                </Text>

                <div className={"mt-4"}>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    원작자도 마음대로 해당 콘텐츠를
                  </Text>
                  <Text color={"white"} fontSize={14} fontWeight={400}>
                    사용할 수 없어요.
                  </Text>
                </div>
              </div>
            }
          />
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 justify-center mt-8">
        <FillButton
          onClick={onClose}
          text={"확인 했어요"}
          className="w-[350px]"
          size="lg"
          color={"black"}
        />
        {location.pathname === "/cart"
          ? !owner && (
              <Text
                className="cursor-pointer underline underline-offset-4 cart-nickname dark:text-darkText"
                onClick={() => {
                  dispatch(onwerFetched(true));
                  onClose();
                }}
              >
                다시 열지 않기
              </Text>
            )
          : null}
      </div>
    </Modal>
  );
}
