import React, { useState } from "react";
import Text from "../../../components/design-system/Text";
import ProfileStudioModal from "../modal/ProfileSetModal";
import ProfileCareerModal from "../modal/ProfileCareerModal";
import StudioReportBtn from "../../../components/studio/StudioReportBtn";

export default function OthersProfileCard({ data }: any) {
  const [profileModal, setProfileModal] = useState<any>({
    show: false,
    data: "",
  });
  const [careerModal, setCareerModal] = useState<any>({
    show: false,
    data: "",
  });

  function formatDateReset(dateStr: any) {
    return dateStr.replace(/-/g, ".");
  }

  return (
    <>
      <div className="bg-[#F9F9F9] dark:bg-darkBanner px-10 py-[22px] border border-[#FFFFFF] dark:!border-[#1a1a1a]  rounded-lg w-full">
        <div className="">
          <div className="flex items-center justify-between gap-2">
            <Text
              fontSize={20}
              fontWeight={600}
              color="#303030"
              className="dark:text-darkText"
            >
              소개
            </Text>

            <StudioReportBtn detail={data} />
          </div>

          <div className="min-h-[100px] mb-5">
            <Text
              className="mt-[14px] dark:text-darkText"
              fontSize={16}
              fontWeight={500}
              color="#535353"
            >
              {data?.description || "프로필 메시지를 작성해주세요."}
            </Text>
          </div>

          <div className="w-full h-[1px] bg-[#E6E6E6] my-5"></div>
        </div>

        <div>
          <div className="flex items-center gap-2">
            <Text
              fontSize={20}
              fontWeight={600}
              color="#303030"
              className="dark:text-darkText"
            >
              아티스트 정보
            </Text>
          </div>

          <div className="min-h-[60px]">
            {/* <Text
              className="mt-[14px]"
              fontSize={16}
              fontWeight={500}
              color="#535353"
            >
              {data?.affiliation || "아티스트 정보를 작성해주세요."}
            </Text> */}

            <ul
              style={{
                listStyle: "inside",
                color: "#535353",
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "14px",
              }}
            >
              <li className="dark:text-darkText">
                {data?.affiliation || "작성된 아티스트 정보가 없습니다."}
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col gap-[10px] max-h-[150px] overflow-auto mb-1">
          {data?.careerList?.map((item: any, index: any) => {
            return (
              <div className="py-[10px] px-4 bg-[#FFFFFF] rounded-md flex">
                <div className="flex">
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#838383"
                    className="w-[80px]"
                  >
                    {formatDateReset(item?.startDate)}
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#838383"
                    className="mx-2"
                  >
                    -
                  </Text>
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#838383"
                    className="w-[100px]"
                  >
                    {formatDateReset(item?.endDate)}
                  </Text>

                  <div className="min-w-[400px] ml-[40px]">
                    <Text
                      fontSize={14}
                      fontWeight={700}
                      color="#404040"
                      className=""
                    >
                      {item?.description}
                    </Text>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {data?.careerList?.length > 3 && (
          <ul
            style={{
              listStyle: "inside",
              color: "#A7A7A7",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            <li>경력이 3개 이상이면 아래로 스크롤 해보세요!</li>
          </ul>
        )}
      </div>

      {profileModal.show && (
        <ProfileStudioModal
          open={profileModal.show}
          onClose={() => setProfileModal({ show: false })}
          data={data}
        />
      )}

      {careerModal.show && (
        <ProfileCareerModal
          open={careerModal.show}
          onClose={() => setCareerModal({ show: false })}
          data={data}
        />
      )}
    </>
  );
}
