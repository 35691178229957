import { Button } from "@material-tailwind/react";
import Text from "../Text";

export default function FillIconButton({
  size = "md",
  text,
  disabled = false,
  onClick,
  className,
  color = "green",
  loading,
  icon,
  fontWeight = 700,
  fontSize = 14,
}: Props) {
  return (
    <Button
      loading={loading}
      className={className}
      size={size}
      variant="outlined"
      color={color}
      disabled={disabled}
      onClick={onClick}
      placeholder={undefined}
    >
      <div className="flex items-center gap-2 justify-center">
        <Text
          className="w-full"
          color="rgb(76, 175, 80)"
          fontWeight={fontWeight}
          fontSize={fontSize}
        >
          {text}
        </Text>
        {icon}
      </div>
    </Button>
  );
}

interface Props {
  icon?: any;
  loading?: boolean;
  width?: string;
  size?: "sm" | "md" | "lg";
  text?: any;
  disabled?: boolean;
  onClick: any;
  className?: string;
  fontWeight?: number;
  fontSize?: number;
  color?:
    | "white"
    | "black"
    | "blue-gray"
    | "gray"
    | "brown"
    | "deep-orange"
    | "orange"
    | "amber"
    | "yellow"
    | "lime"
    | "light-green"
    | "green"
    | "teal"
    | "cyan"
    | "light-blue"
    | "blue"
    | "indigo"
    | "deep-purple"
    | "purple"
    | "pink"
    | "red";
}
