import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lat: "",
  lng: "",
  clickLat: "",
  clickLng: "",
};

export const geoLocationSlice = createSlice({
  name: "geoLocation",
  initialState: initialState,
  reducers: {
    // setData
    setGeoPosition(state, action) {
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
    setClickGeoPosition(state, action) {
      state.clickLat = action.payload.clickLat;
      state.clickLng = action.payload.clickLng;
    },
  },
});

export const { setGeoPosition, setClickGeoPosition } = geoLocationSlice.actions;

export default geoLocationSlice.reducer;
