import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";

export default function CartDeleteModal({
  open,
  onClose,
  data,
  flag,
  setCheckItems,
}: any) {
  const { del } = useAxios();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  async function onCartItemRemove(payload: any) {
    const res = await del(`${API_DEFAULT}/user/cart/remove`, payload);

    if (res?.success) {
      onClose();
      setCheckItems([]);
      dispatch(
        toastFetched({
          show: true,
          text: "항목을 삭제하였습니다.",
          type: "success",
        }),
      );
      window.location.reload();
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: onCartItemRemove,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cart"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          정말 상품을 삭제하시겠어요?
        </Text>
        <Text
          className="upload-text dark:text-darkText mt-4"
          color={UploadTextColor}
        >
          상품을 삭제하면 장바구니에서 사라져요.
        </Text>

        <FillButton
          text="삭제하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={() => {
            mutate({
              cartIds: flag === "all" ? data : [data],
            });
          }}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
