/**
 * @name 콤마찍기
 * @returns boolean
 */
export const addComma = (num: any): string => {
  if (!num) return '0'
  try {
    return Intl.NumberFormat('en-US').format(num)
  } catch (e) {
    return String(num)
  }
}
