import React, { useEffect, useState } from "react";
import { useContext } from "../../../util/useContext";
import Text from "../../../components/design-system/Text";
import { motion } from "framer-motion";
import FillButton from "../../../components/design-system/button/FillButton";
import getBankName from "../../../util/bankCode";
import CustomFooter from "../../../components/layout/footer/CustomFooter";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";
import { useParams } from "react-router";
import { addComma } from "../../../util/number";

export default function BillSuccess() {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useContext();
  const { get } = useAxios();
  const [orderName, setOrderName] = useState<any>();

  const { status, data } = useQuery({
    queryKey: ["pay-result-get"],
    queryFn: async () =>
      await get(
        `${API_DEFAULT}/user/payment/success/result?orderId=${params?.orderId}`,
      ),
  });

  function removeAfterPlus(str: string) {
    const plusIndex = str.indexOf("+");
    if (plusIndex !== -1) {
      // '+'가 발견된 경우
      return str.substring(0, plusIndex);
    }
    return str;
  }

  useEffect(() => {
    const numberPart = parseInt(state?.orderName.split("_")[1], 10);
    // "FOF"와 숫자 부분을 분리하고, 숫자를 10으로 나눈 값을 계산
    const value = numberPart;
    // 숫자와 "FOF"를 합쳐 새로운 orderName 생성 (숫자를 앞에 배치)
    const newOrderName = `${value} FOF`;
    // 새로운 객체 반환
    setOrderName(newOrderName);
  }, [state]);

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">결제 완료</Text>
          </div>

          <div className="w-full flex flex-col">
            <div className="flex flex-col  mt-[40px]">
              <div className="flex justify-center">
                <img src="/img/standbuy/icon/bill-success.svg" alt="" />
              </div>

              <div className="flex justify-center">
                <Text
                  fontSize={30}
                  fontWeight={700}
                  color="#1a1a1a"
                  className="mt-8 mb-[50px]"
                >
                  결제에 성공했습니다!
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center gap-5">
            <div className="flex flex-col justify-center gap-5">
              <div className="ml-[1px]">
                <Text
                  fontSize={15}
                  fontWeight={600}
                  color="#4c4c4c"
                  className="dark:text-darkText"
                >
                  결제 방법
                </Text>
                <Text fontSize={22} fontWeight={600} color="#424242">
                  {data?.data.payMethod || "-"}
                </Text>
              </div>

              {data?.data?.virtualBank === null ? null : (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    결제 수단
                  </Text>
                  {data?.data.bankName && (
                    <Text fontSize={22} fontWeight={600} color="#424242">
                      {data?.data.bankName || "-"}
                    </Text>
                  )}
                  {data?.data.cardName && (
                    <Text fontSize={22} fontWeight={600} color="#424242">
                      {data?.data.cardName || "-"}
                    </Text>
                  )}
                </div>
              )}

              {data?.data.cardNumber ? (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    카드 번호
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {data?.data.cardNumber || "-"}
                  </Text>
                </div>
              ) : null}

              {data?.data.amount ? null : (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    결제 금액
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {addComma(data?.data?.amount) || "-"}
                  </Text>
                </div>
              )}

              {data?.data?.virtualBank ? null : (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    주문 번호
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {data?.data.orderId || "-"}
                  </Text>
                </div>
              )}

              {data?.data.virtualBank ? null : (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    상품 정보
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {data?.data.orderName || "-"}
                  </Text>
                </div>
              )}

              {data?.data?.virtualAccountNumber && (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    가상 계좌
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {data?.data?.virtualAccountNumber || "-"}
                  </Text>
                </div>
              )}

              {data?.data?.virtualBank && (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    은행 정보
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {getBankName(data?.data?.virtualBank) || "-"}
                  </Text>
                </div>
              )}

              {data?.data?.virtualDueDate && (
                <div className="ml-[1px]">
                  <Text
                    fontSize={15}
                    fontWeight={600}
                    color="#4c4c4c"
                    className="dark:text-darkText"
                  >
                    입금 기한
                  </Text>
                  <Text fontSize={22} fontWeight={600} color="#424242">
                    {removeAfterPlus(data?.data?.virtualDueDate) || "-"}
                  </Text>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[60px] flex flex-col justify-center items-center gap-4">
            <FillButton
              text="홈으로 이동"
              onClick={() => navigate("/feed")}
              size="lg"
              className="w-[360px] border border-[#E7E7E7]"
              color="white"
            />
          </div>
        </div>
      </motion.div>

      <CustomFooter />
    </div>
  );
}
