import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { ErrorText, UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

export default function FeedDeleteModal({ open, onClose, data }: any) {
  const queryClient = useQueryClient();
  const { del } = useAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { reportText } = useAppSelector((state) => state.feed);

  async function removeContent() {
    const res = await del(
      `${API_DEFAULT}/contents/delete?id=${data?.contentId}`,
    );

    if (res?.success) {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "삭제 완료",
          type: "success",
        }),
      );
      window.location.reload();
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: removeContent,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ["me-data"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="dark:text-darkText" fontSize={20} fontWeight={600}>
          아래 사유로 콘텐츠를 제재했어요
        </Text>

        <Text
          className="dark:text-darkText mt-4"
          color={ErrorText}
          fontSize={14}
          fontWeight={500}
        >
          제재사유 : {reportText}
        </Text>

        <Text
          className="dark:text-darkText mt-4"
          fontSize={14}
          fontWeight={500}
        >
          이의제기는 채널톡으로 문의해주세요.
        </Text>

        <FillButton
          text="삭제하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
