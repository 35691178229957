import React from "react";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";

export default function NoExchangeData() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-start gap-4">
      <Text
        fontSize={23}
        color="#303030"
        fontWeight={600}
        className="mt-3 dark:text-darkText"
      >
        정산 하기
      </Text>

      <div className="w-full flex justify-center">
        <div className="w-full flex flex-col justify-center items-center">
          <Text className="no-data-title dark:text-darkText">
            등록된 정산 정보가 없습니다.
          </Text>

          <div className="flex flex-col items-center mb-10 mt-3">
            <Text className="upload-text" color={UploadTextColor}>
              우측의 '정산 계좌 등록하기' 를 해주세요!
            </Text>
          </div>

          {/*<FillButton*/}
          {/*  onClick={() => navigate("/my-page")}*/}
          {/*  text={"등록하러 가기"}*/}
          {/*  size="lg"*/}
          {/*/>*/}
        </div>
      </div>

      <ul
        style={{
          listStyle: "inside",
          color: "#A7A7A7",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        <li>수익금에 대하여 현금으로 정산받을 수 있어요.</li>
        <li>
          정산 수수료(아티스트 : 33%, 디렉터 22%)를 제외하고 등록하신 계좌로
          정산해요.
        </li>
        <li>전체 수익 중, 구매가 확정된 금액까지 정산 받을 수 있어요.</li>
      </ul>
    </div>
  );
}
