//@ts-nocheck
import React from "react";
import { Stepper, Step, Button } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { HomeIcon, CogIcon, MapIcon } from "@heroicons/react/24/outline";

export default function UploadStepper() {
  const location = useLocation();

  return (
    <div className="z-0 w-[200px] pl-12 mb-5">
      <Stepper activeStep={location.pathname === "/upload" ? 0 : 1}>
        <Step>
          <CogIcon className="h-5 w-5" />
        </Step>
        <Step>
          <MapIcon className="h-5 w-5" />
        </Step>
      </Stepper>
    </div>
  );
}
