import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  isLogin: boolean | null;
  accessToken: string | null;
  refreshToken: string | null;
  expiresAt: any;
  joinMode: "agree" | "form";
  joinInfo: { checked: boolean; title: string }[];
  joinSubmit: {};
  me: {};
  [key: string]: any;
};

const initialState: StateType = {
  isLogin: false,
  joinMode: "agree",
  joinInfo: [
    { checked: false, title: "[필수] 만 14세 이상입니다." },
    { checked: false, title: "[필수] 스탠바이 이용 약관" },
    { checked: false, title: "[필수] 개인정보 수집 및 이용 동의" },
    { checked: false, title: "[선택] 광고성 정보 수신 동의" },
    { checked: false, title: "[선택] 개인정보 수집 및 이용 동의" },
  ],
  me: {},
  joinSubmit: {
    id: "",
    password: "",
    password_confirm: "",
    name: "",
    birth: "",
    email: "",
  },
  accessToken: null,
  refreshToken: null,
  expiresAt: null,
};
//*--------------------------------------------------*
/** @createSlice */
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 초기화
    setInit(state: StateType) {
      state.joinMode = "agree";
      state.joinSubmit = {
        id: "",
        password: "",
        password_confirm: "",
        name: "",
        birth: "",
        email: "",
      };
    },
    // 회원가입모드
    // setJoinMode(state: StateType, action: PayloadAction<any>) {
    //   state.joinMode = action.payload
    // },
    // // 회원가입정보
    // setJoinInfo(state: StateType, action: PayloadAction<any>) {
    //   state.joinInfo = action.payload
    // },
    // // 회원가입정보
    // setJoinSubmit(state: StateType, action: PayloadAction<any>) {
    //   state.joinSubmit = Object.assign(state.joinSubmit, action.payload)
    // },
    // 로그인상태
    setLogin(state: StateType, action) {
      state.isLogin = action.payload;
    },
    // 사용자본인정보
    setMe(state: StateType, action) {
      state.me = action.payload;
    },
    //accessToken 업데이트
    setAccessToken(state: StateType, action) {
      state.accessToken = action.payload;
    },
    // refreshToken 업데이트
    setRefreshToken(state: StateType, action) {
      state.refreshToken = action.payload;
    },
    setExpiresAt(state: StateType, action) {
      state.expiresAt = action.payload;
    },
  },
});

export const {
  setLogin,
  setMe,
  setAccessToken,
  setRefreshToken,
  setExpiresAt,
} = authSlice.actions;

export default authSlice.reducer;
