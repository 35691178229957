//@ts-nocheck
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setGeoPosition } from "../../../store/reducer/geoLocationSlice";
import useGeolocation from "../../../components/hooks/useGeoLocation";
import { useNavigate } from "react-router-dom";
import { setKeywordValue } from "../../../store/reducer/locationSlice";

export default function SearchByMap() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { lat, lng } = useAppSelector((state) => state.geoLocation);
  const [address, setAddress] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [latLng, setLatLng] = useState({
    latitude: 37.5642135,
    longitude: 127.0016985,
  });

  const HandleLocationChange = (location: string) => {
    setLocation(location);
  };

  const handleSelect = (location: string) => {
    setAddress(location); // 이 주소 업데이트는 즉시 반영되지 않을 수 있습니다.
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        // 좌표 설정 후 바로 페이지 이동
        setLatLng({ latitude: latLng.lat, longitude: latLng.lng });
        // 여기서 navigate를 호출합니다. 이 시점에서는 address 상태가 아직 업데이트되지 않았을 수 있으므로, location 변수를 사용합니다.
        navigate(`/location-feed/${location}`, {
          state: { data: latLng, keyword: location },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={location}
      onChange={HandleLocationChange}
      onSelect={handleSelect}
      //Debounce가 필요하다면
      // debounce={1000}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "장소, 주소를 검색해주세요.",
              className: "location-search-input dark:text-darkText",
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>주소를 불러오고 있습니다.</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId} // Key prop 추가
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
