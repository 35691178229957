// @ts-nocheck
import Modal from "../design-system/modal/Modal";
import Text from "../design-system/Text";
import { Input } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SearchModal({ open, onClose }: any) {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <Text fontSize={14} fontWeight={600} className={"mb-4"}>
        검색하기
      </Text>

      <Input
        variant="static"
        type="text"
        placeholder="아티스트명, 콘텐츠, 키워드로 검색해보세요!"
        containerProps={{
          className: "",
        }}
        className="placeholder:text-[#707070] !bg-gray-50 border-none rounded-md pb-4 px-4 dark:!bg-darkBg dark:border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
        labelProps={{
          className: "hidden",
        }}
        onChange={(e: any) => {
          setKeyword(e.target.value);
        }}
        onKeyUp={(event: any) => {
          if (event.key === "Enter") {
            navigate(`/feed/${keyword}`, {
              state: keyword,
              replace: true,
            });
            onClose();
          }
        }}
      />

      <div className={"flex justify-center"}>
        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
