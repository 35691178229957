//@ts-nocheck
import { Button, Popover, PopoverHandler } from "@material-tailwind/react";

export default function UploadStatCheck() {
  return (
    <div className="fixed bottom-10 left-10 z-[9999]">
      <Popover placement="left-start">
        <PopoverHandler>
          <Button loading={true} disabled={false}>
            업로드가 진행 중입니다.
          </Button>
        </PopoverHandler>
        {/*<PopoverContent>*/}
        {/*  This is a very beautiful popover, show some love.*/}
        {/*</PopoverContent>*/}
      </Popover>
    </div>
  );
}
