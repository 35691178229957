import { createSlice } from "@reduxjs/toolkit";

type StateType = {
  search_result: any[];
  positions: any[] | null;
  [key: string]: any;
  marker: any;
};

const initialState: StateType = {
  search_result: [],
  positions: null,
  marker: "",
  locationList: [],
  locationKeywordList: [],
  contentId: null,
  keyword: "",
  locationFlag: "",
};

export const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    // setData
    setSearchResult(state: StateType, action) {
      state.search_result = action.payload;
    },
    // setFetch
    setPositions(state: StateType, action) {
      state.positions = action.payload;
    },
    setMarker(state: StateType, action) {
      state.marker = action.payload;
    },
    setContentId(state: StateType, action) {
      state.contentId = action.payload;
    },
    setKeywordValue(state: StateType, action) {
      state.keyword = action.payload;
    },

    setLocationFlag(state: StateType, action) {
      state.locationFlag = action.payload.locationFlag;
    },
    setLocationListInit(state: StateType) {
      state.locationList = [];
    },
    setLocationKeywordListInit(state: StateType) {
      state.locationKeywordList = [];
    },
    setLocationListKeywordList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.locationKeywordList = action.payload?.data;
      } else {
        state.locationKeywordList = state.locationKeywordList.concat(
          action.payload?.data
        );
      }
      state.contentId = tempId;
    },

    setLocationList(state: StateType, action) {
      const tempId =
        action.payload?.data[action.payload?.data.length - 1]?.contentId;
      if (state.contentId === tempId) {
        state.isAppend = false;
        return;
      }
      // 리셋모드 추가 확인
      if (action.payload.reset) {
        state.locationList = action.payload?.data;
      } else {
        state.locationList = state.locationList.concat(action.payload?.data);
      }
      state.contentId = tempId;
    },
  },
});

export const {
  setSearchResult,
  setPositions,
  setMarker,
  setContentId,
  setLocationList,
  setKeywordValue,
  setLocationListKeywordList,
  setLocationFlag,
  setLocationListInit,
  setLocationKeywordListInit,
} = locationSlice.actions;

export default locationSlice.reducer;
