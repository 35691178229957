import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  filterImage: "",
  filterVideo: "",
};

export const filterSlice = createSlice({
  name: "assets",
  initialState: initialToastState,
  reducers: {
    imgFetched: (state, action) => {
      state.filterImage = action.payload;
    },
    videoFetched: (state, action) => {
      state.filterVideo = action.payload;
    },
  },
});

export const { imgFetched, videoFetched } = filterSlice.actions;

export default filterSlice.reducer;
