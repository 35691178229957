import React, { useEffect, useRef, useState } from "react";
import Text from "../../components/design-system/Text";
import { FadeLoader, SyncLoader } from "react-spinners";
import FillButton from "../../components/design-system/button/FillButton";
import { UploadTextColor } from "../../styles/color";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { Progress } from "@material-tailwind/react";
import { motion } from "framer-motion";
//@ts-ignore
import Lottie from "react-lottie";
import Standbuy from "../../assets/lottie/standbuy.json";
import CustomFooter from "../../components/layout/footer/CustomFooter";

export default function UploadLoading({
  progressData,
  end = false,
  partFlagData,
  type,
}: any) {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const [numberData, setNumberData] = useState<number>(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Standbuy,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (progressData === 100) {
      setNumberData(numberData + 1);
    }
  }, [progressData]);

  return (
    <div className="flex flex-col justify-between items-center h-full w-full">
      <div className="h-full flex flex-col gap-10 justify-center items-center">
        <div className="flex flex-col justify-cente items-center">
          <Text className="title mb-[30px] dark:text-darkText ">
            {end ? "파일 저장" : "파일 업로드"}
          </Text>
          <Lottie
            options={defaultOptions}
            height={200}
            width={200}
            isClickToPauseDisabled={true}
          />
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Text
            className="upload-text dark:text-darkText"
            color={UploadTextColor}
          >
            {end
              ? "콘텐츠가 STANDBUY에 저장 중 입니다!"
              : "콘텐츠가 STANDBUY로 이동 중 입니다!"}
          </Text>
        </motion.div>

        <Progress
          value={progressData}
          placeholder={"undefined"}
          className="h-6 rounded-lg w-[360px]"
          barProps={{
            className: "rounded-lg bg-[#42BC7F]",
          }}
        />

        {/*{type !== "image" && (*/}
        {/*  <Text>*/}
        {/*    {numberData} / {partFlagData + 1}*/}
        {/*  </Text>*/}
        {/*)}*/}
      </div>

      <CustomFooter />
    </div>
  );
}
