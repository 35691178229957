import React, { useState } from "react";
import { Textarea } from "@material-tailwind/react";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { useMutation } from "@tanstack/react-query";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { getCookie } from "../../../util/cookies";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import CustomTooltip from "../../../components/design-system/CustomTooltip";
import CustomCheckBox from "../../../components/design-system/CustomCheckBox";
import FillButton from "../../../components/design-system/button/FillButton";

const info = [
  { title: "부적절한 댓글", value: false, type: "INAPPROPRIATE_COMMENT" },
  {
    title: "부적절한 콘텐츠 게시",
    value: false,
    type: "INAPPROPRIATE_CONTENT",
  },
  {
    title: "불쾌감을 주는 닉네임",
    value: false,
    type: "UNPLEASANT_NAME",
  },
  { title: "버그 악용 사용자", value: false, type: "ABUSING_USER" },
  {
    title: "각종 위법 행위 (저작권, 초상권 등)",
    value: false,
    type: "LEGAL_ISSUE",
  },

  { title: "기타", value: false, type: "OTHER" },
];

export default function StudioReportModal({ open, onClose, detail }: any) {
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState({ step: 1 });
  const [reportKeywords, setReportKeywords] = useState<string[]>([]);
  const [desc, setDesc] = useState("");
  const isInfo = getCookie("me");

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.name;
    if (event.target.checked) {
      setReportKeywords([value]);
    } else {
      setReportKeywords([]);
    }
  };

  const onSubmit = async () => {
    const _reporterId = isInfo?.memberId;
    const _reporterdId = detail?.memberId;
    const _category = reportKeywords.toString();
    const _desc = desc;
    const res = await post(`${API_DEFAULT}/user/report/member`, {
      reporterId: _reporterId,
      reportedId: _reporterdId,
      category: _category,
      description: _desc,
    });
  };

  const { mutate, status } = useMutation({
    mutationFn: onSubmit,
    onSuccess: () => {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "유저가 신고되었습니다.",
          type: "success",
        }),
      );
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone width="440px">
      <div className="flex items-center gap-2">
        <Text
          fontSize={18}
          fontWeight={700}
          color="#242424"
          className="dark:text-darkText"
        >
          신고하기
        </Text>
        <CustomTooltip title="유저가 신고되면 담당자가 업무시간 내로 검토하여 위반 여부를 판단합니다. 허위신고의 경우 내부 정책에 의거해, 불이익이 발생할 수 있습니다." />
      </div>

      {step.step === 1 && (
        <div className="flex flex-col justify-center gap-6 mt-[32px]">
          {info?.map((item, index) => (
            <CustomCheckBox
              key={index}
              name={item?.type}
              checked={reportKeywords.includes(item?.type)}
              onChange={handleCheckboxChange}
              label={item?.title}
              labelStyle="report-checkbox"
            />
          ))}
        </div>
      )}

      {step.step === 2 && (
        <div className="flex flex-col justify-center gap-6 mt-[32px]">
          <Textarea
            value={desc}
            onChange={(e: any) => setDesc(e.target.value)}
            placeholder="상세 내용을 입력해주세요."
            labelProps={{ className: "before:content-none after:content-none" }}
            variant="outlined"
            className="min-h-[260px] !border-[#F1F1F1] focus:border-[1px]  focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10  shadow-gray-900/5 ring-4 ring-transparent dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
          />
        </div>
      )}

      <div className="flex flex-col items-center">
        {step.step === 1 ? (
          <FillButton
            text="다음"
            className="w-full mt-[30px]"
            size="lg"
            onClick={() => setStep({ step: 2 })}
            disabled={reportKeywords.length < 1 ? true : false}
          />
        ) : (
          <FillButton
            text="신고하기"
            className="w-full mt-[30px]"
            size="lg"
            onClick={mutate}
            disabled={desc.length < 1 ? true : false}
          />
        )}

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
