import React from "react";
import { motion } from "framer-motion";
import Text from "../../components/design-system/Text";
import { useContext } from "../../util/useContext";
import { useQuery } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import { useAxios } from "../../lib/api/useAxios";
import Dayjs from "dayjs";
import FillButton from "../../components/design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import CustomFooter from "../../components/layout/footer/CustomFooter";

export default function NoticeDetail() {
  const { get } = useAxios();
  const { state } = useContext();
  const navigate = useNavigate();

  const { status, data } = useQuery({
    queryKey: ["notice-detail"],
    queryFn: async () =>
      await get(`${API_DEFAULT}/user/notice/get/detail?noticeId=${state}`),
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <motion.div
        className={`w-full flex justify-center mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
          <div className="flex justify-between items-center mb-[30px]">
            <Text className="title dark:text-darkText">공지사항</Text>
          </div>

          <div className="mt-4 mb-[30px] w-full h-[1px] bg-[#ccc]"></div>

          <div>
            <Text
              fontSize={24}
              fontWeight={700}
              color="#1a1a1a"
              className="dark:text-darkText"
            >
              {data?.data.title}
            </Text>

            <Text
              fontSize={16}
              fontWeight={600}
              color="#b3b3b3"
              className="mt-[10px] dark:text-darkText"
            >
              {Dayjs(data?.data.createTime).format("YYYY.MM.DD")}
            </Text>

            <div className="min-h-[280px] overflow-auto">
              <Text
                fontSize={16}
                fontWeight={500}
                color="#4c4c4c"
                className="mt-[30px] dark:text-darkText"
              >
                {data?.data.writing}
              </Text>

              {data?.data.file && (
                <img
                  src={`data:image/jpeg;base64,${data?.data.file}`}
                  alt="이미지"
                />
              )}
            </div>
          </div>

          <div className="flex justify-center mt-10 mb-2">
            <FillButton
              text="전체목록"
              onClick={() => navigate(-1)}
              className="w-[330px]"
              size="lg"
            />
          </div>
        </div>
      </motion.div>

      <CustomFooter />
    </div>
  );
}
