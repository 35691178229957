import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import React from "react";

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props: any) {
  return <Slide {...props} direction="left" />;
}

export default function Toast({
  type,
  text,
  open,
  setOpen,
  vertical = "top",
  horizontal = "right",
}: any) {
  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {open && (
        <Snackbar
          sx={{ minWidth: "400px", height: "56px", borderRadius: "32px" }}
          open={open}
          autoHideDuration={2500}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
          TransitionComponent={TransitionLeft}
        >
          <Alert
            onClose={handleClose}
            sx={{
              borderRadius: "16px",
              marginRight: "60px",
              marginTop: "40px",
              width: "100%",
              padding: "1rem",
              background: "white",
              color: "black",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.10)",
              fontWeight: "700",
              fontSize: "14px",
            }}
            icon={
              type === "success" ? (
                <img src="/img/standbuy/icon/sucess.svg" alt="" width={20} />
              ) : (
                <img src="/img/standbuy/icon/error.svg" alt="" width={20} />
              )
            }
          >
            {text}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
