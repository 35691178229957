/**
 * @name LoadScript
 * @description
 * @example   const res = await loadScript(url)
 * @returns {Promise<boolean>}
 */

export const loadScript = (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const firstScript = window.document.getElementsByTagName('script')[0]
    const script = window.document.createElement('script')
    script.src = url
    script.async = true
    script.defer = true
    // 스크립트 로드 성공 시 resolve 호출
    script.onload = () => resolve(true)
    // 스크립트 로드 실패 시 reject 호출
    script.onerror = () => reject(false)
    firstScript?.parentNode?.insertBefore(script, firstScript)
  })
}
