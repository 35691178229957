import Modal from "../design-system/modal/Modal";
import FillButton from "../design-system/button/FillButton";
import { useNavigate } from "react-router-dom";
import Text from "../design-system/Text";

export default function EventModal({ open, onClose }: any) {
  return (
    <Modal open={open} onClose={onClose} headerNone width={"40rem"}>
      <img src={"/img/standbuy/background/event-bg.png"} alt={""} />
    </Modal>
  );
}
