export const API_DEFAULT = process.env.REACT_APP_API_DEFAULT;
export const API_SOCIAL = process.env.REACT_APP_API_SOCIAL;

export const URL_HOST = process.env.REACT_APP_HOST;

/**---------------------------------- @name API_KEY */
// 스탠바이(DEV)계정
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

/**---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = process.env.REACT_APP_KAKAO_REST_API;

export const TOSS_CLIENTKEY = process.env.REACT_APP_CLIENTKEY as any;
export const TOSS_CUSTOMERKEY = process.env.REACT_APP_CUSTOMERKEY as any;

/**---------------------------------- @name SOCIAL_LOGIN */
export const NAVER_LOGIN = process.env.REACT_APP_NAVER as any;
export const KAKAO_LOGIN = process.env.REACT_APP_KAKAO as any;
export const GOOGLE_LOGIN = process.env.REACT_APP_GOOGLE as any;
