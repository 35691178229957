//@ts-nocheck
import React, { useEffect, useState } from "react";
import FillButton from "../../../components/design-system/button/FillButton";
import { API_DEFAULT } from "../../../api/api";
import { niceAxios, useAxios } from "../../../lib/api/useAxios";
import { useContext } from "../../../util/useContext";
import { useLocation } from "react-router-dom";

function NiceForm() {
  const location = useLocation();
  const { post } = useAxios();
  const { me } = useContext();
  const [token, setToken] = useState("");
  const [infoData, setInfoData] = useState<any>({
    encData: "",
    integrityValue: "",
    tokenVersionId: "",
  });

  useEffect(() => {
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("t");
    setToken(params);
  }, []);

  async function validInfo() {
    const res = await post(`${API_DEFAULT}/user/auth/nice`, {
      memberId: me?.memberId,
    });
    const { form } = document;

    if (form && res.encData) {
      const left = window.screen.width / 2 - 500 / 2;
      const top = window.screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
      // window.open("", "nicePopup", option);

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
      form.enc_data.value = res.encData;
      form.integrity_value.value = res.integrityValue;
      form.token_version_id.value = res.tokenVersionId;
      form.target = "popupChk";
      form.submit();
    }
  }

  async function resetAuthValid() {
    const res = await post(
      `${API_DEFAULT}/user/auth/nice/reset?token=${token}`,
    );
    const { form } = document;

    if (form && res.encData) {
      const left = window.screen.width / 2 - 500 / 2;
      const top = window.screen.height / 2 - 800 / 2;
      const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
      // window.open("", "nicePopup", option);

      form.action =
        "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
      form.enc_data.value = res.encData;
      form.integrity_value.value = res.integrityValue;
      form.token_version_id.value = res.tokenVersionId;
      form.target = "popupChk";
      form.submit();
    }
  }

  return (
    <>
      <form name="form" id="form">
        <input type="hidden" id="m" name="m" value="service" />
        <input
          type="hidden"
          id="token_version_id"
          name="token_version_id"
          value=""
        />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
        <FillButton
          onClick={() => {
            if (location.pathname === "/reset-auth") {
              resetAuthValid();
            } else {
              validInfo();
            }
          }}
          text={"본인인증"}
          className="w-full"
          size="lg"
        ></FillButton>
      </form>
    </>
  );
}

export default NiceForm;
