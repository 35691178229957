import { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

export default function PreloadedImage({ src, alt, className, content }: any) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoaded(true);
    };
    img.src = src;
  }, [src]);

  return loaded ? (
    <>
      <img src={src} alt={alt} className={className} /> {content}
    </>
  ) : (
    <BeatLoader />
  );
}
