import React, { useEffect, useState } from "react";
import AssetsMode from "../../../components/feed/modal/AssetsMode";
import { API_DEFAULT } from "../../../api/api";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setDetail, setReportText } from "../../../store/reducer/feedSlice";
import FeedModal from "../../../components/design-system/modal/FeedModal";
import SellerInfo from "../../../components/feed/modal/SellerInfo";
import FeedContent from "../../../components/feed/modal/FeedContent";
import FeedComment from "../../../components/feed/modal/FeedComment";
import { toastFetched } from "../../../store/reducer/toastSlice";

export default function FeedInfoModal({ open, onClose, data }: any) {
  const { get } = useAxios();
  const dispatch = useAppDispatch();
  const { detail } = useAppSelector((state) => state.feed);
  const [loading, setLoading] = useState(true);

  async function onFetch() {
    const payload = { contentId: data?.target || data?.contentId };
    const res = await get(`${API_DEFAULT}/contents/search/detail`, payload);
    if (res?.success) {
      setLoading(false);
      dispatch(setDetail(res?.data));
      return true;
    } else {
      onClose();
      dispatch(
        toastFetched({
          show: true,
          text: "제재 사유 : " + res?.data.reason || "제재 받은 콘텐츠 입니다.",
          type: "error",
        }),
      );
      dispatch(setReportText(res?.data.reason));
    }
  }

  useEffect(() => {
    onFetch();
  }, []);

  return (
    <FeedModal
      open={open}
      onClose={onClose}
      width="1000px"
      feed={true}
      noOutSideClosed={false}
    >
      {loading ? (
        <span>loading</span>
      ) : (
        <>
          <div className="flex gap-10">
            <AssetsMode />
            <FeedContent onClose={onClose} />
          </div>
          <div>
            <SellerInfo detail={detail} />
            <FeedComment detail={detail} />
          </div>
        </>
      )}
    </FeedModal>
  );
}
