import React, { useState } from "react";
import Text from "../../design-system/Text";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { Avatar, Button, IconButton, Input } from "@material-tailwind/react";
import { useContext } from "../../../util/useContext";
import CommentBtn from "./CommentBtn";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { Pagination } from "../../design-system/Pagination";
import detailDate from "../../../util/dateHistoryTransfer";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/cookies";
import { API_SOCIAL } from "../../../api/api";
import {
  modalFetched,
  niceModalFetched,
} from "../../../store/reducer/globalModalSlice";
import { useMediaQuery } from "react-responsive";

export default function FeedComment({ detail, state }: any) {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const { get, post } = useAxios();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isInfo = getCookie("me");
  const { me, isLogin } = useContext();
  const [comment, setComment] = useState<any>();
  const [replyComment, setReplyComment] = useState<any>();
  const [replyFlag, setReplyFlag] = useState<any>({});
  const [modify, setModifyFlag] = useState<any>();
  const [editingId, setEditingId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const firstPostIndex = (currentPage - 1) * postsPerPage;
  const lastPostIndex = firstPostIndex + postsPerPage;

  const toggleReply = (index: any) => {
    setReplyFlag((prev: any) => {
      if (prev === index) {
        return null;
      } else {
        setReplyComment(null);
        return index;
      }
    });
  };

  const { status, data } = useQuery({
    queryKey: ["social-detail", detail],
    queryFn: async () =>
      await get(`${API_SOCIAL}/user/social/get/detail`, {
        contentId: state?.contentId || detail?.contentId,
        artistId: state?.memberId || detail?.memberId,
      }),
  });

  async function contentComment() {
    const payload = {
      contentId: detail?.contentId || state?.contentId,
      comment: comment,
      // memberNickname: me?.nickname,
      // memberGrade: me?.grade,
      receiverId: detail?.memberId,
      parentId: null,
    };
    const res = await post(`${API_SOCIAL}/user/social/comment`, payload);

    if (res?.success) {
      setComment("");
      dispatch(
        toastFetched({
          show: true,
          text: "댓글을 정상적으로 등록했습니다.",
          type: "success",
        }),
      );
    }
  }

  const { mutate } = useMutation({
    mutationFn: contentComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-detail"] });
    },
  });

  async function contentCommentReply({ item, index }: any) {
    const payload = {
      contentId: detail?.contentId || state?.contentId,
      comment: replyComment,
      // memberNickname: me?.nickname,
      // memberGrade: me?.grade,
      receiverId: item?.memberId,
      parentId: item?.commentId,
    };
    const res = await post(`${API_SOCIAL}/user/social/comment`, payload);

    if (res?.success) {
      setReplyComment("");
      setReplyFlag(null);
      dispatch(
        toastFetched({
          show: true,
          text: "답글을 정상적으로 등록했습니다.",
          type: "success",
        }),
      );
    }
  }

  const { mutate: reply } = useMutation({
    mutationFn: contentCommentReply,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["social-detail"] });
    },
  });

  const currentPosts = data?.data.commentList?.slice(
    firstPostIndex,
    lastPostIndex,
  );

  return (
    <div>
      <Text
        fontSize={20}
        fontWeight={700}
        color="#1a1a1a"
        className="dark:text-darkText"
      >
        댓글{" "}
        <Text span fontSize={20} fontWeight={700} color="#42BC7F">
          {data?.data.commentList?.length || "0"}
        </Text>
      </Text>

      <div className="w-full mt-5 relative flex mb-10">
        <Input
          type="text"
          variant="static"
          label="이 콘텐츠에 댓글을 입력해주세요."
          placeholder="이 콘텐츠에 댓글을 입력해주세요."
          className="!py-5 !px-5 rounded-lg !border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10 dark:!bg-darkBg dark:!border-[#1a1a1a] dark:border-[1px] dark:border-solid dark:placeholder:text-[#707070] dark:text-darkText"
          labelProps={{
            className: "hidden",
          }}
          containerProps={{ className: "min-w-[100px]" }}
          crossOrigin={undefined}
          size="lg"
          onChange={(e: any) => {
            if (isLogin && me?.mobileVerification) {
              setComment(e.target.value);
            } else if (isLogin && !me?.mobileVerification) {
              dispatch(niceModalFetched({ show: true }));
            } else if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            }
          }}
          value={comment}
          // onKeyUp={(event: any) => {
          //   if (event.key === "Enter") {
          //     event.stopPropagation();
          //     mutate();
          //   }
          // }}
        />
        <Button
          //@ts-ignore
          onClick={() => {
            if (isLogin && me?.mobileVerification) {
              mutate();
            } else if (isLogin && !me?.mobileVerification) {
              dispatch(niceModalFetched({ show: true }));
            } else if (isLogin === false) {
              dispatch(modalFetched({ show: true }));
            }
          }}
          ripple={false}
          variant="text"
          size="sm"
          className="!absolute right-1 top-2 rounded text-gray-500 hover:bg-white !font-semibold active:!bg-inherit"
          children={undefined}
          placeholder={undefined}
          disabled={comment ? false : true}
        >
          입력
        </Button>
      </div>

      {currentPosts?.map((item: any, index: any) => {
        return (
          <div className="mb-[30px]">
            <div className="flex items-center justify-between gap-[10px] cursor-pointer">
              <div
                className="flex items-center gap-[10px] cursor-pointer"
                onClick={() => {
                  if (isInfo?.memberId === item?.memberId) {
                    navigate(`/studio`);
                  } else {
                    navigate(`/studio/${item?.memberId}`, {
                      state: item?.memberId,
                    });
                  }
                }}
              >
                <Avatar
                  size="sm"
                  variant="circular"
                  alt="profile img"
                  src={
                    item?.memberProfileImage
                      ? item?.memberProfileImage
                      : "/img/standbuy/icon/no-profile.svg"
                  }
                  placeholder={undefined}
                  className="border border-[#42BC7F] ring-1 ring-[#42BC7F] p-0.5 w-[30px] h-[30px] cursor-pointer"
                />
                <Text
                  fontSize={18}
                  fontWeight={600}
                  color="#373737"
                  className="dark:text-darkText"
                >
                  {item?.memberNickname}
                </Text>
              </div>

              <CommentBtn
                options={
                  item?.memberId === me?.memberId ? "myList" : "declarationList"
                }
                item={item}
                me={me}
                setModifyFlag={setModifyFlag}
                setEditingId={setEditingId}
              />
            </div>
            <div className="ml-[40px] flex flex-col gap-[10px]">
              <Text
                fontSize={14}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                {item?.comment}
              </Text>

              <div className="flex items-center gap-1">
                <Text fontSize={12} fontWeight={600} color="#b2b2b2">
                  {detailDate(new Date(item?.createTime))}
                </Text>

                {/* <div className="w-[3px] h-[3px] bg-[#b2b2b2] rounded-full" />

                <Text fontSize={12} fontWeight={600} color="#b2b2b2">
                  좋아요
                </Text> */}

                <div className="w-[3px] h-[3px] bg-[#b2b2b2] rounded-full" />

                <Text
                  className="cursor-pointer"
                  fontSize={12}
                  fontWeight={600}
                  color="#b2b2b2"
                  onClick={() => {
                    if (isLogin && me?.mobileVerification) {
                      toggleReply(index);
                    } else if (isLogin && !me?.mobileVerification) {
                      dispatch(niceModalFetched({ show: true }));
                    } else if (isLogin === false) {
                      dispatch(modalFetched({ show: true }));
                    }
                  }}
                >
                  답글달기
                </Text>
              </div>
            </div>

            {/**대댓글 */}
            {item.replies?.map((replyItem: any, replyIndex: any) => (
              <div className="ml-[40px] mt-[10px] bg-[#F9F9F9] dark:bg-darkBanner py-5 pr-2 pl-8 rounded-lg">
                <div className="flex items-center justify-between gap-[10px]">
                  <div
                    className="flex items-center gap-[10px] cursor-pointer"
                    onClick={() => {
                      if (isInfo?.memberId === replyItem?.memberId) {
                        navigate(`/studio`);
                      } else {
                        navigate(`/studio/${replyItem?.memberId}`, {
                          state: replyItem?.memberId,
                        });
                      }
                    }}
                  >
                    <Avatar
                      size="sm"
                      variant="circular"
                      alt="profile img"
                      src={
                        replyItem?.memberProfileImage
                          ? replyItem?.memberProfileImage
                          : "/img/standbuy/icon/no-profile.svg"
                      }
                      placeholder={undefined}
                      className="border border-[#42BC7F] ring-1 ring-[#42BC7F] p-0.5 w-[30px] h-[30px]"
                    />
                    <Text
                      fontSize={18}
                      fontWeight={600}
                      color="#373737"
                      className="dark:text-darkText"
                    >
                      {replyItem?.memberNickname}
                    </Text>
                  </div>

                  <CommentBtn
                    options={
                      replyItem?.memberId === me?.memberId
                        ? "myList"
                        : "declarationList"
                    }
                    item={replyItem}
                    detail={detail}
                    me={me}
                    setModifyFlag={setModifyFlag}
                    setEditingId={setEditingId}
                  />
                </div>
                <div key={replyIndex} className="ml-[40px]">
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color="#535353"
                    className="dark:text-darkText"
                  >
                    {replyItem?.comment}
                  </Text>

                  <Text
                    fontSize={12}
                    fontWeight={600}
                    color="#b2b2b2"
                    className="mt-1"
                  >
                    {detailDate(new Date(replyItem?.createTime))}
                  </Text>
                </div>
              </div>
            ))}

            {replyFlag === index && (
              <div className="ml-[40px] mt-[26px] relative">
                <Input
                  type="text"
                  variant="static"
                  label="이 댓글에 답글을 입력해주세요."
                  placeholder="이 댓글에 답글을 입력해주세요."
                  className="!py-5 !px-5 rounded-lg !border !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                  labelProps={{
                    className: "hidden",
                  }}
                  containerProps={{ className: "" }}
                  crossOrigin={undefined}
                  size="lg"
                  onChange={(e: any) => {
                    setReplyComment(e.target.value);
                  }}
                  value={replyComment}
                />
                <Button
                  //@ts-ignore
                  onClick={() => reply({ item, index })}
                  ripple={false}
                  variant="text"
                  size="sm"
                  className="!absolute right-1 top-2 rounded text-gray-500 hover:bg-white !font-semibold active:!bg-inherit"
                  children={undefined}
                  placeholder={undefined}
                >
                  입력
                </Button>
              </div>
            )}
          </div>
        );
      })}

      {data?.commentList?.length > 1 && (
        <Pagination
          postsNum={data?.commentList?.length}
          postsPerPage={postsPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}
