import React from "react";
import ImgModal from "../../design-system/modal/ImgModal";

export default function FeedOrignalImgModal({ open, onClose, data }: any) {
  return (
    <ImgModal open={open} onClose={onClose} headerNone>
      <img
        src={data?.previewPath}
        width={data?.contentWidth}
        height={data?.contentHeight}
        className={"max-h-screen"}
      />
    </ImgModal>
  );
}
