import Switcher from "../../components/common/Switcher";
import { useNavigate } from "react-router-dom";

import SocialCheckForm from "./card/SocialCheckForm";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useEffect } from "react";
import { LOCAL_STORAGE } from "../../lib/const";
import { useContext } from "../../util/useContext";

export default function SocialTermsPage() {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const isLogin = localStorage.getItem(LOCAL_STORAGE.LOGIN);
  const { me } = useContext();

  useEffect(() => {
    if (isLogin && me?.agreementSelectiveTerms) {
      navigate("/feed");
    } else if (!isLogin) {
      navigate("/");
    }
  }, [me, isLogin]);

  return (
    <div className="bg-white dark:bg-black h-screen flex justify-center items-center">
      <Switcher className="hidden" />
      <div className="w-full flex flex-col items-center gap-12">
        <img
          src={
            themeData === "light" || themeData === ""
              ? "/img/standbuy/logo/logo.svg"
              : "/img/standbuy/logo/logo-white.svg"
          }
          alt="standbuy"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          width={200}
        />
        <SocialCheckForm />
      </div>
    </div>
  );
}
