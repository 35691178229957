import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switcher from "../../components/common/Switcher";
import Card from "../../components/design-system/Card";
import Text from "../../components/design-system/Text";
import FillButton from "../../components/design-system/button/FillButton";

export default function DenyPage() {
  const themeData = useAppSelector((state) => state.theme.data, shallowEqual);
  const navigate = useNavigate();
  const [email, setEmail] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [reason, setReason] = useState<any>("");

  useEffect(() => {
    const temp = new URL(window.location.href).search;
    const params = new URLSearchParams(temp).get("email");
    const paramsTwo = new URLSearchParams(temp).get("status");
    const paramsThree = new URLSearchParams(temp).get("reason");
    setEmail(params);
    setType(paramsTwo);
    setReason(paramsThree);
  }, []);

  return (
    <div className="bg-gray-200 dark:bg-black h-screen flex justify-center items-center">
      <Switcher className="hidden" />
      <div className="flex flex-col gap-12 w-full items-center">
        <img
          src={
            themeData === "light" || themeData === ""
              ? "/img/standbuy/logo/logo.svg"
              : "/img/standbuy/logo/logo-white.svg"
          }
          alt="standbuy"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          width={200}
        />
        <Card>
          <div className="flex flex-col overflow-auto">
            <Text className="subtitle-1 dark:text-darkText">로그인 제한</Text>
            <Text className="subtitle-2 dark:text-darkText mt-4">
              해당 계정은 로그인이 제한되었습니다.
            </Text>

            <div className="mt-4">
              <Text
                fontSize={16}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                - 이메일 : {email}
              </Text>
              <Text
                fontSize={16}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                - 상태 :{" "}
                {type === "SUSPENSION"
                  ? "제재 또는 정지"
                  : type === "DORMANT"
                    ? "휴면"
                    : "탈퇴"}
              </Text>
              <Text
                fontSize={16}
                fontWeight={500}
                color="#535353"
                className="dark:text-darkText"
              >
                - 이유 : {reason}
              </Text>
            </div>

            <FillButton
              onClick={() => navigate("/")}
              text="홈으로"
              className="mt-10"
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
