/**
 * @name useAxios
 * @description
 */
//@ts-nocheck
import axios from "axios";
import { useState } from "react";
import { LOCAL_STORAGE, SESSION_STORAGE } from "../const";
import { API_DEFAULT } from "../../api/api";
import { signOut } from "../modules/auth";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { shallowEqual } from "react-redux";
import {
  niceModalFetched,
  overlapModalFetched,
} from "../../store/reducer/globalModalSlice";
// import {SESSION_STORAGE} from '../lib/const'
// import {isArray, log} from 'lib/util'

export const uploadAxios = axios.create({});
export const niceAxios = axios.create({});
export const visitAxios = axios.create({});

export const useAxios = (_url?: string, _options = {}) => {
  const dispatch = useAppDispatch();
  const [result, setResult] = useState<string>(""); // *--------------------- result
  const [loading, setLoading] = useState<boolean | null>(null); // *--------- Loading
  const browserData = useAppSelector(
    (state) => state.browser.browser,
    shallowEqual,
  );

  const _token = localStorage.getItem(LOCAL_STORAGE.TOKEN);
  const r_token = localStorage.getItem(LOCAL_STORAGE.R_TOKEN);

  async function response(_config: any) {
    setLoading(true);
    const config = Object.assign(
      {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Access-Control-Allow-Origin": "*",
        },
        // timeout: 1000 * 900, // Adjusted as per your comments
        async: true,
        crossDomain: true,
        withCredentials: true,
      },
      _config,
    );

    // 로그인일때 header에 토큰을 넣지 않는다.
    if (config?.url.indexOf("user/auth/sign-in") === -1) {
      const token = await localStorage.getItem(LOCAL_STORAGE.TOKEN);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    const res = await axios(config);
    setLoading(false);

    // REST성공
    if (res?.status === 200 || res?.status === 201) {
      if (res?.data) {
        if (
          res?.data?.error?.message === "인증되지 않은 사용자에요" ||
          res?.data?.error?.message === "Expired Request"
        ) {
          const postResponse = await post(`${API_DEFAULT}/user/auth/re-issue`, {
            accessToken: _token,
            refreshToken: r_token,
            browser: browserData,
          });

          if (postResponse?.success) {
            const newAccessToken = postResponse.data.accessToken;

            if (newAccessToken) {
              localStorage.setItem(LOCAL_STORAGE.TOKEN, newAccessToken);
            }

            if (postResponse?.data?.refreshToken) {
              localStorage.setItem(
                LOCAL_STORAGE.R_TOKEN,
                postResponse.data.refreshToken,
              );
            }

            axios.defaults.headers.common["Authorization"] =
              `Bearer ${newAccessToken}`;
            window.location.reload();
          } else {
            await del(`${API_DEFAULT}/user/auth/sign-out`, {
              accessToken: _token,
              refreshToken: r_token,
              browser: browserData,
            });
            signOut({ dispatch: dispatch });
          }
        } else if (res?.data?.error?.message === "Auth Valid Fail") {
          await del(`${API_DEFAULT}/user/auth/sign-out`, {
            accessToken: _token,
            refreshToken: r_token,
            browser: browserData,
          });
          signOut({ dispatch: dispatch });
        } else if (res?.data?.error?.message === "Another Browser") {
          dispatch(
            overlapModalFetched({
              show: true,
            }),
          );
        } else {
          setResult(res?.data);
          return res?.data;
        }
      }
    }
  }

  // *---------------- Error 실행
  function errorFunc(error: any) {
    setLoading(false);
    errorMsg(error);
  }
  // *-------------------------------------- FETCH
  async function ajax(url?: string | null, params?: {}) {
    const config = { method: "GET", url: url || _url, ...params };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- GET
  async function get(url?: string | null, params?: {}) {
    const config = {
      method: "GET",
      url: url || _url,
      params: Object.assign(_options, params),
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- POST
  async function post(url?: string | null, data?: {} | any) {
    const config = {
      method: "POST",
      url: url || _url,
      data: Object.assign(_options, data),
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }

  async function postFile(url?: string | null, data?: {} | any) {
    const config = {
      method: "POST",
      url: url || _url,
      data: Object.assign(_options, data),
      responseType: "blob",
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }

  async function postProgress(
    url?: string | null,
    data?: {} | any,
    option?: any,
  ) {
    const config = {
      method: "POST",
      url: url || _url,
      data: Object.assign(_options, data),
      option,
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }

  // *-------------------------------------- AWS UPLOAD
  async function putAws(url?: string | null, data?: {} | any, headers?: any) {
    const config = {
      method: "put",
      url: url || _url,
      headers,
      data: data,
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }

  // *-------------------------------------- File upload (multipart/form-data)
  async function upload(url?: string | null, data?: {} | any) {
    const { stockFile, thumbnailFile, form } = data;
    let config;
    const _form = new FormData() as any;
    for (const [key, value] of Object.entries(form)) {
      const _key: string = key;
      const _value: any = value;
      if (_key === "file") {
        const chunkSize = 1024 * 1024; // 1MB chunks
        const numChunks = Math.ceil(stockFile.size / chunkSize);
        // 청크를 나누어 FormData에 추가
        for (let i = 0; i < numChunks; i++) {
          const offset = i * chunkSize;
          const chunk = stockFile.slice(offset, offset + chunkSize);
          const sendFileName = i + "_" + stockFile.name;
          _form.append("file", chunk, sendFileName);
        }
      }
      _form.append(_key, _value);
    }

    config = {
      method: "POST",
      url: url || _url,
      headers: {
        "Content-Type": "multipart/form-data",
        // Accept: 'application/json',
      },
      data: _form,
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }

  // *-------------------------------------- FORM (multipart/form-data)
  async function form(url?: string | null, data?: {} | any) {
    let config;
    const _form = new FormData() as any;
    for (const [key, value] of Object.entries(data)) {
      const _key: string = key;
      const _value: any = value;
      _form.append(_key, _value);
    }
    config = {
      method: "POST",
      url: url || _url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: _form,
    };
    try {
      const res = await response(config);
      return res;
    } catch (error) {
      errorFunc(error);
    }
  }

  async function formProgress(url: any, data: any, onUploadProgress: any) {
    let config;
    const _form = new FormData();
    for (const [key, value] of Object.entries(data)) {
      const _key: string = key;
      const _value: any = value;
      _form.append(_key, _value);
    }
    config = {
      method: "POST",
      url: url || _url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: _form,
      onUploadProgress,
    };

    try {
      const res = await response(config);
      return res;
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- PUT
  async function put(url?: string | null, data?: {}) {
    const config = {
      method: "PUT",
      url: url || _url,
      data: Object.assign(_options, data),
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- PUT
  async function patch(url?: string | null, data?: {}) {
    const config = {
      method: "PATCH",
      url: url || _url,
      data: Object.assign(_options, data),
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  // *-------------------------------------- DELETE
  async function del(url?: string | null, data?: {}) {
    const config = {
      method: "DELETE",
      url: url || _url,
      data: Object.assign(_options, data),
    };
    try {
      return await response(config);
    } catch (error) {
      errorFunc(error);
    }
  }
  //-----------------------------------------------
  return {
    result,
    form,
    ajax,
    get,
    upload,
    post,
    patch,
    del,
    put,
    loading,
    formProgress,
    putAws,
    postProgress,
    postFile,
  };
};

/**
 * @description Ajax
 * @param
 */

export const ajax = async (url: string, payload?: any) => {
  const config = Object.assign(
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      timeout: 1000 * 10,
      async: true,
      crossDomain: true,
      withCredentials: false,
      method: payload?.method ?? "GET",
    },
    { data: payload?.data },
  );
  const token = await localStorage.getItem(LOCAL_STORAGE.TOKEN);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const res = await axios(url, config);
  //*----REST성공
  if (res?.status === 200 || res?.status === 201) {
    return res?.data;
  }
  //*----REST실패
  else {
    return res?.data;
  }
};
/**
 * @description 에러발생시코드
 * @param error
 */
export const errorMsg = (params: any) => {
  // log(`====Error====`, 'padding:5px 10px;font-size:14px;background:#111;color:red;')
  // log(params?.config?.url, `font-size:10px; color:#888;`)
  // log(params?.config)
};
/********************************************************
[사용법]
 
*********************************************************/
