import React from "react";
import { motion } from "framer-motion";
import SideDrawer from "../../components/design-system/SideDrawer";
import Text from "../../components/design-system/Text";
import { useContext } from "../../util/useContext";
import { addComma } from "../../util/number";
import ExChangeForm from "./card/ExChangeForm";
import NoExchangeData from "./card/NoExchangeData";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { API_DEFAULT } from "../../api/api";
import { useAxios } from "../../lib/api/useAxios";
import MyBankEdit from "../myPage/card/MyBankEdit";
import { Tooltip } from "@material-tailwind/react";
import CustomTooltip from "../../components/design-system/CustomTooltip";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/layout/footer/MobileFooter";
import CustomFooter from "../../components/layout/footer/CustomFooter";

export default function ExchangePage() {
  const isMobile = useMediaQuery({ maxWidth: 575 });
  const { get } = useAxios();
  const { me } = useContext();
  const queryClient = useQueryClient();

  const { status, data } = useQuery({
    queryKey: ["settle-stats"],
    queryFn: async () => await get(`${API_DEFAULT}/user/settle/stat`),
  });

  return (
    <div
      className={`flex flex-col justify-between h-full ${
        isMobile ? "" : "pt-[20px]"
      }`}
    >
      <motion.div
        className={`w-full ${isMobile ? "px-10" : "flex justify-center"} mb-10`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div
          className={`mt-[40px] ${isMobile ? "" : "mx-[200px] max-w-[1280px]"} w-full `}
        >
          {isMobile ? null : (
            <div className="animate-bounce gap-1">
              <Text
                fontSize={14}
                fontWeight={600}
                color="black"
                className="dark:text-darkText"
              >
                메뉴
              </Text>
              <img src="/img/standbuy/icon/scroll-down.svg" alt="" />
            </div>
          )}
          <div className="flex items-center mb-[30px]">
            {isMobile ? null : <SideDrawer />}
            <Text className="title dark:text-darkText">정산</Text>
          </div>

          <div className="h-[1px] bg-[#afafaf] mt-[10px] mb-[30px]" />

          <div className="mb-10">
            <div className="py-5  bg-[#f9f9f9] dark:bg-darkBanner rounded-xl">
              <div
                className={`w-full  ${isMobile ? "flex justify-around items-center" : "flex items-center justify-center "}`}
              >
                <div className="flex flex-col items-center gap-1">
                  <Text
                    className={`dark:text-darkText ${isMobile ? "" : "mb-[6px]"} `}
                    fontSize={15}
                    color="#636363"
                    fontWeight={500}
                  >
                    총 수익금
                  </Text>
                  <Text className="subtitle-1 dark:text-darkText">
                    {addComma(data?.data.profit) || 0} ₩
                  </Text>
                </div>

                {!isMobile && (
                  <div className="w-[1px] h-[36px] bg-[#a7a7a7] mx-[120px]"></div>
                )}

                <div className="flex flex-col items-center gap-1">
                  <div className={"flex items-center gap-2"}>
                    <Text
                      className="dark:text-darkText"
                      fontSize={15}
                      color="#636363"
                      fontWeight={500}
                    >
                      수익금(확정)
                    </Text>

                    <Tooltip
                      content={
                        <>
                          <div>확정이란?</div>
                          <ul
                            style={{
                              listStyle: "inside",
                              fontWeight: "500",
                              marginTop: "8px",
                            }}
                          >
                            <li>
                              환불규정에 따라 7일 내로 사용하지 않으면 환불이
                              가능해요.
                            </li>
                            <li>
                              7일이 지나거나 사용한 콘텐츠는 확정된 수익으로
                              정산이 가능해요.
                            </li>
                          </ul>
                        </>
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                          stroke="#C9C9C9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 16.5V12.5"
                          stroke="#C9C9C9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 8.5H12.0098"
                          stroke="#C9C9C9"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Tooltip>
                  </div>
                  <Text className="subtitle-1 dark:text-darkText">
                    {addComma(data?.data.profit) || 0} ₩
                  </Text>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${isMobile ? "flex-col gap-10" : "justify-around gap-40"} flex`}
          >
            {me?.bankAccount === null ? (
              <NoExchangeData />
            ) : (
              <ExChangeForm data={data?.data} />
            )}

            <MyBankEdit />
          </div>
        </div>
      </motion.div>

      {isMobile ? <MobileFooter /> : <CustomFooter />}
    </div>
  );
}
