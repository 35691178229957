import { createSlice } from "@reduxjs/toolkit";

const initialToastState = {
  data: { show: false, text: "", type: "" },
};

export const toastSlice = createSlice({
  name: "toast",
  initialState: initialToastState,
  reducers: {
    toastFetched: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { toastFetched } = toastSlice.actions;

export default toastSlice.reducer;
