import React from "react";
import Text from "./Text";
import { motion } from "framer-motion";

export default function PageCard({
  title,
  textButton,
  children,
  onClick,
}: Props) {
  return (
    <motion.div
      className={`w-full flex justify-center`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="mt-[50px] mx-[200px] w-full max-w-[1280px]">
        <div className="flex justify-between items-center mb-[30px]">
          <Text className="title  dark:text-darkText">{title}</Text>
          <Text
            className="page-button-text underline underline-offset-4 dark:text-darkText cursor-pointer"
            onClick={onClick}
          >
            {textButton}
          </Text>
        </div>
        <div className="h-[1px] bg-[#afafaf] mb-[30px]"></div>
        {children}
      </div>
    </motion.div>
  );
}

interface Props {
  title: string;
  textButton?: string;
  children: any;
  onClick?: any;
}
