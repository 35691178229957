import React, { useEffect, useState } from "react";
import Card from "../../../components/design-system/Card";
import Text from "../../../components/design-system/Text";
import { LabelInputReset } from "../../../components/design-system/input/LabelInputReset";
import FillButton from "../../../components/design-system/button/FillButton";
import { LabelInputEyes } from "../../../components/design-system/input/LabelInputEyes";
import { useAxios } from "../../../lib/api/useAxios";
import { API_DEFAULT } from "../../../api/api";
import { signIn } from "../../../lib/modules/auth";
import { useAppDispatch } from "../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorText } from "../../../styles/color";
import JoinEndModal from "../modal/JoinEndModal";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { LabelInputBtn } from "../../../components/design-system/input/LabelInputBtn";
import JoinEndSelectModal from "../modal/JoinEndSelectModal";
import useDeviceSize from "../../../components/hooks/useDeviceSize";

export default function JoinAddForm() {
  const { post } = useAxios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop } = useDeviceSize();

  const [joinSuccessModal, setJoinSuccessModal] = useState({ show: false });
  const [joinSelectModal, setJoinSelectModal] = useState({ show: false });
  const [emailSendNumber, setEmailSendNumber] = useState(""); // 이메일 인증 코드
  const [sendNumberFlag, setSendNumberFlag] = useState(false);
  const [sendEmailFlag, setEmailFlag] = useState(false);
  const [seconds, setSeconds] = useState(300);

  const [email, setEmail] = useState("");
  const [password, setPassWord] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [birth, setBirth] = useState("2000-00-00");
  const [phone, setPhone] = useState("");

  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isValidEmail = regex.test(email);

  const regexPwd = /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\[\]{};:'"<>,.?/]).+$/;
  let isValidPassword = regexPwd.test(password);

  const regexName = /^[가-힣]+$/;
  let isValidName = regexName.test(name);

  const regexNickname = /^[가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9]+$/;
  let isValidNickname = regexNickname.test(nickname);

  const regexPhone = /^\d{3}-\d{4}-\d{4}$/;
  let isValidPhone = regexPhone.test(phone);

  const joinData = {
    email: email,
    password: password,
    passwordConfirm: passwordConfirm,
    nickname: nickname,
    agreeAdvertisingInfo: location?.state?.ad,
    // agreeUsingPersonalInfo: location?.state?.info,
    // name: name,
    // birth: birth,
    // phone: phone,
  };

  const autoHypenPhone = (str: any) => {
    str = str.replace(/[^0-9]/g, "");
    let tmp = "";
    if (str.length < 4) {
      return str;
    } else if (str.length < 7) {
      tmp += str.substr(0, 3) + "-" + str.substr(3);
    } else if (str.length < 11) {
      tmp += str.substr(0, 3) + "-" + str.substr(3, 3) + "-" + str.substr(6);
    } else {
      tmp += str.substr(0, 3) + "-" + str.substr(3, 4) + "-" + str.substr(7);
    }
    return tmp;
  };

  useEffect(() => {
    const formattedPhone = autoHypenPhone(phone);
    if (phone !== formattedPhone) {
      setPhone(formattedPhone);
    }
  }, [phone]);

  async function onSubmit(payload: any) {
    const res = await post(`${API_DEFAULT}/user/auth/sign-up`, payload);
    if (res?.success) {
      const sign = await post(`${API_DEFAULT}/user/auth/sign-in`, {
        email: payload.email,
        password: payload.password,
      });

      await signIn({ dispatch: dispatch, data: sign?.data });

      if (sign?.success) {
        navigate("/login-end");
        // setJoinSelectModal({ show: true });
      } else {
        dispatch(
          toastFetched({
            show: true,
            text: res?.error?.message,
            type: "error",
          }),
        );
      }
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error?.message,
          type: "error",
        }),
      );
    }
  }

  // 이메일 인증 코드 발송
  async function onEamilCode(payload: any) {
    if (sendEmailFlag) {
      setSeconds(300);
    }
    const data = {
      email: payload,
    };
    const res = await post(`${API_DEFAULT}/user/auth/email/request`, data);
    if (res?.success) {
      setEmailFlag(true);
      dispatch(
        toastFetched({
          show: true,
          text: "해당 이메일로 인증코드가 발급 되었습니다.",
          type: "success",
        }),
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: "",
          type: "error",
        }),
      );
    }
  }

  // 이메일 인증 코드 검증
  async function onEamilCodeVerify() {
    const data = {
      email: email,
      code: emailSendNumber,
    };
    const res = await post(`${API_DEFAULT}/user/auth/email/verify`, data);
    if (res?.success) {
      setSendNumberFlag(true);
      dispatch(
        toastFetched({
          show: true,
          text: "이메일 인증 성공",
          type: "success",
        }),
      );
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: "인증코드가 유효하지 않습니다.",
          type: "error",
        }),
      );
    }
  }

  useEffect(() => {
    let interval: any = null;

    // sendNumberFlag가 true이고, seconds가 0보다 클 때 타이머를 시작합니다.
    if (sendEmailFlag && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval); // 타이머가 0에 도달하면 인터벌을 정리합니다.
      // 필요한 경우 여기에서 타이머 종료 후 로직을 추가할 수 있습니다.
    }

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
  }, [sendEmailFlag, seconds]);

  return (
    <>
      <Card>
        <div className="flex flex-col gap-12">
          <Text className="subtitle-2 dark:text-darkText">회원가입</Text>

          <div className="w-full">
            <LabelInputBtn
              width="100%"
              value={email}
              setValue={setEmail}
              placeholder={"이메일을 입력해주세요."}
              label="이메일"
              error={!isValidEmail && email !== ""}
              success={isValidEmail && email !== ""}
              btnText={sendEmailFlag ? "재전송" : "인증"}
              onClick={() => {
                onEamilCode(email);
              }}
              flag="email"
              sendNumberFlag={sendNumberFlag}
              readOnly={emailSendNumber}
            />

            {!isValidEmail && email !== "" && (
              <Text color={ErrorText} className="label-2 mt-1">
                이메일 형식이 올바르지 않습니다.
              </Text>
            )}
          </div>

          {sendEmailFlag && (
            <div className="w-full">
              <LabelInputBtn
                width="100%"
                value={emailSendNumber}
                setValue={setEmailSendNumber}
                placeholder={"이메일에 발송된 인증번호를 입력해주세요."}
                label="이메일 인증번호"
                btnText="확인"
                onClick={onEamilCodeVerify}
                // error={sendNumberFlag}
                success={sendNumberFlag}
                disabled={sendNumberFlag}
                flag="emailNumber"
                sendNumberFlag={sendNumberFlag}
              />

              {sendEmailFlag && sendNumberFlag === false && (
                <Text
                  className="label-2 mt-1 dark:text-darkText"
                  color={ErrorText}
                >
                  {`인증 유효시간 : ${Math.floor(seconds / 60)}분 ${
                    seconds % 60
                  }초`}
                </Text>
              )}
            </div>
          )}

          <div className="w-full">
            <LabelInputEyes
              type="password"
              width="100%"
              value={password}
              setValue={setPassWord}
              placeholder={
                "8자 이상 숫자/영어 소문자/특수문자 조합으로 입력해주세요."
              }
              label="비밀번호"
              minLength={8}
              error={
                (!isValidPassword && password !== "") ||
                (isValidPassword && password !== "" && password.length < 8)
              }
              success={
                isValidPassword && password !== "" && password.length >= 8
              }
            />

            {(!isValidPassword && password !== "") ||
            (isValidPassword && password !== "" && password.length < 8) ? (
              <Text color={ErrorText} className="label-2 mt-1">
                8자 이상 숫자/영어 소문자/특수문자 조합으로 입력해주세요.
              </Text>
            ) : null}
          </div>

          <div className="w-full">
            <LabelInputEyes
              width="100%"
              type="password"
              value={passwordConfirm}
              setValue={setPasswordConfirm}
              placeholder={"비밀번호를 다시 한 번 입력해주세요."}
              label="비밀번호 확인"
              error={passwordConfirm !== "" && passwordConfirm !== password}
              success={passwordConfirm !== "" && passwordConfirm === password}
            />
            {passwordConfirm !== "" && passwordConfirm !== password && (
              <Text color={ErrorText} className="label-2 mt-1">
                입력한 비밀번호가 다릅니다.
              </Text>
            )}
          </div>

          {/* <div className="w-full">
            <LabelInputReset
              width="100%"
              value={name}
              setValue={setName}
              placeholder={"이름은 2~5글자 이내의 한글만 입력해주세요."}
              label="이름"
              maxLength={5}
              minLength={2}
              error={!isValidName && name !== ""}
              success={isValidName && name !== ""}
            />
            {!isValidName && name !== "" && (
              <Text color={ErrorText} className="label-2 mt-1">
                이름은 2~5글자 이내의 한글만 입력해주세요.
              </Text>
            )}
          </div> */}

          {/* <div className="w-full">
          <LabelInputReset
            width="100%"
            value={birth}
            setValue={setBirth}
            placeholder={"생년월일"}
            label="생년월일"
            type="date"
          />
        </div> */}

          <div className="w-full">
            <LabelInputReset
              width="100%"
              value={nickname}
              setValue={setNickname}
              placeholder={"닉네임은 2~15글자의 한글, 영문, 숫자만 가능합니다"}
              label="닉네임"
              error={
                isValidNickname && nickname !== "" && nickname.length === 1
              }
              success={
                isValidNickname && nickname !== "" && nickname.length > 1
              }
              maxLength={15}
              minLength={2}
            />
            {isValidNickname && nickname !== "" && nickname.length === 1 && (
              <Text color={ErrorText} className="label-2 mt-1">
                닉네임은 2~15글자의 한글, 영문, 숫자만 가능합니다
              </Text>
            )}
          </div>

          {/* <div className="w-full">
            <LabelInputReset
              maxLength={13}
              width="100%"
              type="tel"
              value={phone}
              setValue={setPhone}
              placeholder={"휴대전화번호"}
              label="휴대전화번호"
              error={!isValidPhone && phone !== ""}
              success={isValidPhone && phone !== ""}
              onKeyUp={(event: any) => {
                if (event.key === "Enter") {
                  onSubmit(joinData);
                }
              }}
            />
            {!isValidPhone && phone !== "" && (
              <Text color={ErrorText} className="label-2 mt-1">
                입력예시. 000-0000-0000
              </Text>
            )}
          </div> */}

          <FillButton
            text={"가입하기"}
            disabled={
              !isValidEmail ||
              !isValidPassword ||
              !isValidNickname ||
              sendNumberFlag === false
            }
            onClick={() => {
              onSubmit(joinData);
            }}
            size="lg"
          />
        </div>
      </Card>
      {joinSuccessModal.show && (
        <JoinEndModal
          open={joinSuccessModal.show}
          onClose={() => setJoinSuccessModal({ show: false })}
        />
      )}

      {joinSelectModal.show && (
        <JoinEndSelectModal
          open={joinSelectModal.show}
          onClose={() => setJoinSelectModal({ show: false })}
        />
      )}
    </>
  );
}
