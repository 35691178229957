import React, { useState } from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import FillButton from "../../../components/design-system/button/FillButton";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch } from "../../../store/store";
import { API_DEFAULT } from "../../../api/api";
import { toastFetched } from "../../../store/reducer/toastSlice";
import { Radio } from "@material-tailwind/react";

export default function PayCancelModal({
  open,
  onClose,
  data,
  payOrderId,
  flag,
}: any) {
  const queryClient = useQueryClient();
  const { post, get } = useAxios();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<any>({
    changed: false,
    mistake: false,
  });

  const { data: payDetail } = useQuery({
    queryKey: ["payInfo", data?.orderId],
    queryFn: async () =>
      await get(`${API_DEFAULT}/user/payment/list/detail`, {
        orderId: data?.orderId,
      }),
    enabled: flag === "all",
  });

  const handleChange = (type: any) => {
    setValue({
      changed: type === "changed",
      mistake: type === "mistake",
    });
  };

  async function handlePayCancel() {
    const cancelReason = value.chaged ? "단순변심" : "결제실수";
    const tradeIdsALL =
      flag === "all"
        ? payDetail?.data.map((detail: any) => detail.tradeId)
        : "";

    const res = await post(`${API_DEFAULT}/user/payment/cancel`, {
      orderId: flag === "all" ? data?.orderId : payOrderId,
      tradeIds: flag === "all" ? tradeIdsALL : [data?.tradeId],
      cancelReason: cancelReason,
    });

    if (res?.success) {
      dispatch(
        toastFetched({
          show: true,
          text: "결제 취소 완료",
          type: "success",
        }),
      );
      window.location.reload();
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: handlePayCancel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          정말 결제를 취소하시겠어요?
        </Text>

        <div className="mt-4 flex gap-10">
          <Radio
            value={value.changed}
            onChange={() => handleChange("changed")}
            name="type"
            label={
              <Text
                fontSize={16}
                fontWeight={500}
                color="#555"
                className="dark:text-darkText"
              >
                생각이 바꼈어요!
              </Text>
            }
            crossOrigin={undefined}
            ripple={true}
            color="green"
            className="hover:before:opacity-0"
          />
          <Radio
            value={value.mistake}
            onChange={() => handleChange("mistake")}
            name="type"
            label={
              <Text
                fontSize={16}
                fontWeight={500}
                color="#555"
                className="dark:text-darkText"
              >
                결제를 잘못했어요!
              </Text>
            }
            color="green"
            ripple={true}
            crossOrigin={undefined}
            className="hover:before:opacity-0"
          />
        </div>

        <FillButton
          text="취소하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
          disabled={
            value.changed === false && value.mistake === false ? true : false
          }
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
