import React from "react";
import styled from "styled-components";
import { getCssFilter } from "../../lib/getCssFilter";

const CustomImageSet = styled.img<any>`
  ${(props) => props.filter};
`;
export function CustomImage({
  src,
  color,
  className,
  width,
  style,
  onClick,
}: any) {
  return (
    <CustomImageSet
      style={style}
      width={width}
      className={className}
      src={src}
      filter={getCssFilter(color)}
      alt=""
      onClick={onClick}
    />
  );
}
