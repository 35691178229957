import React from "react";
import Modal from "../../../components/design-system/modal/Modal";
import Text from "../../../components/design-system/Text";
import { UploadTextColor } from "../../../styles/color";
import FillButton from "../../../components/design-system/button/FillButton";
import { addComma } from "../../../util/number";
import { API_DEFAULT } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../../lib/api/useAxios";
import { useAppDispatch } from "../../../store/store";
import { toastFetched } from "../../../store/reducer/toastSlice";

export default function ExChangeModal({ open, onClose, fof }: any) {
  const { post } = useAxios();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  async function handleTransFof() {
    const res = await post(`${API_DEFAULT}/user/settle`);

    if (res?.success) {
      alert("정산 요청 성공");
      window.location.reload();
    } else {
      dispatch(
        toastFetched({
          show: true,
          text: res?.error.message,
          type: "error",
        }),
      );
    }
  }

  const { mutate, status } = useMutation({
    mutationFn: handleTransFof,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile-simple"] });
    },
  });

  return (
    <Modal open={open} onClose={onClose} headerNone>
      <div className="flex flex-col items-center justify-center">
        <Text className="subtitle-2 dark:text-darkText">
          수익금을 정산하시겠어요?
        </Text>
        <Text
          className="upload-text dark:text-darkText mt-4"
          color={UploadTextColor}
        >
          정산 예정 : {addComma(fof) || 0} ₩
        </Text>

        <FillButton
          text="정산하기"
          className="w-[350px] mt-[30px]"
          size="lg"
          onClick={mutate}
        />

        <Text
          className="cursor-pointer underline underline-offset-4 cart-nickname mt-[18px] dark:text-darkText"
          onClick={onClose}
        >
          취소
        </Text>
      </div>
    </Modal>
  );
}
