/**
 * @name GoogleMapUtil
 * @description 구글맵유틸
 */

import { GOOGLE_MAP_KEY } from "../../../api/api";
import { loadScript } from "../../../util/load";

/**
 * @name googleMapPanTo
 * @description 구글맵이동
 */
export const googleMapPanTo = (position: { lat: number; lng: number }) => {
  if (!window.googlemap) return;
  window.googlemap.panTo(position);
};

/**
 * @name googleMapInfoWindowClose
 * @description 윈도창닫기
 */
export const googleMapInfoWindowClose = (selected?: number) => {
  if (!window.googlemap) return;
  if (!window.googlemap.positions) return;
  const _map = window.googlemap;
  const _positions = _map.positions;
  // const total = _positions.length
  _positions.forEach((element: any, index: number) => {
    if (selected === index) {
      element?.infoWindow.open(_map, _positions[selected].marker);
    } else {
      element.infoWindow?.close();
    }
  });
};

/**
 * @name isLoadedGoogleApi
 * @description 로드되었는지확인
 */

export const isLoadedGoogleApi = () => {
  const script = window.document.getElementsByTagName("script")[0];
  const includeCheck = script.src.startsWith(
    "https://maps.googleapis.com/maps/api"
  );
  return includeCheck;
};

/**
 * @name loadGoogleApi
 * @param payload
 * @description 구글API로드
 */

export const loadGoogleApi = async (payload?: any) => {
  if (isLoadedGoogleApi()) return;
  const queryString = new URLSearchParams(payload).toString();
  const url = `https://maps.googleapis.com/maps/api/js?${queryString}`;
  const res = await loadScript(url);
  return res;
};

/**
 * @name getGeocoder
 * @param payload
 * @description 구글위도경도를 주소로 변환
 * @returns {address: string, data: any[]}
 */
export const getGeocoder = async (payload: any) => {
  loadGoogleApi({ key: GOOGLE_MAP_KEY });
  let geocoder = new google.maps.Geocoder();

  let address = "";
  const res = await geocoder?.geocode(
    { location: payload },
    function (results, status) {
      if (status === "OK") {
        if (results?.[0]) {
          // 변환된 주소를 콘솔에 출력
          address = results?.[0].formatted_address;
        }
      } else {
        // console.log("geocoder.geocode 오류");
      }
      return results;
    }
  );
  return { address: address, data: res?.results };
};

/**
 * @name getGeocoder
 * @param 주소
 * @description 주소로 구글위도경도를 변환
 */
export const getLatLng = async (address: string) => {
  try {
    const key = GOOGLE_MAP_KEY; // 환경변수에서 API 키를 가져옴
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${key}`;
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === "OK") {
      return data.results[0].geometry.location;
    } else {
      console.error("Geocoding failed:", data.status);
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};
